import {cn} from "app/helpers";
import {useDispatch} from "react-redux";
import {addNote} from "features/notes/notesSlice";
import {useState} from "react";
import {Button, Input} from "@chakra-ui/react";
import Textarea from "components/Textarea";
import {toast} from "react-toastify";

export default function AddNoteForm({segmentId}) {
  const dispatch = useDispatch();
  const classes = cn('form');
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [processing, setProcessing] = useState(false);

  const handleInputChange = (event) => {
    const {name, value} = event.target;
    if (name === 'title') {
      setTitle(value);
    } else if (name === 'body') {
      setBody(value);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const values = {
      id: segmentId,
      title: title,
      body: body
    };

    setProcessing(true);
    dispatch(addNote(values)).then(action => {
      setProcessing(false);
      if (action.meta.requestStatus === 'fulfilled') {
        toast.success('Note saved.');
        setTitle('');
        setBody('');
      } else {
        const message = action.payload.message;
        if (message) {
          toast.error(message);
        }
      }
    });
  }

  return <form className={classes({}, ['add-note-form'])} onSubmit={handleSubmit}>
    <Input value={title} onChange={handleInputChange} name={"title"} placeholder="Enter title"/>
    <Textarea onChange={handleInputChange} name={"body"} value={body} isRequired={true}
              placeholder="Enter note" maxRows={6}/>
    <div className={classes('actions')}>
      <Button variant={'solidNavy'} isLoading={processing} type={'submit'}>Save</Button>
    </div>
  </form>
}
