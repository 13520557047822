import { Link as RouterLink } from 'react-router-dom';
import { Box, Flex, Hide, Link, Text } from '@chakra-ui/react';
import BackArrow from '../../icons/BackArrow';
import {useCourseContentData} from "features/course/courseHooks";

export default function ActivityHeading({activity}) {
  const {module} = activity;
  const moduleData = useCourseContentData(module?.id);

  return (module &&
    <Hide below={'lg'} ssr={false}>
      <Link as={RouterLink} to={`/course/content/${module.id}`} fontFamily="button">
        <Flex mb={2} alignItems={'center'}>
          <Box mr={'5px'}>
            <BackArrow height={'8px'} width={'8px'} color={'#1746B9'} />
          </Box>
          <Text as={'rb'} textTransform={'uppercase'} fontSize={'10px'} color={'#1746B9'}>{moduleData?.content?.title}</Text>
        </Flex>
      </Link>
    </Hide>
  );
};
