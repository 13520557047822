import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button as ChakraButton,
  ButtonGroup,
  Image,
  Tooltip,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import {cn, getImageDerivative} from 'app/helpers';
import { useProfileData } from 'features/profile/profileHooks';
import { submitWebform } from 'features/webform/webformSlice';
import RichText from 'components/Activities/RichText';
import LocationIcon from 'icons/LocationIcon';
import { ReactComponent as LanguageIcon } from 'icons/LanguageIcon.svg';
import ExpandIcon from 'icons/ExpandIcon';
import ModalDialog from 'components/modals/ModalDialog';
import GetInTouchForm from 'components/form/GetInTouchForm';
import Button from 'buttons/Button';

const classes = cn('consultant');

// @todo Extract as a separate component.
function Badge({name, icon}) {
  const image = getImageDerivative(icon, '24x24');

  return (
    <Tooltip label={name}>
      <div className="badge">
        {icon &&
          <Image src={image.url} alt={`${name} icon`} htmlWidth={image.width} htmlHeight={image.height} loading='lazy' />
        }
      </div>
    </Tooltip>
  );
}

function Label({children}) {
  return <h3 className={classes('label')}>
    {children}
  </h3>;
}

function Consultant({data, full = true, children}) {
  const {display_name, profile} = data;
  const {language, location_details: locationDetails, about, professional_background: professionalBackground} = profile;
  const photo = getImageDerivative(profile.photo, '88x88');
  const badges = profile.badges || [];

  const languages = language ? language.map(item => item.name) : [];
  if (languages.length > 1) {
    languages[0] = `${languages[0]} (Main)`;
    languages[languages.length - 1] = ` and ${languages[languages.length - 1]}`;
  }
  const languageText = languages.length
    ? languages.slice(0, languages.length - 1).join(', ') + languages[languages.length - 1]
    : '';

  return (
    <div className={classes({full}, !full ? ['box'] : [])}>
      <div className={classes('header')}>
        {photo &&
          <div className={classes('photo')}>
            <Image src={photo.url} alt={photo.alt} htmlWidth={photo.width} htmlHeight={photo.height} loading='lazy' />
          </div>
        }
        <h2 className={classes('name')}>{display_name}</h2>
      </div>

      <div className={classes('skills')}>
        {(badges.length > 0) &&
          <div className={classes('badges')}>
            {badges.map(badge => <Badge key={badge.tid} {...badge} />)}
          </div>
        }

        {language &&
          <div>
            <Label>
              <LanguageIcon /><span>Language</span>
            </Label>
            <p>{languageText}</p>
          </div>
        }

        {locationDetails &&
          <div>
            <Label>
              <LocationIcon variant="outline" /><span>Location</span>
            </Label>
            <p>{locationDetails}</p>
          </div>
        }
      </div>

      {about &&
        <div>
          {full && <Label>About</Label>}
          <RichText text={about} noOfLines={full ? null : 3} />
        </div>
      }

      {professionalBackground && full &&
        <div>
          <Label>Professional Background</Label>
          <RichText text={professionalBackground} />
        </div>
      }

      {children}
    </div>
  );
}

export default function ConsultantCard({data}) {
  const [isOpen, setIsOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const {data: profileData} = useProfileData();

  const handleClose = () => {
    setIsOpen(false);
    setShowForm(false);
  };

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    return dispatch(submitWebform({
      webform_id: 'get_in_touch_consultant',
      data: {
        ...values,
        consultant: data.uid,
      },
    })).then((action) => {
        if (action.meta.requestStatus === 'fulfilled') {
          toast.success('Sent.');
          handleClose();
        }
        else if (action.payload.message) {
          toast.error(action.payload.message);
        }
      });
  };

  return (
    <>
      <Consultant data={data} full={false}>
        <div className={classes('actions')}>
          <ChakraButton variant={'outlineNavy'} leftIcon={<ExpandIcon size={20} color={'navy'}/>} onClick={() => setIsOpen(true)}>View more</ChakraButton>
        </div>
      </Consultant>

      <ModalDialog isOpen={isOpen} onClose={handleClose} size="xl">
        <Consultant data={data}>
          {showForm
            ? <GetInTouchForm onSubmit={handleSubmit} initialValues={{from: profileData?.mail || '' }} />
            : <ButtonGroup mt={4}>
                <Button color="orange" onClick={() => setShowForm(true)}>Get in touch</Button>
              </ButtonGroup>
          }
        </Consultant>
      </ModalDialog>
   </>
  );
}
