import {colorToHex} from "app/helpers";

function Arrow({color = 'navy', size = 24, direction = 'top'}) {
  let rotateDeg = 0;
  switch (direction) {
    case 'right':
      rotateDeg = 90;
      break;
    case 'bottom':
      rotateDeg = 180;
      break;
    case 'left':
      rotateDeg = 270;
      break;
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
      <g transform={`rotate(${rotateDeg}, 12, 12)`}>
        <path d="M4.375 12L12 4.375L19.625 12L18.55 13.075L12.75 7.25L12.75 19.625L11.25 19.625L11.25 7.25L5.45 13.075L4.375 12Z"
              fill={colorToHex(color)}/>
      </g>
    </svg>
  );
}

export default Arrow;
