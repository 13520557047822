import Layout from "../components/layout/Layout"
import Course from "../components/course/Course";
import Block from "../components/layout/Block";
import {useCoursesData} from "features/course/courseHooks";

export default function Courses() {
  const [data] = useCoursesData();

  return (
    <Layout>
      <Block className={'courses-block'}>
        {data.map(course => <Course key={course.id} data={course} />)}
      </Block>
    </Layout>
  )
}
