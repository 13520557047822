import {useDispatch, useSelector} from "react-redux";
import {fetchBlocks, getRegionBlocks, getRegionState} from "./blockSlice";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";

const regionLoading = [];
export function useRegionData(id) {
  const dispatch = useDispatch();
  const location = useLocation();
  const uri = location.pathname || '/';
  const regionState = useSelector(state => getRegionState(state, id));
  const content = useSelector(state => getRegionBlocks(state, id));

  useEffect(() => {
    const isLoading = regionLoading.includes(id) || regionState.isLoading;
    const isLoaded = uri === regionState.uri && (regionState.isSuccess || regionState.isError);

    if (id && !isLoading && !isLoaded) {
      regionLoading.push(id);
      dispatch(fetchBlocks({
        region: id,
        uri: uri,
      })).then(() => {
        const index = regionLoading.indexOf(id);
        if (index > -1) {
          regionLoading.splice(index, 1);
        }
      });
    }
  }, [dispatch, id, uri]);

  return content;
}