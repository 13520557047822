import Block from "../Block";
import {cn} from "../../../app/helpers";
import RichText from "../../Activities/RichText";
import {Button} from "@chakra-ui/react";
import {useNavigate, useNavigation} from "react-router-dom";

export default function CtaBlock({data}) {
  const classes = cn('block');
  const content = data.content || {};
  const navigate = useNavigate();
  const navigation = useNavigation();

  const navigateCta = (uri) => {
    if (!uri) return;

    if (uri.startsWith('internal:/')) {
      navigate(uri.replace('internal:/', ''));
    }
    else if (uri.startsWith('https://') || uri.startsWith('http://') || uri.startsWith('//')) {
      window.open(uri, '_blank');
    }
  }

  return <Block className={classes({type: 'cta'})}>
    <div className={classes('inner')}>
      {data.title && <h2 className={classes('title')}>{data.title}</h2>}
      <RichText text={content.body?.value}/>
    </div>
    <div className={classes('cta')}>
      {content.link && <Button isLoading={navigation.state !== 'idle'} variant={'solidOrange'} onClick={() => {navigateCta(content.link.uri)}}>{content.link.title}</Button>}
    </div>
  </Block>
}