import {
  Box,
  Fade,
  Flex,
  Slide,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ReactComponent as CrossIcon } from 'icons/CrossIcon.svg';

function TipOverlay({show}) {
  return (
    <Fade in={show} unmountOnExit={true} style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: '0',
      top: '0',
      zIndex: '2',
    }}>
      <Box
        bg='#000'
        opacity='0.25'
        position='absolute'
        width='100%'
        height='100%'
        top='0'
        left='0'
        zIndex='overlay'
      ></Box>
  </Fade>
  );
}

export default function TipContainer({ title, isVisible, onClose, icon, children}) {
  const slideDirection = useBreakpointValue({ base: 'bottom', lg: 'left' }, { ssr: false });
  const slideStyle = {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: slideDirection === 'bottom' ? 'end' : 'start',
    zIndex: 'var(--chakra-zIndices-modal)',
    padding: 'var(--chakra-space-4)',
    height: '100%',
  };

  function handleClick(...args) {
    onClose && onClose(...args);
  }

  return (
    <>
      <TipOverlay show={isVisible} />
      <Slide direction={slideDirection} in={isVisible} unmountOnExit={true} style={slideStyle} onClick={handleClick}>
        <Flex
          p={4}
          width='100%'
          bg='lightGrey.100'
          borderRadius='8px'
          direction='column'
          maxHeight='100%'
          maxWidth={{lg: '352px'}}
          onClick={event => event.stopPropagation()}
        >
          <Flex mb={4} columnGap={2} alignItems='start'>
            {icon && <Box flex='0 0'>{icon}</Box>}
            <Text fontWeight={'semibold'} color={'primary.navy'}>{title}</Text>
            <Box as='button' flex='0 0' ml='auto' onClick={handleClick} aria-label='Close tip'><CrossIcon /></Box>
          </Flex>
          <Box overflow='auto'>
            {children}
          </Box>
        </Flex>
      </Slide>
    </>
  );
}
