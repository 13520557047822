function ProfileIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill="#2A2A2A"
          d="M4.025 15.3A10.902 10.902 0 016.8 13.8c1-.367 2.067-.55 3.2-.55s2.2.183 3.2.55c1 .367 1.925.867 2.775 1.5a8.035 8.035 0 001.475-2.375c.367-.9.55-1.875.55-2.925 0-2.217-.779-4.104-2.337-5.663C14.104 2.779 12.217 2 10 2s-4.104.779-5.662 2.337C2.779 5.896 2 7.783 2 10c0 1.05.183 2.025.55 2.925.367.9.858 1.692 1.475 2.375zM10 10.75c-.917 0-1.687-.313-2.312-.938-.625-.625-.938-1.395-.938-2.312 0-.917.313-1.687.938-2.312.625-.625 1.395-.938 2.312-.938.917 0 1.687.313 2.312.938.625.625.938 1.395.938 2.312 0 .917-.313 1.687-.938 2.312-.625.625-1.395.938-2.312.938zm0 8.75a9.263 9.263 0 01-3.712-.75 9.58 9.58 0 01-3.013-2.025 9.58 9.58 0 01-2.025-3.013A9.263 9.263 0 01.5 10c0-1.317.25-2.554.75-3.713a9.583 9.583 0 012.025-3.012A9.58 9.58 0 016.288 1.25 9.263 9.263 0 0110 .5a9.27 9.27 0 013.713.75 9.583 9.583 0 013.012 2.025 9.583 9.583 0 012.025 3.012A9.27 9.27 0 0119.5 10c0 1.317-.25 2.554-.75 3.712a9.58 9.58 0 01-2.025 3.013 9.583 9.583 0 01-3.012 2.025A9.27 9.27 0 0110 19.5z"
        ></path>
      </svg>
    );
  }
  
  export default ProfileIcon;