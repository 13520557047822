import ModuleNav from "../course/ModuleNav";
import {useContext} from "react";
import {CourseContentContext} from "routes/CourseContent";
import {useCoursesData} from "features/course/courseHooks";
import Block from "components/layout/Block";

export default function ModuleNavBlock() {
  const [courses, coursesLoaded] = useCoursesData();
  const firstCourse = courses.find(course => !course.locked && course.progress.complete < 1);
  const lastCourse = courses.findLast(course => !course.locked);
  let {currentCourse, currentModule} = useContext(CourseContentContext);

  if (!currentCourse && coursesLoaded && (firstCourse || lastCourse)) {
    // Get last un-locked course.
    currentCourse = firstCourse ? firstCourse.id : lastCourse.id;
    currentModule = null;
  }

  return (
    currentCourse &&
    <Block className={'block--module-nav'}>
      <ModuleNav courseId={currentCourse} moduleId={currentModule} showButton={true}/>
    </Block>
  )
}
