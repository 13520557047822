import {createContext} from "react";
import {useParams} from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import Layout from "../components/layout/Layout";
import Module from "../components/course/Module";
import Activity from "components/course/Activity/Activity";
import ActivityHeading from '../components/course/ActivityHeading';
import SegmentNav from '../components/course/SegmentNav';
import Block from "../components/layout/Block";
import PageHeading from "../components/layout/PageHeading";
import Like from "../components/likes/Like";
import {cn} from "app/helpers";
import {useCourseContentData} from "features/course/courseHooks";
import ExpandablePanel from "components/layout/ExpandablePanel";

export const CourseContentContext = createContext({
  currentCourse: null,
  currentModule: null,
  currentSegment: null,
});

export default function CourseContent() {
  const classes = cn('course-content');
  const {id} = useParams();
  const data = useCourseContentData(id);
  const {segment, module} = data;
  useCourseContentData(module?.id);
  const segmentData = useCourseContentData(segment?.id);

  const headerInfo = {
    title: (data.type === 'course_activity' ? module?.content?.title : data.content?.title) || '',
    destination: (data.type === 'course_activity' && module?.id)
      ? `/course/content/${module?.id}`
      : data.course_id ? `/course/${data.course_id}` : '/course',
  };

  return (
    <CourseContentContext.Provider value={{
      currentCourse: data?.course_id,
      currentModule: module?.id,
      currentSegment: segment?.id,
    }}>
      <Layout headerInfo={headerInfo}>
        <Block className={classes()}>
          {data.type === 'course_module' && <Module data={data} />}

          {data.type === 'course_activity' &&
          <>
            <ActivityHeading activity={data} />
            <Box>
              <PageHeading title={segmentData?.content?.title} size={'sm'} suffix={<Like entityId={segment.id} entityType={'course_content'} variant={'bookmark'} />} />
              {/* `key` property is needed at the moment to reset Activity state when ID changes. */}
              {/* @todo Avoid keys outside of lists. */}
              <Activity key={data.id} id={data.id} data={data} />
              <ExpandablePanel />
            </Box>
          </>
          }

          {segmentData.isSuccess && <SegmentNav segment={segmentData} current={data} />}
        </Block>
      </Layout>
    </CourseContentContext.Provider>
  );
}
