import {useDispatch} from 'react-redux'
import {useNavigate, NavLink} from 'react-router-dom';
import {logout, reset} from 'features/auth/authSlice'
import {Flex, Avatar, Box, Text, Menu, MenuButton, MenuList, MenuItem, Button, IconButton} from '@chakra-ui/react'
import ChevronDown from '../../icons/ChevronDown';
import NotificationIcon from '../../icons/NotificationIcon';
import FaqIcon from '../../icons/FaqIcon';
import LogoutIcon from '../../icons/Logout';
import ProfileIcon from '../../icons/ProfileIcon';
import {useProfileData} from "features/profile/profileHooks";
import Block from "components/layout/Block";
import {cn, getImageDerivative} from "app/helpers";
import DropdownMenu from "components/menu/DropdownMenu";

export default function ProfilePanel() {
  const classes = cn('profile-panel');
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const {data} = useProfileData();
  const avatar = getImageDerivative(data?.profile?.photo, '24x24');
  const photoUrl = avatar?.url || '';

  const handleClick = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/')
  }

  return (
    <>
      <Block className={"profile-panel-block"}>
        <div className={classes()}>
          <Menu>
            <MenuButton className={classes('button')} as={Button} variant={'transparent'}
                        leftIcon={<Avatar className={classes('avatar')} size='sm'
                                          src={photoUrl} key={photoUrl} />}
                        rightIcon={<ChevronDown/>}
                        title={data?.display_name}
            >
              {data?.display_name ? data.display_name : "Name"}
            </MenuButton>

            <DropdownMenu>
              <NavLink to={"/profile"} className={'dropdown-menu__item'}>
                <MenuItem icon={<ProfileIcon/>}>
                  Profile
                </MenuItem>
              </NavLink>
              <NavLink to={"/faq"} className={'dropdown-menu__item'}>
                <MenuItem icon={<FaqIcon/>}>
                  FAQs
                </MenuItem>
              </NavLink>
              <MenuItem className={'dropdown-menu__item'} onClick={handleClick}
                        icon={<LogoutIcon/>}>
                Sign out
              </MenuItem>
            </DropdownMenu>
          </Menu>
          <IconButton className={classes('notifications')} variant={'transparent'} icon={<NotificationIcon/>} aria-label={'Notifications'}/>
        </div>
      </Block>
    </>
  )
}