import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {Box, Center, Flex, Heading, useBreakpointValue} from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import "swiper/css";
import LockedContentIcon from 'icons/LockedContentIcon';
import {useCourseContentData} from "features/course/courseHooks";
import CompleteIcon from "icons/CompleteIcon";
import {cn} from "app/helpers";

const classes = cn('segment-nav');

function ThumbnailIcon({locked = false, complete = false}) {
  let icon;

  if (locked) {
    icon = <LockedContentIcon/>;
  }
  else if (complete) {
    icon = <CompleteIcon color={'white'} bg={'navy'} size={40}/>;
  }

  return icon && <div className={classes('icon')}>{icon}</div>
}

function Thumbnail({data, isSelected}) {
  return (
    <div className={classes('item', {
      selected: isSelected,
      locked: !!data.locked,
    })}>
      <img className={classes('thumbnail')} src={data.content?.thumbnail?.thumbnail?.url} alt={data?.title} />
      <ThumbnailIcon locked={!!data.locked} complete={data.progress?.complete >= 1} />
    </div>
  );
}

function ActivityCard({id, isSelected}) {
  const data = useCourseContentData(id);
  return (
    !data.locked ?
      <NavLink to={`/course/content/${data.id}`}>
        <Thumbnail data={data} isSelected={isSelected}/>
      </NavLink>
      : <Thumbnail data={data} isSelected={isSelected}/>
  )
}

export default function SegmentNav({segment, current}) {
  const slideWidth = useBreakpointValue({
    base: '164px',
    lg: '198px',
  });
  const spaceBetween = useBreakpointValue({
    base: 8,
    lg: 16,
  });
  const children = segment.children || [];
  const currentIndex = children.findIndex(item => item.id === current?.id);

  const [swiperRef, setSwiperRef] = useState();

  useEffect(() => {
    if (currentIndex > -1) {
      swiperRef?.slideTo(currentIndex);
    }
  }, [currentIndex, swiperRef]);

  return (
    <Box className={classes()} mt={{base: '24px', lg: '32px'}}>
      <Heading mb={'16px'} fontSize={{base: '18px', lg: '16px'}} color='primary.navy'>Activities</Heading>
      <Box position='relative'>
        <Swiper
          onSwiper={setSwiperRef}
          slidesPerView={'auto'}
          spaceBetween={spaceBetween}
          className='swiper'
          modules={[Navigation]}
          navigation
        >
          {children.map((activity, index) =>
            <SwiperSlide key={activity.id} virtualIndex={index} style={{width: slideWidth}}>
              <ActivityCard id={activity.id} isSelected={current?.id === activity.id || current?.parent?.id === activity.id || current?.parents?.filter(parent => parent.id === activity.id).length > 0 } />
            </SwiperSlide>
          )}
        </Swiper>
      </Box>
    </Box>
  );
};
