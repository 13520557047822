import authService from '../auth/authService'

const getProfile = async () => {
    const response = await authService.getClient().request({
        url: `/api/v1/account`,
        method: 'GET',
    })
    return response.data
}

const updateProfile = async (data) => {
    const response = await authService.getClient().request({
        url: `/api/v1/account`,
        method: 'PATCH',
        data: data,
    })
    return response.data
}

const updateAvatar = async (data) => {
    const formData = {
        'photo': {
            'data': data.base,
            'extension': data.extension
        }
    }
    const response = await authService.getClient().request({
        url: `/api/v1/profile`,
        method: 'PATCH',
        data: formData,
    })
    return response.data
}

const profileService = {
    getProfile,
    updateProfile,
    updateAvatar
}

export default profileService
