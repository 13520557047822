import {useContext, useMemo, useState} from 'react';
import { Box } from '@chakra-ui/react';
import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import {ActivityContext} from "components/course/Activity/ActivityContext";

export default function SummaryTable({data}) {
  const {onNext} = useContext(ActivityContext);
  const { id, content } = data;
  const items = content?.content?.items || [];
  const contentIsEditable = !!content?.content?.is_editable;
  const rowsNumber = items.reduce(
    (max, item) => {
      // Items contain reference to course content in the `content` property,
      // so there are chain of 3 such properties. `content` is probably the
      // best name for this property anyway.
      const colItems = item?.content?.content?.content?.items || [];
      const colRowsNumber = colItems.reduce((total, colItem) => total + (parseInt(colItem?.quantity) || 1), 0);
      return (colRowsNumber > max) ? colRowsNumber : max;
    },
    0
  );

  const defaultValues = useMemo(() => {
    const values = {};

    items.forEach((item, colIndex) => {
      const savedValues = item?.content?.answer?.value || [];
      // @todo Slice for quantity?
      savedValues.map(value => value.values || []).flat().forEach((value, rowIndex) => {
        values[`${colIndex}_${rowIndex}`] = value.trim();
      });
    });

    return values;
    // Should depend on data instead of id, but the component is re-rendered
    // several times, and when slider (activity navigation) is loaded, data
    // object has different structure and misses answers for referenced
    // activities. This only happens when activity page is reloaded, not when
    // navigating to it by submitting previous one, or by using slider
    // navigation.
    // @todo Depend on data instead when data loading is fixed.
  }, [id]);

  const [values, setValues] = useState(defaultValues);

  const isEditable = (colIndex, rowIndex) => {
    return contentIsEditable && !!defaultValues[`${colIndex}_${rowIndex}`];
  };

  const handleInput = (colIndex, rowIndex, value) => {
    const key = `${colIndex}_${rowIndex}`;
    setValues(prevValues => ({...prevValues, [key]: value}));
  };

  const isValid = !contentIsEditable || Object.keys(defaultValues).every(key => values[key] !== '');

  const handleSubmit = () => {
    const answers = items.map((item, colIndex) => {
      return Array.from({length: rowsNumber})
        .map((_, rowIndex) => values[`${colIndex}_${rowIndex}`] || '')
        .filter(value => !!value);
    });

    onNext(answers);
  };

  return (
    <ActivityContext.Provider value={{
      ...useContext(ActivityContext),
      onNext: handleSubmit,
      canSubmit: isValid,
    }}>
      <ActivityWrapper title={content?.title} description={content?.description}>
        <Box className='parse-html' mt={8}>
          <table>
            <thead>
            <tr>
              {items.map((item, index) => {
                return <th key={index}>{item?.title}</th>;
              })}
            </tr>
            </thead>
            <tbody>
            {Array.from({length: rowsNumber}).map((_, rowIndex) => (
              <tr key={rowIndex}>
                {items.map((item, colIndex) => {
                  const cellIsEditable = isEditable(colIndex, rowIndex);

                  return (
                    <td
                      key={colIndex}
                      onInput={cellIsEditable ? event => handleInput(colIndex, rowIndex, event.target.textContent.trim()) : null}
                      className={(cellIsEditable && !values[`${colIndex}_${rowIndex}`]) ? 'no-value' : ''}
                      contentEditable={cellIsEditable}
                      suppressContentEditableWarning={cellIsEditable}
                    >
                      {/* Cell content shouldn't change between re-renders, that's why useMemo() hook is used. */}
                      {/* Truly controlled content will cause the cursor stay at the start on input. */}
                      {defaultValues[`${colIndex}_${rowIndex}`] || ''}
                    </td>
                  );
                })}
              </tr>
            ))}
            </tbody>
          </table>
        </Box>
      </ActivityWrapper>
    </ActivityContext.Provider>
  );
}
