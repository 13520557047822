import {
  Text,
  Box,
  Heading,
  Progress,
  Grid,
  GridItem,
  Hide,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  AccordionIcon
} from "@chakra-ui/react"
import LockedIcon from "../../icons/LockedIcon"
import CheckIcon from "../../icons/CheckIcon"
import {useCourseContentData, useCourseData} from "../../features/course/courseHooks";
import {NavLink} from "react-router-dom";

const CourseContentProgress = ({contentId}) => {
  const data = useCourseContentData(contentId);

  const getProgress = (data) => {
    if (data?.progress?.locked === true) {
      return <LockedIcon size={16}/>
    }

    if (data?.progress?.complete === 1) {
      return <CheckIcon/>
    }

    if (data?.progress?.complete < 1) {
      return <Text fontSize={'12px'} color={'primary.navy'}>{Math.ceil(data?.progress?.complete * 100)}%</Text>
    }
  }

  return <Grid alignItems={'center'} columnGap={2} templateColumns="repeat(12, 1fr)">
    <GridItem colSpan={3}>
      <NavLink to={data.locked ? '' : `/course/content/${data.id}`}>
        <Text title={data?.content?.title} fontSize={'14px'} color={'primary.navy'} overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'}>{data?.content?.title}</Text>
      </NavLink>
    </GridItem>
    <GridItem colSpan={8}>
      <Progress width={'100%'} height={'8px'} my={2} boxShadow='inner' colorScheme={'orange'} borderRadius={'24px'}
                value={Math.ceil(data?.progress?.complete * 100)}/>
    </GridItem>
    <GridItem justifySelf={'center'} colSpan={1}>
      {getProgress(data)}
    </GridItem>
  </Grid>
}

export default function CourseProgress({courseId}) {
  const courseData = useCourseData(courseId);
  const {progress, content = []} = courseData;

  return (
    <>
      <Hide below="lg">
        <Box position={'relative'}>
          <Heading size={'18px'} color={'primary.navy'}>Course progress</Heading>
        </Box>
        {progress &&
        <Text fontSize={'12px'} color={'primary.navy'}>{Math.ceil(progress?.complete * 100)}% complete</Text>}
        <Progress mt={2} mb={4} boxShadow='inner' colorScheme={'orange'} borderRadius={'24px'}
                  value={Math.ceil(progress?.complete * 100)}/>

        <Box maxHeight={'205px'} height={'100%'} overflowY={'auto'}>
          {content.map((module, index) => <CourseContentProgress contentId={module.id} key={module.id}/>)}
        </Box>
      </Hide>
      <Hide above={'lg'}>
        <Accordion allowToggle>
          <AccordionItem border={'none'}>
            <AccordionButton _hover={{background: "none"}} p={0}>
              <Flex alignItems={'flex-start'} width={'100%'} direction={'column'} position={'relative'}>
                <Box position={'absolute'} right={0}>
                  <AccordionIcon color={'primary.navy'} borderColor={'primary.navy'}/>
                </Box>
                <Heading mb={1} size={'18px'} color={'primary.navy'}>Course progress</Heading>
                {progress && <Text color={'primary.navy'}>{Math.ceil(progress?.complete * 100)}% complete</Text>}
                <Progress width={'100%'} mt={2} mb={4} boxShadow='inner' colorScheme={'orange'} borderRadius={'24px'}
                          value={Math.ceil(progress?.complete * 100)}/>
              </Flex>

            </AccordionButton>

            <AccordionPanel p={0}>
              <Box>
                {content.map((module, index) => <CourseContentProgress contentId={module.id} key={module.id}/>)}
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Hide>
    </>
  )
}
