import authService from '../auth/authService'

const getFaqs = async () => {
    const response = await authService.getClient().request({
        url: `/api/v1/faqs`,
        method: 'GET',
    })
    return response.data    
    
}

const getPromotedFaqs = async () => {
    const response = await authService.getClient().request({
        url: `/api/v1/faqs/promoted`,
        method: 'GET',
        
    })
    return response.data    
}


const getStatements = async (url) => {
    url = url.replace(/^\/+/, '').replace(/\/+$/, '');
    const response = await authService.getClient().request({
        url: `/api/v1/${url}`,
        method: 'GET',
    })
    return response.data
}


const generalService = {
    getFaqs,
    getStatements,
    getPromotedFaqs
}

export default generalService