import {Howl} from "howler";

const sounds = {
  'correct': new Howl({
    src: ['/sound/correct1.mp3'],
    html5: true,
  }),
  'incorrect': new Howl({
    src: ['/sound/incorrect.mp3'],
    html5: true,
  }),
  'complete': new Howl({
    src: ['/sound/correct2.mp3'],
    html5: true,
  }),
};

export function playSound(id) {
  for (let i in sounds) {
    sounds[i].stop();
  }

  if (sounds[id]) {
    return sounds[id].play();
  }
}
