import {cn} from "../../../app/helpers";
import FullsreenIcon from "../../../icons/FullsreenIcon";

export default function FullscreenButton({toggleFullscreen}) {
  const classes = cn('player-controls');
  const handleClick = (e) => {
    e.preventDefault();
    toggleFullscreen();
  }

  return <button className={classes('button', {'fullscreen': true})} onClick={handleClick}><FullsreenIcon/></button>
}
