import Like from "components/likes/Like";
import RichText from "components/Activities/RichText";
import {cn} from "app/helpers";
import Pin from "components/likes/Pin";
import {useLikeData} from "features/like/likesHooks";
import {useEncouragementData} from "features/encouragements/encouragementsHooks";
import {useCourseContentData} from "features/course/courseHooks";
import LocationIcon from "icons/LocationIcon";
import ExtensionIcon from "icons/ExtensionIcon";
import {NavLink} from "react-router-dom";

export default function EncouragementCard({id, variant = 'minimal', forceReload = false}) {
  const entityType = 'encouragement';
  const classes = cn('encouragement');
  const [like] = useLikeData(entityType, id);
  const [encouragement, isLoading] = useEncouragementData(id, forceReload);
  const module = encouragement?.course_content[0]?.module;
  const segment = encouragement?.course_content[0]?.segment;
  useCourseContentData(encouragement?.course_content[0]?.id);

  return <div className={classes({
    variant,
    sticky: like?.sticky,
    liked: !!like?.id,
    loading: isLoading,
  })}>
    <div className={classes('inner')}>
      <div className={classes('info')}>
        <div className={classes('description')}>
          <RichText text={encouragement?.description}/>
        </div>
        {variant === 'full' && (module || segment) && <div className={classes('course')}>
          {module && <div className={classes('module')}>
            <div className={classes('info-icon')}>
              <LocationIcon size={16} color={'navy'} />
            </div>
            <NavLink to={`/course/content/${module.id}`}>{module.label}</NavLink>
          </div>}
          {segment && <div className={classes('segment')}>
            <div className={classes('info-icon')}>
              <ExtensionIcon size={16} color={'navy'} />
            </div>
            <NavLink to={`/course/content/${segment.id}`}>{segment.label}</NavLink>
          </div>}
        </div>}
      </div>
      <div className={classes('actions')}>
        <Like entityType={entityType} entityId={id}
              confirmationMessage={variant === 'full' &&
                <p>Unsaving an encouragement means you will no longer be able to revisit it. All
                  encouragements can be resaved by revisiting their relevent course content.</p>
              }
              confirmationButton={'I Understand'}
        />
        {variant !== 'minimal' && <Pin entityType={entityType} entityId={id}/>}
      </div>
    </div>
    <div className={classes('bottom')}/>
  </div>
}
