import {
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { contactSchema } from 'schemas';
import SendButton from 'buttons/SendButton';
import FormField from 'components/Input';
import Textarea from 'components/Textarea';

export default function ContactForm({onSubmit, initialValues = {}}) {
  const { values, isValid, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues: {
      from: '',
      subject: '',
      message: '',
      ...initialValues,
    },
    validationSchema: contactSchema,
    validateOnMount: true,
    onSubmit,
  });

  return (
    <form onSubmit={handleSubmit}>
      <FormField
        name="from"
        label="From"
        type="email"
        value={values.from}
        touched={touched}
        errors={errors}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <FormField
        name="subject"
        label="Subject"
        type="text"
        value={values.subject}
        touched={touched}
        errors={errors}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <FormControl color={'primary.navy'} isInvalid={touched.message && errors.message}>
        <FormLabel>Message</FormLabel>
        <Textarea
          name="message"
          placeholder="Enter text here"
          value={values.message}
          onChange={handleChange}
          onBlur={handleBlur}
        ></Textarea>
        <FormErrorMessage>{errors.message}</FormErrorMessage>
      </FormControl>

      <ButtonGroup mt={8}>
        <SendButton type="submit" isDisabled={!isValid} isLoading={isSubmitting} />
      </ButtonGroup>
    </form>
  );
}
