import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import resourcesService from "../resources/resourcesService";
import {handleApiError} from "../auth/authSlice";

const resourcesAdapter = createEntityAdapter({
  selectId: (resource) => resource.nid,
  sortComparer: (a, b) => {
    const dateA = new Date(a.created?.value);
    const dateB = new Date(b.created?.value);
    return dateA === dateB ? 0 : (dateA > dateB ? -1 : 1);
  },
});
export const resourcesSelector = resourcesAdapter.getSelectors(state => state.resources);

export const fetchResources = createAsyncThunk('resources/fetchResources', async (params, thunkAPI) => {
  try {
    return await resourcesService.getResources(params);
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
});

const resourcesSlice = createSlice({
  name: 'resources',
  initialState: resourcesAdapter.getInitialState({
    isLoading: false,
    isSuccess: false,
    isError: false,
  }),
  extraReducers: (builder) => {
    builder
      .addCase(fetchResources.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchResources.fulfilled, (state, action) => {
        resourcesAdapter.setMany(state, action.payload.items);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(fetchResources.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
  },
});

export default resourcesSlice.reducer;
