import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@chakra-ui/react';
import Block from '../layout/Block';
import { fetchConsultants } from '../../features/consultants/consultantsSlice';
import ConsultantCard from '../consultant/ConsultantCard';

export default function ConsultantsBlock() {
  const dispatch = useDispatch();
  const {data, isError, isLoading} = useSelector(state => state.consultants);
  // For now the block is used on the home page only - trigger fetching data
  // every mount, so the data is latest on each arrival to the home page.
  // Consultants page utilizes route loader, so also forces data load.
  const [triggeredLoading, setTriggeredLoading] = useState(false);

  useEffect(() => {
    if (!triggeredLoading && !isLoading && !isError) {
      setTriggeredLoading(true);
      dispatch(fetchConsultants({items_per_page: 2}));
    }
  }, [triggeredLoading, isLoading, isError]);

  return ( data.length > 0 &&
    <Block title={'Our Consultants'} to={'/consultants'}>
      <Grid gap={4} templateColumns={{base: '1fr', lg: '1fr 1fr' }}>
        {data.map(item => <ConsultantCard key={item.uid} data={item} />)}
      </Grid>
    </Block>
  );
}
