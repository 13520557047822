import {colorToHex} from "app/helpers";

function BookmarkIcon({color = 'navy', variant = 'solid', size = 24}) {
  if (variant === 'outline') {
    return (
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.50002 20.2499V5.3077C5.50002 4.80257 5.67502 4.375 6.02502 4.025C6.37502 3.675 6.80259 3.5 7.30772 3.5H16.6923C17.1974 3.5 17.625 3.675 17.975 4.025C18.325 4.375 18.5 4.80257 18.5 5.3077V20.2499L12 17.4615L5.50002 20.2499ZM7 17.95L12 15.8L17 17.95V5.3077C17 5.23077 16.9679 5.16024 16.9038 5.09613C16.8397 5.03203 16.7692 4.99998 16.6923 4.99998H7.30772C7.23079 4.99998 7.16026 5.03203 7.09615 5.09613C7.03205 5.16024 7 5.23077 7 5.3077V17.95Z"
          fill={colorToHex(color)}/>
      </svg>
    );
  }

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.50002 20.2499V5.3077C5.50002 4.80257 5.67502 4.375 6.02502 4.025C6.37502 3.675 6.80259 3.5 7.30772 3.5H16.6923C17.1974 3.5 17.625 3.675 17.975 4.025C18.325 4.375 18.5 4.80257 18.5 5.3077V20.2499L12 17.4615L5.50002 20.2499Z"
        fill={colorToHex(color)}/>
    </svg>
  );
}

export default BookmarkIcon;
