import {FormControl, FormLabel, FormErrorMessage, Input} from '@chakra-ui/react'


const FormField = ({touched, name, type, value, placeholder, onChange, onBlur, label, errors, id}) => {

  return (
    <FormControl color={'primary.navy'} my={4} isInvalid={errors[name] && touched[name]}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <Input bg={'white'} type={type} name={name} id={id} value={value} placeholder={placeholder} onChange={onChange}
             onBlur={onBlur}/>
      {errors[name] && touched[name] ? <FormErrorMessage>{errors[name]}</FormErrorMessage> : ""}
    </FormControl>
  )
}

export default FormField

