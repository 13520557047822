import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {LoadBatch} from "app/LoadBatch";
import {fetchNotes, notesSelector} from "features/notes/notesSlice";

let noteBatch;

export function useNoteData(id, reload = false) {
  noteBatch = noteBatch || new LoadBatch();
  const dispatch = useDispatch();
  const note = useSelector(state => notesSelector.selectById(state, id));
  const batch = noteBatch;
  const [isLoaded, setLoaded] = useState(batch.isLoaded(id));
  const isLoading = batch.isBatched(id) || batch.isLoading(id);
  const isSuccess = isLoaded || note;

  useEffect(() => {
    if (reload || !isSuccess) {
      batch.add(id, {
        onLoad: () => {
          setLoaded(batch.isLoaded(id));
        },
      });

      setTimeout(() => {
        const ids = batch.next();

        if (ids.length > 0) {
          dispatch(fetchNotes({
            'id': ids,
          })).then(() => {
            batch.load(ids);
          });
        }
      }, 30);
    }
  }, [dispatch, batch, id, reload]);

  return [note, isLoading, isSuccess];
}

export function useNotesData(params) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [ids, setIds] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const notes = useSelector(state => notesSelector.selectAll(state).filter(note => ids.includes(note.id)));

  useEffect(() => {
    if (!loading && !loaded) {
      setLoading(true);
      dispatch(fetchNotes(params)).then((action) => {
        const data = action.payload;
        const loadedIds = [];
        data.items.forEach((item) => {
          loadedIds.push(item.entity_id);
        });
        setIds(loadedIds);
        setPage(data.page);
        setTotal(data.total);
        setLoading(false);
        setLoaded(true);
      });
    }
  }, [dispatch, params]);

  return [notes, page, total];
}
