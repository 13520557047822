import {resourcesSelector} from "../../features/resources/resourcesSlice";
import {useSelector} from "react-redux";
import Layout from "../../components/layout/Layout";
import Block from "../../components/layout/Block";
import {useState} from "react";
import Sort from "../../components/listing/Sort";
import PageHeading from "../../components/layout/PageHeading";
import FilterWrapper from "../../components/listing/FilterWrapper";
import ResourceCard from "../../components/resources/ResourceCard";
import {cn} from "../../app/helpers";
import Filter from "../../components/listing/Filter";

export default function ResourcesPage() {
  const classes = cn('resources');
  const defaultSort = 'created_asc';
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [sort, setSort] = useState(defaultSort);
  const resources = useSelector(state => {
    const resources = resourcesSelector.selectAll(state);
    const [sortBy, sortDir] = sort.split('_');

    return resources
      .filter(resource => selectedOptions.length === 0 || (resource.locked ? selectedOptions.includes('locked') : selectedOptions.includes('unlocked')))
      .sort((a, b) => {
        let propA;
        let propB;
        switch (sortBy) {
          case 'created':
            propA = new Date(a.created?.value);
            propB = new Date(b.created?.value);
            break;
          case 'title':
            propA = a.title;
            propB = b.title;
            break;
          default:
            return 0;
        }

        if (propA < propB) {
          return sortDir === 'asc' ? -1 : 1;
        }
        if (propA > propB) {
          return sortDir === 'asc' ? 1 : -1;
        }

        return 0;
      });
  });

  const header = {
    title: 'Resources',
  };

  const filerOptions = [
    {
      id: 'unlocked',
      title: 'Unlocked',
    },
    {
      id: 'locked',
      title: 'Locked',
    },
  ];

  const sortOptions = [
    {
      id: 'created_desc',
      title: 'Newest',
    },
    {
      id: 'created_asc',
      title: 'Oldest',
    },
    {
      id: 'title_asc',
      title: 'A-Z',
    },
    {
      id: 'title_desc',
      title: 'Z-A',
    },
  ];

  const handleFilter = (selected) => {
    setSelectedOptions(selected);
  };

  const handleSort = (selected) => {
    setSort(selected);
  };

  return <Layout headerInfo={header}>
    <Block className={classes()}>
      <PageHeading title={header.title} isMain={true} size={'md'}>
        {<FilterWrapper>
          <Filter options={filerOptions} onChange={handleFilter}/>
          <Sort options={sortOptions} defaultValue={defaultSort} onChange={handleSort} />
        </FilterWrapper>}
      </PageHeading>
      <div className={classes('list')}>
        {resources.length > 0 ? resources.map((resource) => <ResourceCard data={resource} key={resource.nid}/>) : 'There are no resources added yet.'}
      </div>
    </Block>
  </Layout>;
}
