import { useState } from 'react'
import { FormControl, FormLabel, FormErrorMessage, Input, Button, InputRightElement, InputGroup } from '@chakra-ui/react'
import VisibilityHide from '../icons/VisibilityHide'
import VisibilityShow from '../icons/VisibilityShow'


const FormField = ({ touched, name,  value, placeholder, onChange, onBlur, label, errors, id }) => {
    const [show, setShow] = useState(false)
    const handleClick = () => setShow(!show)
    return (
            <FormControl color={'primary.navy'} my={4} isInvalid={errors[name] && touched[name] }>
                <FormLabel htmlFor={id}>{label}</FormLabel>
                <InputGroup size='md'>
                    <Input bg={'white'} type={show ? 'text' : 'password'} id={id}  value={value} placeholder={placeholder} onChange={onChange} onBlur={onBlur}/>
                    <InputRightElement width='4.5rem'>
                    <Button variant={'ghost'} h='1.75rem' size='sm' onClick={handleClick}>
                    {show ? <VisibilityHide /> : <VisibilityShow/>}
                    </Button>
                </InputRightElement>
                </InputGroup>
                {errors[name] && touched[name] ? <FormErrorMessage>{errors[name]}</FormErrorMessage> : ""} 
            </FormControl>
    )
}

export default FormField

