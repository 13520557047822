import { Accordion } from "@chakra-ui/react"
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useEffect } from "react";
import Faq from "../faqs/Faq";
import { getPromotedFaqs } from "../../features/general/generalSlice";
import { reset } from "../../features/general/generalSlice";
import Block from "../layout/Block";




export default function FaqBlock() {

    const dispatch = useDispatch()

    const { promotedFaqs, isError, isSuccess, message } = useSelector(
        (state) => state.general
    )

    useEffect(() => {
        if (isError) {
            toast.error(message, {
              autoClose: false,
              onClose: () => dispatch(reset())
            })
        }
    }, [isError, message])
    
    useEffect(() => {
      if (!isSuccess && !isError) {
          dispatch(getPromotedFaqs())
      }
    }, [])

      const printFaqs = () => {

        return promotedFaqs?.items?.map(faq => <Faq data={faq} key={faq.nid}/>)
     }


  return (
    <Block title={'Course FAQs'} to={'/faq'}>
      {isSuccess &&
      <Accordion allowToggle>
        {printFaqs()}
      </Accordion>
      }
    </Block>
  )
}
