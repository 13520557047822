import React from "react";

function Profile({color}) {
    const {isActive} = color
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill={isActive? "#EE5622" : "#0C1851"}
      viewBox="0 0 24 24"
      className={isActive? "fill-gray-900" : "fill-gray-300"}
    >
      <mask
        id="mask0_25_1547"
        style={{ maskType: "alpha" }}
        width="24"
        height="24"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill={color} d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_25_1547)">
        <path
          fill={isActive? "fill-gray-900" : "fill-gray-300"}
          d="M6.025 17.3A10.902 10.902 0 018.8 15.8c1-.367 2.067-.55 3.2-.55s2.2.183 3.2.55c1 .367 1.925.867 2.775 1.5a8.035 8.035 0 001.475-2.375c.367-.9.55-1.875.55-2.925 0-2.217-.779-4.104-2.337-5.663C16.104 4.779 14.217 4 12 4s-4.104.779-5.662 2.337C4.779 7.896 4 9.783 4 12c0 1.05.183 2.025.55 2.925.367.9.858 1.692 1.475 2.375zM12 12.75c-.917 0-1.687-.313-2.312-.938-.625-.625-.938-1.395-.938-2.312 0-.917.313-1.687.938-2.312.625-.625 1.395-.938 2.312-.938.917 0 1.687.313 2.312.938.625.625.938 1.395.938 2.312 0 .917-.313 1.687-.938 2.312-.625.625-1.395.938-2.312.938zm0 8.75a9.263 9.263 0 01-3.712-.75 9.58 9.58 0 01-3.013-2.025 9.58 9.58 0 01-2.025-3.013A9.263 9.263 0 012.5 12c0-1.317.25-2.554.75-3.713a9.583 9.583 0 012.025-3.012A9.58 9.58 0 018.288 3.25 9.263 9.263 0 0112 2.5a9.27 9.27 0 013.713.75 9.583 9.583 0 013.012 2.025 9.583 9.583 0 012.025 3.012A9.27 9.27 0 0121.5 12c0 1.317-.25 2.554-.75 3.712a9.58 9.58 0 01-2.025 3.013 9.583 9.583 0 01-3.012 2.025A9.27 9.27 0 0112 21.5zm0-1.5c.9 0 1.771-.146 2.613-.438a7.51 7.51 0 002.237-1.212 7.698 7.698 0 00-2.213-1.175A8.061 8.061 0 0012 16.75c-.933 0-1.812.138-2.637.413A7.153 7.153 0 007.15 18.35a7.51 7.51 0 002.237 1.212A7.919 7.919 0 0012 20zm0-8.75c.5 0 .917-.167 1.25-.5.333-.333.5-.75.5-1.25s-.167-.917-.5-1.25c-.333-.333-.75-.5-1.25-.5s-.917.167-1.25.5c-.333.333-.5.75-.5 1.25s.167.917.5 1.25c.333.333.75.5 1.25.5z"
        ></path>
      </g>
    </svg>
  );
}

export default Profile;