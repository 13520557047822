function HomeIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        fill="none"
        viewBox="0 0 24 24"
      >
        <mask
          id="mask0_627_22770"
          style={{ maskType: "alpha" }}
          width="24"
          height="24"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
        </mask>
        <g mask="url(#mask0_627_22770)">
          <path
            fill="#0C1851"
            d="M4.5 20.5V9.25L12 3.6l7.5 5.65V20.5h-5.6v-6.7h-3.8v6.7H4.5z"
          ></path>
        </g>
      </svg>
    );
  }
  
  export default HomeIcon;