import {useContext, useMemo, useState} from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import Textarea from 'components/Textarea';
import {ActivityContext} from "components/course/Activity/ActivityContext";
import ActivityPager from "components/course/Activity/ActivityPager";

export default function OpenQuestionMulti({data}) {
  const {onNext} = useContext(ActivityContext);
  const { content = {}, answer } = data;
  const {title, description} = content;
  const items = content.content?.items || [];
  const image = content.content?.image?.file;
  const aside = image ? <Image src={image.url} alt={image.alt} /> : null;

  const initialValues = useMemo(() => {
    const savedValues = answer?.value || [];
    // Prepare values for every item filled the same number of values as inputs within item.
    return items.map(item => {
        const savedItem = savedValues.find(value => value.item === item.label);
        return {
          item: item.label,
          values: Array.from({length: item.quantity || 1}).map((_, index) => savedItem?.values?.[index] || ''),
        };
      });
  }, [data]);

  const [values, setValues] = useState(initialValues);
  const isValid = values.every(item => item.values.every(value => value.trim() !== ''));

  const handleChange = (itemIndex, inputIndex, value) => {
    setValues(prevValues => {
      const newItemValues = prevValues[itemIndex].values.toSpliced(inputIndex, 1, value);
      return prevValues.toSpliced(itemIndex, 1, {...prevValues[itemIndex], values: newItemValues});
    });
  };

  const handleSubmit = () => {
    onNext(values);
  };

  return (
    <ActivityContext.Provider value={{
      ...useContext(ActivityContext),
      canSubmit: isValid,
      onNext: handleSubmit,
    }}>
      <ActivityWrapper title={title} description={description} aside={aside}>
        {items.map((item, itemIndex) => {
          const inputNumber = item.quantity || 1;

          return (
            <Box key={itemIndex} mt={4}>
              {item.show_label && <Text mb={{base: 2, lg: 4}}>{item.label}</Text>}
              {Array.from({length: inputNumber}).map((_, inputIndex) => (
                <Textarea
                  minRows={1}
                  value={values[itemIndex].values[inputIndex]}
                  onChange={(event) => handleChange(itemIndex, inputIndex, event.target.value)}
                  key={inputIndex}
                  mt={inputIndex === 0 ? 0 : 4}
                  bg={'white'}
                  placeholder="Enter text here"
                  aria-label={inputNumber > 1 ? `${item.label} - value ${inputIndex + 1}` : item.label}
                />
              ))}
            </Box>
          );
        })}
        <ActivityPager/>
      </ActivityWrapper>
    </ActivityContext.Provider>
  )
}
