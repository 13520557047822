function CoursesIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        fill="none"
        viewBox="0 0 24 24"
      >
        <mask
          id="mask0_610_40298"
          style={{ maskType: "alpha" }}
          width="24"
          height="24"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
        </mask>
        <g mask="url(#mask0_610_40298)">
          <path
            fill="#0C1851"
            d="M8.875 20.5H5c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 013.5 19v-3.875a2.962 2.962 0 001.763-.85A2.394 2.394 0 006 12.5c0-.7-.246-1.292-.737-1.775a2.963 2.963 0 00-1.763-.85V6c0-.417.146-.77.438-1.062A1.444 1.444 0 015 4.5h4a2.477 2.477 0 01.825-1.575A2.43 2.43 0 0111.5 2.3c.65 0 1.208.208 1.675.625.467.417.742.942.825 1.575h4c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v4a2.478 2.478 0 011.575.825A2.43 2.43 0 0121.7 12.5c0 .65-.208 1.208-.625 1.675A2.477 2.477 0 0119.5 15v4c0 .417-.146.77-.438 1.062A1.444 1.444 0 0118 20.5h-3.875c-.083-.717-.375-1.313-.875-1.788-.5-.475-1.083-.712-1.75-.712s-1.25.237-1.75.712-.792 1.071-.875 1.788z"
          ></path>
        </g>
      </svg>
    );
  }
  
  export default CoursesIcon;