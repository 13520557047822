import authService from "../auth/authService";

const getLikes = async (params) => {
  const response = await authService.getClient().request({
    url: `/api/v1/likes`,
    method: 'GET',
    params: params,
  });

  return response.data;
}

/* Creates like for a course content */
const createLike = async (params) => {
  const response = await authService.getClient().request({
    url: `/api/v1/like`,
    method: 'POST',
    data: params,
  })

  return response.data
}

const deleteLike = async (id) => {
  const response = await authService.getClient().request({
    url: `/api/v1/like/${id}`,
    method: 'DELETE',
  })

  return response.data
}

const pinLike = async (id, pinned = true) => {
  const response = await authService.getClient().request({
    url: `/api/v1/like/${id}`,
    method: 'PATCH',
    data: {
      sticky: pinned,
    },
  })

  return response.data
}

const likesService = {
  getLikes,
  createLike,
  deleteLike,
  pinLike,
}

export default likesService
