import {useEffect, useState} from "react";
import wipeService from "../../features/media/wipeService";
import {cn} from "app/helpers";
import {effect} from "@preact/signals-react";
import AnimationPlayer from "./AnimationPlayer";
import {useDispatch} from "react-redux";
import {allowAutoplay, setActiveMedia} from "features/media/mediaSlice";

export const Wipe = () => {
  const classes = cn('wipe');
  const dispatch = useDispatch();
  const wipe = wipeService.currentWipe.value;
  const [showWipe, setShowWipe] = useState(!!wipe.video);
  const [animationStatus, setAnimationStatus] = useState('idle');
  const inSpeed = wipe?.animation?.in?.speed || 500;
  const outSpeed = wipe?.animation?.out?.speed || 2000;

  const handleComplete = () => {
    animationStatus !== 'fadeout' && setAnimationStatus('fadeout');
  };

  const handleError = () => {
    handleComplete();
  };

  effect(() => {
    if (wipe.video && !showWipe) {
      setShowWipe(true);
      setAnimationStatus('mount');
    }
  });

  useEffect(() => {
    if (animationStatus === 'mount') {
      setAnimationStatus('fadein');
    }
  }, [animationStatus]);

  useEffect(() => {
    if (showWipe) {
      dispatch(setActiveMedia(null));
      dispatch(allowAutoplay(false));
    }
    else {
      dispatch(allowAutoplay(true));
    }
  }, [dispatch, showWipe]);

  const transition = 'opacity ' + (animationStatus === 'fadein' || animationStatus === 'mount' ? (inSpeed / 1000).toFixed(3) : (outSpeed / 1000).toFixed(3)) + 's ease';

  return showWipe && <div className={classes({
    animation: animationStatus,
  })} style={{
    opacity: (['mount', 'fadeout'].includes(animationStatus) || !showWipe) ? 0 : 1,
    transition: transition,
  }} onTransitionEnd={() => {
    if (animationStatus === 'fadeout') {
      const nextWipe = wipeService.wipeQueue.value.shift();
      if (nextWipe) {
        wipeService.currentWipe.value = nextWipe;
      } else {
        wipeService.currentWipe.value = {};
      }
      setAnimationStatus('idle');
      setShowWipe(false);
    }

    if (animationStatus === 'fadein') {
      setAnimationStatus('idle');
    }
  }}>
    <AnimationPlayer key={'wipe-' + wipe?.id} files={wipe.video} loop={false} onComplete={handleComplete} onError={handleError} muted={!!wipe.muted} onCompleteOffset={outSpeed} isMain={true} />
  </div>
}
