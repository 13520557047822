import { useNavigate } from 'react-router-dom';
import {
  Box,
  Heading,
  IconButton,
  Image,
  Progress,
  Text,
} from '@chakra-ui/react';
import { cn } from 'app/helpers';
import { useCourseContentData, useCoursesData } from 'features/course/courseHooks';
import SegmentCard from 'components/course/SegmentCard';
import Like from 'components/likes/Like';
import lockedSegment from 'img/lockedSegment.png';
import PlayIcon from 'icons/PlayIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCourseContent,
  getCourseContent,
} from 'features/course/courseContentSlice';
import { useEffect, useState } from 'react';

const classes = cn('resume-course');

function ResumeCourseBlockCompact({segment, module = null}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useCourseContentData(segment.id);
  const {progress, locked, content} = data;
  const isComplete = progress?.complete === 1;
  const isStarted = progress && progress.started;

  // Copy-paste from the SegmentCard.
  const lastChild = useSelector((state) => {
    const children = data?.children || [];
    const child = children.find(activity => activity.locked === false && activity.progress.complete < 1);
    return getCourseContent(state, child?.id);
  });

  const thumbnail = lastChild?.content?.thumbnail?.file?.url || lastChild?.thumbnail?.file?.url || lockedSegment;

  const[isClicked, setIsClicked] = useState(false);

  // See SegmentCard.
  useEffect(() => {
    if (!lastChild.isSuccess && lastChild.id) {
      dispatch(fetchCourseContent(lastChild.id));
    }
  }, [lastChild]);

  const goToCourseContent = () => {
    setIsClicked(true);
    if (!locked) {
      navigate(`/course/content/${segment.id}`);
    }
  }

  // Show nothing if data is not fully loaded.
  if (!data.isSuccess) {
    return null;
  }

  // Inspired by the SegmentCard component.
  // `compact` modifier is here just to highlight that there is another version
  // of this block, though technically it's completely different component right
  // now.
  return (
    <Box onClick={goToCourseContent} className={classes({compact: true})}>
      <Box className={classes('content')}>
        <Box className={classes('thumbnail')}>
          <Image src={locked ? lockedSegment : thumbnail} />
        </Box>
        <Box className={classes('body')}>
          <Heading className={classes('title')}>{content?.title}</Heading>
          {module?.content && <Text className={classes('module')}>{module.content.title}</Text>}
        </Box>
        {!locked && <Box className={classes('actions')}>
          <Like entityId={segment.id} entityType={'course_content'} variant={'bookmark'} />
          <IconButton icon={<PlayIcon variant="inverse" />}
                      variant="transparent"
                      size="24px"
                      onClick={goToCourseContent}
                      aria-label={!isStarted ? 'Get started' : isComplete ? 'Restart' : 'Resume'}
                      isLoading={isClicked}
          />
        </Box>}
      </Box>
      <Box className={classes('progress')}>
        <Progress className={classes('progressbar')} height={'4px'} value={Math.ceil(progress?.complete * 100)} />
      </Box>
    </Box>
  )
}

// Handle 2 block variants (basically, desktop and mobile) by a single component
// for current course/module/segment handling simplicity.
// @todo Extract "current course/module/segment" logic, re-use it in the Module Nav Block on the homepage for example, and divide this component into 2.
export default function ResumeCourseBlock({full = true}) {
  const [courses] = useCoursesData();
  // Get first not completed course; fallback to last not locked.
  // Similar to gathering "current" course in the ModuleNavBlock.
  const course = courses.find(course => !course.locked && course.progress.complete < 1)
    || courses.findLast(course => !course.locked);

  // Show nothing if there are no courses or no started courses.
  if (!course || !courses.some(course => !course.locked && (course.progress.started || course.progress.complete > 0))) {
    return null;
  }

  // Similar to ModuleNav.
  // @todo This doesn't work well if some of modules are not locked initially and so are available if even previous are not completed.
  // @todo Get first not completed instead? ModuleNav and this block should gather "current" course/module/segment in the same way.
  const module = course.content?.findLast(child => !child.locked);

  if (!module) {
    return null;
  }

  const segment = module.children?.find(child => !child?.locked && child.progress?.complete < 1);

  if (!segment) {
    return null;
  }

  return full ? <SegmentCard id={segment.id} isCta={true} /> : <ResumeCourseBlockCompact segment={segment} module={module} />;
}
