function LockedContentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="24" r="8" fill="#0C1851"/>
      <path fill="#fff"
            d="M10.5 35.834a2.893 2.893 0 01-2.125-.876 2.893 2.893 0 01-.875-2.124V17.166c0-.834.292-1.542.875-2.125a2.893 2.893 0 012.125-.875h2v-3.333c0-2.084.729-3.855 2.187-5.314 1.459-1.458 3.23-2.186 5.313-2.186 2.083 0 3.854.728 5.313 2.186 1.458 1.46 2.187 3.23 2.187 5.314v3.333h2c.833 0 1.542.292 2.125.875s.875 1.292.875 2.125v15.666c0 .834-.292 1.542-.875 2.126a2.893 2.893 0 01-2.125.874h-19zm9.5-7.917c.806 0 1.493-.285 2.063-.854.57-.57.854-1.257.854-2.063 0-.805-.285-1.493-.854-2.063A2.814 2.814 0 0020 22.083c-.806 0-1.493.285-2.063.854A2.815 2.815 0 0017.083 25c0 .806.285 1.494.854 2.064.57.568 1.257.853 2.063.853zm-5-13.75h10v-3.333c0-1.39-.486-2.57-1.458-3.542-.973-.972-2.153-1.458-3.542-1.458-1.389 0-2.57.486-3.542 1.458S15 9.445 15 10.834v3.333z"
      />
    </svg>
  );
}

export default LockedContentIcon;