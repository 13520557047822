function LogoutIcon(props) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="16"
        fill="none"
        viewBox="0 0 14 16"
      >
        <path
          fill="#0C1851"
          d="M1.5 15.5c-.417 0-.77-.146-1.063-.438A1.446 1.446 0 010 13.999V2.416c0-.417.146-.77.438-1.062A1.447 1.447 0 011.5.916h5.604v1.25H1.5c-.056 0-.111.028-.167.083-.055.056-.083.111-.083.167v11.583c0 .056.028.111.083.167.056.056.111.083.167.083h5.604v1.25H1.5zm8.938-3.73l-.855-.916 2.021-2.021h-7v-1.25h7l-2.02-2.021.854-.917L14 8.208l-3.563 3.562z"
        ></path>
      </svg>
    );
  }
  
  export default LogoutIcon;