import {colorToHex} from "app/helpers";

function LocationIcon({color = 'navy', variant = 'solid', size = 24}) {
  if (variant === 'outline') {
    return (
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 11.75C12.4833 11.75 12.896 11.5793 13.238 11.238C13.5793 10.896 13.75 10.4833 13.75 10C13.75 9.51667 13.5793 9.104 13.238 8.762C12.896 8.42067 12.4833 8.25 12 8.25C11.5167 8.25 11.104 8.42067 10.762 8.762C10.4207 9.104 10.25 9.51667 10.25 10C10.25 10.4833 10.4207 10.896 10.762 11.238C11.104 11.5793 11.5167 11.75 12 11.75ZM12 19.8C14.0667 17.9667 15.625 16.2123 16.675 14.537C17.725 12.8623 18.25 11.4167 18.25 10.2C18.25 8.3 17.646 6.75 16.438 5.55C15.2293 4.35 13.75 3.75 12 3.75C10.25 3.75 8.77067 4.35 7.562 5.55C6.354 6.75 5.75 8.3 5.75 10.2C5.75 11.4167 6.275 12.8623 7.325 14.537C8.375 16.2123 9.93333 17.9667 12 19.8ZM12 21.8C9.4 19.5333 7.45833 17.4333 6.175 15.5C4.89167 13.5667 4.25 11.8 4.25 10.2C4.25 7.78333 5.02933 5.854 6.588 4.412C8.146 2.97067 9.95 2.25 12 2.25C14.05 2.25 15.8543 2.97067 17.413 4.412C18.971 5.854 19.75 7.78333 19.75 10.2C19.75 11.8 19.1083 13.5667 17.825 15.5C16.5417 17.4333 14.6 19.5333 12 21.8Z"
          fill={colorToHex(color)}/>
      </svg>
    );
  }

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 11.75C12.4833 11.75 12.896 11.5793 13.238 11.238C13.5793 10.896 13.75 10.4833 13.75 10C13.75 9.51667 13.5793 9.104 13.238 8.762C12.896 8.42067 12.4833 8.25 12 8.25C11.5167 8.25 11.104 8.42067 10.762 8.762C10.4207 9.104 10.25 9.51667 10.25 10C10.25 10.4833 10.4207 10.896 10.762 11.238C11.104 11.5793 11.5167 11.75 12 11.75ZM12 21.8C9.4 19.5333 7.45833 17.4333 6.175 15.5C4.89167 13.5667 4.25 11.8 4.25 10.2C4.25 7.78333 5.02933 5.854 6.588 4.412C8.146 2.97067 9.95 2.25 12 2.25C14.05 2.25 15.8543 2.97067 17.413 4.412C18.971 5.854 19.75 7.78333 19.75 10.2C19.75 11.8 19.1083 13.5667 17.825 15.5C16.5417 17.4333 14.6 19.5333 12 21.8Z"
        fill={colorToHex(color)}/>
    </svg>
  );
}

export default LocationIcon;
