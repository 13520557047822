import {colorToHex} from "app/helpers";

function BulbIcon({color = 'navy', variant = 'solid', size = 24}) {
  if (variant === 'outline') {
    return (
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 20.95C11.6 20.95 11.246 20.8417 10.938 20.625C10.6293 20.4083 10.4167 20.1333 10.3 19.8H10C9.58333 19.8 9.22933 19.65 8.938 19.35C8.646 19.05 8.5 18.7 8.5 18.3V15C7.48333 14.4 6.68767 13.5833 6.113 12.55C5.53767 11.5167 5.25 10.4167 5.25 9.25C5.25 7.36667 5.90433 5.771 7.213 4.463C8.521 3.15433 10.1167 2.5 12 2.5C13.8833 2.5 15.4793 3.15433 16.788 4.463C18.096 5.771 18.75 7.36667 18.75 9.25C18.75 10.45 18.4627 11.5583 17.888 12.575C17.3127 13.5917 16.5167 14.4 15.5 15V18.3C15.5 18.7 15.354 19.05 15.062 19.35C14.7707 19.65 14.4167 19.8 14 19.8H13.7C13.5833 20.1333 13.3707 20.4083 13.062 20.625C12.754 20.8417 12.4 20.95 12 20.95ZM10 18.3H14V17.35H10V18.3ZM10 16.475H14V15.5H10V16.475ZM9.8 14H11.4V11.1L9.25 8.95L10.1 8.1L12 10L13.9 8.1L14.75 8.95L12.6 11.1V14H14.2C15.1 13.5667 15.8333 12.929 16.4 12.087C16.9667 11.2457 17.25 10.3 17.25 9.25C17.25 7.78333 16.7417 6.54167 15.725 5.525C14.7083 4.50833 13.4667 4 12 4C10.5333 4 9.29167 4.50833 8.275 5.525C7.25833 6.54167 6.75 7.78333 6.75 9.25C6.75 10.3 7.03333 11.2457 7.6 12.087C8.16667 12.929 8.9 13.5667 9.8 14Z"
          fill={colorToHex(color)}/>
      </svg>
    );
  }

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 20.95C11.6 20.95 11.246 20.8417 10.938 20.625C10.6293 20.4083 10.4167 20.1333 10.3 19.8H10C9.58333 19.8 9.22933 19.65 8.938 19.35C8.646 19.05 8.5 18.7 8.5 18.3V15C7.48333 14.4 6.68767 13.5833 6.113 12.55C5.53767 11.5167 5.25 10.4167 5.25 9.25C5.25 7.36667 5.90433 5.771 7.213 4.463C8.521 3.15433 10.1167 2.5 12 2.5C13.8833 2.5 15.4793 3.15433 16.788 4.463C18.096 5.771 18.75 7.36667 18.75 9.25C18.75 10.45 18.4627 11.5583 17.888 12.575C17.3127 13.5917 16.5167 14.4 15.5 15V18.3C15.5 18.7 15.354 19.05 15.062 19.35C14.7707 19.65 14.4167 19.8 14 19.8H13.7C13.5833 20.1333 13.3707 20.4083 13.062 20.625C12.754 20.8417 12.4 20.95 12 20.95ZM10 18.3H14V17.35H10V18.3ZM10 16.475H14V15.5H10V16.475ZM12.6 14V11.1L14.75 8.95L13.9 8.1L12 10L10.1 8.1L9.25 8.95L11.4 11.1V14H12.6Z"
        fill={colorToHex(color)}/>
    </svg>
  );
}

export default BulbIcon;
