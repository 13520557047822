import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import EncouragementCard from "components/course/EncouragementCard";

export default function Encouragement({data}) {
    const { content } = data

    return(
      <ActivityWrapper title={data?.content?.title} description={data?.content?.description}>
          <div className={'encouragement-list'}>
              {content?.content?.items?.map(item => {
                  return <EncouragementCard key={item.id} id={item.id} forceReload={true} />
              })}
          </div>
      </ActivityWrapper>
    )
}
