import {useFormik} from "formik"
import {passwordSchema} from "schemas"
import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import {Center, Text, Heading, Stack, Skeleton} from '@chakra-ui/react'
import {useSelector, useDispatch} from 'react-redux'
import {oneTimeLogin, setPassword} from 'features/auth/authSlice'
import {toast} from 'react-toastify'

import {TAndCModal} from 'components/registrationPanels/TAndCModal';


import Btn from "../components/Button";
import PasswordInput from "../components/PasswordInput"
import SignInFormPanel from "../components/layout/SiginInFormPanel";
import RegisterLoginLayout from "../components/layout/RegisterLoginLayout";


export default function FirstTimeLogin() {
  const navigate = useNavigate()
  const {id, timestamp, hash} = useParams()
  const userDetails = {id, timestamp, hash}
  const dispatch = useDispatch()
  const {isLoading, isError, isSuccess, message} = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
  }, [dispatch, isError, message])

  useEffect(() => {
    dispatch(oneTimeLogin(userDetails))
  }, [])

  const onSubmit = async (values) => {
    const data = {id, timestamp, hash, ...values}
    dispatch(setPassword(data)).then(action => {
      if (action.meta.requestStatus === 'fulfilled') {
        toast.success('Password successfully set.');
        navigate('/');
      }
    });
  }

  const {values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit} = useFormik({
    initialValues: {
      password: "",
      confirmPassword: ""
    },
    validationSchema: passwordSchema,
    onSubmit,
  })


  return (
    <RegisterLoginLayout>
      <SignInFormPanel>
        {!isError ?
          <>
            {isLoading &&
            <Stack>
              <Skeleton height='20px'/>
              <Skeleton height='20px'/>
              <Skeleton height='20px'/>
            </Stack>
            }
            {isSuccess &&
            <>
              <Heading mt={4} fontSize={'xl'} color={'primary.navy'} as={'h2'}>Welcome to Change your mind... <br/>create
                new results</Heading>
              <Text mt={4} color={'primary.navy'}>To begin, please finish setting up your password</Text>
              <form onSubmit={handleSubmit}>
                <PasswordInput touched={touched} errors={errors} id={"password"} name={"password"} type={"password"}
                               value={values.password} onBlur={handleBlur} placeholder={""} onChange={handleChange}
                               label={"Password"}/>
                <Text color={'secondary.lightblue'} fontSize={'sm'}>Must be at least 8 characters and contain at least
                  one number, one uppercase and one lowercase letter.</Text>
                <PasswordInput touched={touched} errors={errors} id={"confirmPassword"} name={"confirmPassword"}
                               type={"confirmPassword"} value={values.confirmPassword} onBlur={handleBlur}
                               placeholder={""} onChange={handleChange} label={"Confirm Password"}/>
                <Text my={8} color={'primary.navy'}>By creating your account you agree to our <TAndCModal
                  link={'IP Statement'} title={'IP Statement'}/> and <TAndCModal link={'Terms & Conditions'}
                                                                                 title={'Terms & Conditions'}/> </Text>
                <Center>
                  <Btn isLoading={isSubmitting} type={"submit"} name={"submit"} copy={"Agree and Register"}
                       txColor={'white'} bgColor={'primary.flame'}/>
                </Center>
              </form>
            </>
            }
          </>
          :
          <Heading color={'primary.navy'} textAlign={'center'} size={'xl'} as={'h2'}>Sign in link expired please contact
            support</Heading>}
      </SignInFormPanel>
    </RegisterLoginLayout>
  );
}