import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  overlay: {
    bg: 'other.overlay',
  },
  dialogContainer: {
    alignItems: 'center',
  },
  dialog: {
    borderColor: 'washedOut.blue',
    borderRadius: '2xl',
    borderWidth: '1px',
    // @todo Extend theme's shadows property for reusability.
    // @see https://chakra-ui.com/docs/styled-system/style-props#shadow
    boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.09), 0px 3px 2px 0px rgba(0, 0, 0, 0.05), 0px 5px 2px 0px rgba(0, 0, 0, 0.01)',
    marginRight: '15px',
    marginLeft: '15px',
    padding: '33px 26px'
  },
  body: {
    padding: '33px 0'
  },
  footer: {
    padding: '0'
  },
  header: {
    padding: '0'
  }
})

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
})