import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import profileService from './profileService'
import {handleApiError} from "../auth/authSlice";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  data: {},
  profileUpdated: false
}

export const getProfile = createAsyncThunk('profile/getProfile', async (arg, thunkAPI) => {
  try {
    return await profileService.getProfile()
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

export const updateProfile = createAsyncThunk('profile/updateProfile', async (values, thunkAPI) => {
  try {
    return await profileService.updateProfile(values)
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

export const updateAvatar = createAsyncThunk('profile/updateAvatar', async (values, thunkAPI) => {
  try {
    return await profileService.updateAvatar(values)
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

export const setSuccess = () => (email) => {
  return email
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetProfile: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
      state.data = {}
      state.profileUpdated = false
    },
    setSuccess: (state) => {
      state.isSuccess = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.data = action.payload
        state.isError = false
        state.message = ''
        state.profileUpdated = false
        state.avatarUpdated = false
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload.message
        state.data = {}
      })
      .addCase(updateProfile.pending, (state) => {
        state.isLoading = true
        state.isSuccess = false
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.data = action.payload
        state.isError = false
        state.message = 'Account updated'
        state.profileUpdated = true
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload.message
        state.data = {}
      })
      .addCase(updateAvatar.pending, (state) => {
        state.isLoading = true
        state.isSuccess = false
      })
      .addCase(updateAvatar.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.message = 'Account updated'
        state.avatarUpdated = true
        state.data.profile = action.payload
      })
      .addCase(updateAvatar.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload.message
        state.data = {}
      })
  }
})

export const {resetProfile} = profileSlice.actions
export default profileSlice.reducer