import React from 'react'
import {Avatar, Box, Menu, MenuButton, MenuItem, MenuList, Flex} from "@chakra-ui/react"
import AvatarEditIcon from "../../icons/AvatarEditIcon"
import DeleteIcon from "../../icons/DeleteIcon"
import UploadIcon from '../../icons/UploadIcon'
import {updateAvatar} from 'features/profile/profileSlice'
import {useDispatch} from 'react-redux'
import {getImageDerivative} from "app/helpers";

const EditAvatar = ({data}) => {
  const dispatch = useDispatch()
  const hiddenFileInput = React.useRef(null);

  const handleClick = (e) => {
    hiddenFileInput.current.click();
  }

  const handleChange = async (e) => {
    const fileUploaded = e.target.files[0];
    const base64 = await convertBase64(fileUploaded)
    const firstComma = base64.indexOf(",")
    const base64Data = base64.slice(firstComma + 1)
    const ext = fileUploaded.name.split('.').pop()
    const data = {base: base64Data, extension: ext}

    dispatch(updateAvatar(data))
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const removeAvatar = () => {
    const data = {base: '', extension: 'png'}
    dispatch(updateAvatar(data))
  }

  const avatar = getImageDerivative(data?.profile?.photo, '136x136');
  const photoUrl = avatar?.url || '';

  return (
    <Menu>
      <MenuButton>
        <Box position={'relative'}>
          <Box>
            {/* Avatar is not reset to placeholder image/bg when src is */}
            {/* not empty and then becomes empty. Element also keeps */}
            {/* `data-loaded` attribute in this case. Let's force key to rerender. */}
            {/* @see https://github.com/chakra-ui/chakra-ui/issues/7591 */}
            <Avatar size='xl' src={photoUrl} key={photoUrl}/>
          </Box>
          <Box position={'absolute'} bottom={0} right={0}>
            <AvatarEditIcon/>
          </Box>
        </Box>

      </MenuButton>

      <MenuList color={'primary.navy'} py={4} borderRadius={'16px'}>
        <Flex direction={'column'} gap={2}>
          <MenuItem as='b' width={'fit-content'} mx={4} borderRadius={'8px'} _hover={{bg: 'lightGreyThree.100'}}>
            <Flex onClick={handleClick} alignItems={'center'}>
              <Box mr={4}>
                <UploadIcon/>
              </Box>
              Choose from library
            </Flex>
            <input type="file" style={{display: 'none'}} ref={hiddenFileInput} onChange={handleChange}/>
          </MenuItem>
          {data?.profile?.photo &&
          <MenuItem as='b' width={'fit-content'} mx={4} borderRadius={'8px'} _hover={{bg: 'lightGreyThree.100'}}>
            <Flex onClick={removeAvatar} alignItems={'center'}>
              <Box mr={4}>
                <DeleteIcon/>
              </Box>
              Remove current photo
            </Flex>
          </MenuItem>}
        </Flex>
      </MenuList>
    </Menu>
  )
}

export default EditAvatar