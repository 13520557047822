function FaqIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          fill="#0C1851"
          d="M7 10.75a.633.633 0 00.475-.2.674.674 0 00.192-.483.674.674 0 00-.192-.484.633.633 0 00-.475-.2.673.673 0 00-.683.683.673.673 0 00.683.684zm-.483-2.4h.95c0-.322.044-.58.133-.775.089-.195.3-.458.633-.792.3-.289.528-.558.684-.808A1.62 1.62 0 009.15 5.1c0-.578-.206-1.028-.617-1.35-.41-.322-.9-.483-1.466-.483-.556 0-1.017.15-1.384.45-.366.3-.627.65-.783 1.05l.867.333a1.94 1.94 0 01.408-.633c.195-.211.486-.317.875-.317.4 0 .694.108.883.325.19.217.284.453.284.708 0 .223-.067.428-.2.617-.134.189-.3.372-.5.55-.434.389-.709.703-.825.942-.117.239-.175.591-.175 1.058zM7 13.333a6.176 6.176 0 01-2.475-.5 6.386 6.386 0 01-2.008-1.35 6.387 6.387 0 01-1.35-2.008A6.175 6.175 0 01.667 7c0-.878.166-1.703.5-2.475a6.389 6.389 0 011.35-2.008 6.387 6.387 0 012.008-1.35A6.175 6.175 0 017 .667a6.18 6.18 0 012.475.5 6.388 6.388 0 012.008 1.35 6.388 6.388 0 011.35 2.008c.334.772.5 1.597.5 2.475 0 .878-.166 1.703-.5 2.475a6.386 6.386 0 01-1.35 2.008 6.387 6.387 0 01-2.008 1.35 6.18 6.18 0 01-2.475.5z"
        ></path>
      </svg>
    );
  }
  
  export default FaqIcon;