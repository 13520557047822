import {useFormik} from "formik"
import {useContext} from "react";
import { Image } from '@chakra-ui/react';
import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import Textarea from '../Textarea';
import {ActivityContext} from "components/course/Activity/ActivityContext";
import ActivityPager from "components/course/Activity/ActivityPager";

export default function OpenQuestion({data}) {
  const {onNext} = useContext(ActivityContext);
  const { content = {}, answer } = data;
  const { title, description} = content;
  const image = content.content?.image?.file;
  const aside = image ? <Image src={image.url} alt={image.alt} /> : null;

  const onSubmit = async (values) => {
    await onNext(values.answer);
  }

  // @todo Is Formik really needed here?
  const {values, handleChange, handleSubmit} = useFormik({
    initialValues: {
      answer: answer?.value || '',
    },
    onSubmit,
  });
  const isValid = values.answer?.trim() !== '';

  return (
    <ActivityContext.Provider value={{
      ...useContext(ActivityContext),
      onNext: () => {
        handleSubmit();
      },
      canSubmit: isValid,
    }}>
      <ActivityWrapper title={title} description={description} aside={aside}>
        <Textarea onChange={handleChange} id={'answer'} value={values.answer} mt={4} bg={'white'} placeholder="Enter text here" required={true} />
        <ActivityPager/>
      </ActivityWrapper>
    </ActivityContext.Provider>
  )
}
