import LocationIcon from '../../icons/LocationIcon'
import CalendarIcon from '../../icons/CalendarIcon'
import moment from "moment/moment"
import {Checkbox, Button, Text} from '@chakra-ui/react'
import {cn} from "app/helpers";
import {useNoteData} from "features/notes/notesHooks";
import ExpandIcon from "icons/ExpandIcon";
import EditIcon from "icons/EditIcon";
import {useCourseContentData} from "features/course/courseHooks";
import {NavLink} from "react-router-dom";
import {useState} from "react";
import NoteExpanded from "components/notes/NoteExpanded";

const Note = ({id, isSelected = false, onChange}) => {
  const classes = cn('note');
  const [data] = useNoteData(id);
  const courseContent = useCourseContentData(data?.course_content?.id);
  const segmentData = useCourseContentData(courseContent?.segment?.id);
  const [expanded, setExpanded] = useState(false);
  const [editing, setEditing] = useState(false);

  const handleChange = (e) => {
    onChange && onChange(id, e.target.checked);
  };

  const handleExpand = (editing) => {
    setExpanded(true);
    setEditing(editing);
  }

  return <div className={classes({
    card: true,
  })}>
    <div className={classes('inner')}>
      <div className={classes('content')}>
        <div className={classes('title')}>
          {data?.title}
          {onChange && <div className={classes('checkbox')}><Checkbox isChecked={isSelected} onChange={handleChange}/></div>}
        </div>
        <div className={classes('body')}>{data?.body.split("\n").filter(text => text.trim()).map((text, line) => <p key={line}>{text}</p>)}</div>
      </div>
      <div className={classes('meta')}>
        {segmentData.isSuccess && <div className={classes('meta-row')}>
          <div className={'meta-icon'}><LocationIcon size={16}/></div>
          <NavLink to={`/course/content/${courseContent.id}`}>{segmentData?.content?.title}</NavLink>
        </div>}
        <div className={classes('meta-row')}>
          <div className={'meta-icon'}><CalendarIcon size={16}/></div>
          {moment(data?.created.value).format("DD MMM YY")}
        </div>
      </div>
    </div>
    <div className={classes('actions')}>
      <Button variant={'outlineNavy'} leftIcon={<ExpandIcon size={20} color={'navy'}/>} onClick={() => handleExpand(false)}>Expand</Button>
      <Button variant={'transparent'} leftIcon={<EditIcon size={20} color={'navy'} variant={'outline'}/>} onClick={() => handleExpand(true)}>Edit</Button>
    </div>

    <NoteExpanded id={id} editing={editing} isOpen={expanded} onClose={() => setExpanded(false)}/>
  </div>
}

export default Note;
