import { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Layout from "../components/layout/Layout"
import Course from "../components/course/Course";
import Block from "../components/layout/Block";
import ModuleCompleteModal from '../components/course/ModuleCompleteModal';
import RichText from '../components/Activities/RichText';
import {useCourseData} from "features/course/courseHooks";

export default function CoursePage() {
  const {id} = useParams();
  const data = useCourseData(id);

  const [showModal, setShowModal] = useState(true);
  const [searchParams] = useSearchParams();

  const completed = searchParams.get('completed');

  const modules = data.content || [];
  const completedModuleIndex = completed ? modules.findIndex(module => module.id == completed) : -1;
  const completedModule = (completedModuleIndex > -1) ? modules[completedModuleIndex] : null;
  // @todo Check if next module and segment are not locked?
  const nextModule = (completedModule && modules[completedModuleIndex + 1])
    ? modules[completedModuleIndex + 1]
    : null;
  const nextSegment = (nextModule && nextModule.children?.[0])
    ? nextModule.children[0]
    : null;

  const navigate = useNavigate();

  return (
    <Layout>
      <Block>
        <Course data={data} />
      </Block>

      {completedModule && completedModule.content?.complete_message?.length &&
        <ModuleCompleteModal isOpen={showModal}
                             title={completedModule.content?.title}
                             onSubmit={nextModule ? () => {navigate('/course/content/' + (nextSegment ? nextSegment.id : nextModule.id))} : null}
                             onClose={() => {setShowModal(false)}}
                             submitLabel={`Continue to ${nextModule?.content?.title}`}
                             closeLabel='Return to Course'
        >
          <RichText text={completedModule.content.complete_message} color='primary.navy' />
        </ModuleCompleteModal>
      }
    </Layout>
  )
}
