import wipeService from "../media/wipeService";
import {pageReloaded} from "../../app/helpers";

export default async function loginLoader({ params }) {
  try {
    if (!pageReloaded) {
      const wipe = await wipeService.getWipeByName('login');
      if (wipe) {
        await wipeService.showWipe(wipe);
      }
    }
  }
  catch (e) {
    console.error(e);
  }

  return null;
};
