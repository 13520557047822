import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './routes/App';
import {Provider} from 'react-redux';
import {store} from './app/store';
import {ChakraProvider} from '@chakra-ui/react'
import {theme} from './styles/theme'
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/custom.css'
import './styles/global.scss';
import {VoiceoverContextProvider} from "./components/media/VoiceoverContext";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <VoiceoverContextProvider>
        <App/>
      </VoiceoverContextProvider>
    </ChakraProvider>
    <ToastContainer/>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
const handleUpdate = (registration) => {
  toast.warning('New application version is available. Close this message to update.', {
    autoClose: false,
    onClose: () => {
      if (registration.waiting) {
        // let waiting Service Worker know it should became active
        registration.waiting.postMessage({type: 'SKIP_WAITING'});
      }
    }
  });
}
serviceWorkerRegistration.register({
  onRegister: (registration) => {
    if (registration.waiting) {
      handleUpdate(registration);
    }
  },
  onUpdate: (registration) => {
    handleUpdate(registration);
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
