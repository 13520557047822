import {useDispatch} from "react-redux";
import {addLike, pinLike, removeLike} from "features/like/likesSlice";
import {IconButton} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {cn} from "app/helpers";
import {useLikeData} from "features/like/likesHooks";
import PinIcon from "icons/PinIcon";

export default function Pin({entityType, entityId}) {
  const classes = cn('pin');
  const dispatch = useDispatch();
  const [data, isLoading] = useLikeData(entityType, entityId);
  const [processing, setProcessing] = useState(false);
  const [pinned, setPinned] = useState(data?.sticky);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    setPinned(data?.sticky);
  }, [data]);

  const handleComplete = (result) => {
    setProcessing(false);
    if (result?.meta?.requestStatus === 'rejected') {
      setPinned(pinned);
    }
  }

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!processing) {
      setPinned(!pinned);
      setClicked(true);
      setProcessing(true);

      if (!data && !pinned) {
        dispatch(addLike({
          'entity_type': entityType,
          'entity_id': entityId,
          sticky: true,
        })).then(handleComplete);
      }
      else {
        dispatch(pinLike({
          ...data,
          sticky: !pinned,
        })).then(handleComplete);
      }
    }
  };

  return <div className={classes({
    pinned,
    clicked,
    processing,
  })}>
    <IconButton onAnimationEnd={() => setClicked(false)}
                className={classes('button')} aria-label={'Save'}
                icon={<PinIcon color={'navy'} variant={pinned ? 'solid' : 'outline'} />}
                variant={'transparent'} size={'24px'} onClick={handleClick}
                isDisabled={isLoading}/>
  </div>
}
