import {store} from "../../app/store";
import {fetchBlocks} from "../block/blockSlice";

export default async function mainLoader({params, request}) {
  // @todo Should it really be a part of the loader? Do we just avoid layout shift?
  // @todo Make it possible to force load via useRegionData() hook instead?
  // @todo Make it possible to gather several regions from the backend at once?
  await Promise.all([
    store.dispatch(fetchBlocks({
      region: 'cta',
      uri: '/',
    })),
    store.dispatch(fetchBlocks({
      region: 'content_after',
      uri: '/',
    })),
  ]);

  return null;
}
