import {cn} from "../../../app/helpers";
import CaptionsIcon from "../../../icons/CaptionsIcon";
import mediaService from "../../../features/media/mediaService";

export default function CaptionsButton({enabled, setCaptions}) {
  const classes = cn('player-controls');
  const handleClick = (e) => {
    e.preventDefault();
    setCaptions(!enabled);
  }

  return <button className={classes('button', {'captions': true})} onClick={handleClick}><CaptionsIcon enabled={enabled}/></button>
}
