import Layout from "../components/layout/Layout";
import {  Heading, Flex, Hide, Accordion, Spinner, Center } from '@chakra-ui/react'
import Faq from "../components/faqs/Faq";
import { useEffect } from "react";
import { getFaqs } from "../features/general/generalSlice";
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import Block from "../components/layout/Block";

export default function Faqs() {


  const dispatch = useDispatch()
  
  const { faqs, isLoading, isError, isSuccess, message } = useSelector(
      (state) => state.general
  )

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
  },[isError, message])

  useEffect(() => {
    if (Object.keys(faqs).length === 0) {
      dispatch(getFaqs())
    }
  }, [])

  const printFaqs = () => {
     return faqs?.items?.map(faq => <Faq data={faq} key={faq.nid}/>)
  }

  return (
    <Layout headerInfo={{title: 'FAQs'}}>
      <Block>
        <Flex mb={'40px'} justifyContent={'space-between'} alignItems={'center'}>
          <Hide below={'lg'}>
            <Heading  color={'primary.navy'}>FAQs</Heading>
          </Hide>
        </Flex>

        {isLoading &&
        <Center>
          <Spinner/>
        </Center>
        }
        { isSuccess &&
        <Accordion pb={'100px'} allowToggle>
          {printFaqs()}
        </Accordion>
        }
      </Block>
    </Layout>
  );
}