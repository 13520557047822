import {
  Box,
  Link,
  Portal,
  Popover, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader,
  PopoverTrigger, useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react';
import parse, {domToReact} from 'html-react-parser';
import generalService from "features/general/generalService";
import {useState} from "react";
import {cn} from "app/helpers";
import ModalDialog from "components/modals/ModalDialog";

export function ParsedLink({children, href = ''}) {
  const isModal = true;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState({});

  const handleClick = () => {
    const absolute = new RegExp('^(?:[a-z+]+:)?//', 'i');
    if (!absolute.test(href)) {
      generalService.getStatements(href).then(response => {
        setData(response);
        onOpen();
      });
    }
    else {
      window.open(href);
    }
  };

  return <>
    <Link onClick={handleClick}>{children}</Link>
    {isModal && <ModalDialog title={data?.title} isOpen={isOpen} onClose={onClose} size={'lg'}>
      <RichText text={data?.body?.value}/>
    </ModalDialog>}
  </>
}

export function Tooltip({children, data}) {
  const classes = cn('tooltip');
  const placement = useBreakpointValue({
    'base': 'bottom-end',
    'md': 'right-start',
  });

  return <Popover placement={placement}>
    <PopoverTrigger>
      <a className={classes()}>{children}</a>
    </PopoverTrigger>
    <Portal>
      <PopoverContent>
        <PopoverCloseButton />
        <div className={classes('inner')}>
          {data.header && <PopoverHeader>{data.header}</PopoverHeader>}
          <PopoverBody><RichText text={data.content}/></PopoverBody>
        </div>
      </PopoverContent>
    </Portal>
  </Popover>
}

export default function RichText({text, className = '', ...rest}) {
  const options = {
    replace: (domNode) => {
      if (domNode.tagName === 'a') {
        return <ParsedLink href={domNode.attribs.href}>{domToReact(domNode.children, options)}</ParsedLink>;
      }

      if (domNode.tagName === 'span') {
        if (domNode.attribs['data-tooltip']) {
          return <Tooltip data={JSON.parse(domNode.attribs['data-tooltip'])}>{domToReact(domNode.children, options)}</Tooltip>
        }
      }
    },
  };
  const children = typeof text === 'string' && text.length > 0 && parse(text, options);

  return children && <Box className={`parse-html ${className}`} {...rest}>
    {children}
  </Box>;
};
