import {cn} from "app/helpers";

export default function EmptyList({icon, title, children}) {
  const classes = cn('empty-list');

  return <div className={classes()}>
    {icon && <div className={classes('icon')}>{icon}</div>}
    <div className={classes('content')}>
      {title && <div className={classes('title')}>{title}</div>}
      {children && <div className={classes('description')}>{children}</div>}
    </div>
  </div>
}
