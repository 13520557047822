import {useFormik} from "formik"
import FormField from "../Input"
import {profileSchema} from "schemas"
import OutlineButton from "../../buttons/OutlineButton"
import {useDispatch} from 'react-redux'
import {updateProfile} from "features/profile/profileSlice"
import {toast} from 'react-toastify'

const EditProfileForm = ({data, isLoading}) => {
  const {first_name, last_name, mail, preferred_langcode, timezone, phone_number} = data
  const dispatch = useDispatch()

  const onSubmit = async (values) => {
    dispatch(updateProfile(values));
  }

  const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
    initialValues: {
      first_name: first_name,
      last_name: last_name,
      mail: mail,
      phone_number: phone_number || '',
      timezone: timezone,
      preferred_langcode: preferred_langcode
    },
    validationSchema: profileSchema,
    onSubmit,
  })


  return (
    <form onSubmit={handleSubmit}>
      <FormField touched={touched} errors={errors} id={"first_name"} name={"first_name"} type={"text"}
                 value={values.first_name} placeholder={""} onBlur={handleBlur} onChange={handleChange}
                 label={"First name"}/>
      <FormField touched={touched} errors={errors} id={"last_name"} name={"last_name"} type={"text"}
                 value={values.last_name} placeholder={""} onBlur={handleBlur} onChange={handleChange}
                 label={"Surname"}/>
      <FormField touched={touched} errors={errors} id={"mail"} name={"mail"} type={"email"} value={values.mail}
                 placeholder={""} onBlur={handleBlur} onChange={handleChange} label={"Email"}/>
      <FormField touched={touched} errors={errors} id={"phone_number"} name={"phone_number"} type={"text"}
                 value={values.phone_number} placeholder={""} onBlur={handleBlur} onChange={handleChange}
                 label={"Phone number"}/>
      <OutlineButton _hover={{color: 'white', bg: 'primary.navy'}} width={'80px'} isLoading={isLoading} type={"submit"}
                     name={"submit"} copy={"Save"} txColor={'white'} bgColor={'primary.navy'}/>
    </form>
  )
}

export default EditProfileForm

