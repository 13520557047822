export default function TimeDuration({currentTime, duration}) {
  const formatTime = (seconds) => {
    const formatted = [];
    seconds = seconds < 0 ? 0 : seconds;
    if (seconds >= 3600) {
      const hours = Math.floor(seconds / 3600);
      seconds -= (hours * 3600);
      formatted.push((hours < 10 ? '0' : '') + hours);
    }

    const minutes = Math.floor(seconds / 60);
    seconds = Math.floor(seconds % 60);
    formatted.push((minutes < 10 ? '0' : '') + minutes);
    formatted.push((seconds < 10 ? '0' : '') + seconds);

    return formatted.join(':');
  };

  return <div className="player-controls__time-duration">
    <span className="time time--current">{formatTime(currentTime)}</span>
    {duration > 0 && <>
      /
      <span className="time time--duration">{formatTime(duration)}</span>
    </>}
  </div>
}
