function ChevronDown() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="4"
        fill="none"
        viewBox="0 0 8 4"
      >
        <path fill="#0C1851" d="M4 4L0 0h8L4 4z"></path>
      </svg>
    );
  }
  
  export default ChevronDown;