import {colorToHex} from "app/helpers";

function ResourcesIcon({color = 'navy', variant = 'solid', size = 24}) {
  if (variant === 'outline') {
    return (
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.425 20.2001L3.575 9.20005H20.425L18.575 20.2001H5.425ZM6.675 18.7H17.325L18.6 10.7H5.4L6.675 18.7ZM10 14.45H14C14.2167 14.45 14.396 14.375 14.538 14.225C14.6793 14.075 14.75 13.9 14.75 13.7C14.75 13.4834 14.6793 13.304 14.538 13.162C14.396 13.0207 14.2167 12.95 14 12.95H10C9.78333 12.95 9.60433 13.0207 9.463 13.162C9.321 13.304 9.25 13.4834 9.25 13.7C9.25 13.9 9.321 14.075 9.463 14.225C9.60433 14.375 9.78333 14.45 10 14.45ZM6 8.00005C5.78333 8.00005 5.60433 7.92905 5.463 7.78705C5.321 7.64572 5.25 7.46672 5.25 7.25005C5.25 7.03338 5.321 6.85438 5.463 6.71305C5.60433 6.57105 5.78333 6.50005 6 6.50005H18C18.2167 6.50005 18.396 6.57105 18.538 6.71305C18.6793 6.85438 18.75 7.03338 18.75 7.25005C18.75 7.46672 18.6793 7.64572 18.538 7.78705C18.396 7.92905 18.2167 8.00005 18 8.00005H6ZM8 5.30005C7.78333 5.30005 7.60433 5.22938 7.463 5.08805C7.321 4.94605 7.25 4.76672 7.25 4.55005C7.25 4.35005 7.321 4.17505 7.463 4.02505C7.60433 3.87505 7.78333 3.80005 8 3.80005H16C16.2167 3.80005 16.396 3.87505 16.538 4.02505C16.6793 4.17505 16.75 4.35005 16.75 4.55005C16.75 4.76672 16.6793 4.94605 16.538 5.08805C16.396 5.22938 16.2167 5.30005 16 5.30005H8Z"
          fill={colorToHex(color)}/>
      </svg>
    );
  }

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.425 20.2001L3.575 9.20005H20.425L18.575 20.2001H5.425ZM10 14.45H14C14.2167 14.45 14.396 14.375 14.538 14.225C14.6793 14.075 14.75 13.9 14.75 13.7C14.75 13.4834 14.6793 13.304 14.538 13.162C14.396 13.0207 14.2167 12.95 14 12.95H10C9.78333 12.95 9.60433 13.0207 9.463 13.162C9.321 13.304 9.25 13.4834 9.25 13.7C9.25 13.9 9.321 14.075 9.463 14.225C9.60433 14.375 9.78333 14.45 10 14.45ZM6 8.00005C5.78333 8.00005 5.60433 7.92905 5.463 7.78705C5.321 7.64572 5.25 7.46672 5.25 7.25005C5.25 7.03338 5.321 6.85438 5.463 6.71305C5.60433 6.57105 5.78333 6.50005 6 6.50005H18C18.2167 6.50005 18.396 6.57105 18.538 6.71305C18.6793 6.85438 18.75 7.03338 18.75 7.25005C18.75 7.46672 18.6793 7.64572 18.538 7.78705C18.396 7.92905 18.2167 8.00005 18 8.00005H6ZM8 5.30005C7.78333 5.30005 7.60433 5.22938 7.463 5.08805C7.321 4.94605 7.25 4.76672 7.25 4.55005C7.25 4.35005 7.321 4.17505 7.463 4.02505C7.60433 3.87505 7.78333 3.80005 8 3.80005H16C16.2167 3.80005 16.396 3.87505 16.538 4.02505C16.6793 4.17505 16.75 4.35005 16.75 4.55005C16.75 4.76672 16.6793 4.94605 16.538 5.08805C16.396 5.22938 16.2167 5.30005 16 5.30005H8Z"
        fill={colorToHex(color)}/>
    </svg>
  );
}

export default ResourcesIcon;
