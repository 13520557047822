import {IconButton} from "@chakra-ui/react";

export default function NextButton({ disabled, isLoading, colorScheme, variant, onClick, txColor, bgColor, name, type, style }) {
  return (
    <IconButton aria-label={'Next'} isDisabled={disabled} width={6} isLoading={isLoading} onClick={onClick} borderColor={txColor} variant={variant} type={type} colorScheme={colorScheme} borderRadius={'3xl'} name={name} color={txColor} bg={bgColor} style={style}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0004 6.35003L15.6504 12L10.0004 17.65L8.95039 16.6L13.5504 12L8.95039 7.40002L10.0004 6.35003Z" fill="#0C1851"/>
      </svg>
    </IconButton>
  )
}
