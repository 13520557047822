import {Progress} from "@chakra-ui/react";
import ModuleCard from "./ModuleCard";
import RichText from "../Activities/RichText";
import {cn, formatPercentage} from "app/helpers";
import LockedIcon from "icons/LockedIcon";

export default function Course({data}) {
  const classes = cn('course');
  const {title, description, locked = true, content, progress} = data;

  return (
    <div className={classes()}>
      <div className={classes('title')}>
        {locked && <LockedIcon size={24}/>}
        {title}
      </div>
      {description && <div className={classes('description')}>
        <RichText text={data.description.value || data.description}/>
      </div>}

      {!locked && <div className={classes('progress')}>
        <Progress boxShadow='inner' value={data?.progress?.complete && formatPercentage(progress?.complete)} colorScheme={'orange'} borderRadius={'24px'} />
        <div>{formatPercentage(progress?.complete)}% Complete</div>
      </div> }

      {!locked && content && <div className={classes('content')}>
        {content.map(moduleData => <ModuleCard key={moduleData.id} id={moduleData.id} />)}
      </div>}
    </div>
  )
}
