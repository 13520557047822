import {cn} from "app/helpers";
import {Button as ChakraButton} from '@chakra-ui/react';
import {useNavigation} from "react-router-dom";

export default function Button(props) {
  const navigation = useNavigation();
  const classes = cn('button');
  const {variant = 'solid', color = 'navy', isLoading, disabled, ...chakraProps} = props;
  const loading = isLoading || navigation.state === 'loading';

  return <ChakraButton className={classes({
    [variant]: true,
    [color]: true,
  })} isLoading={loading} isDisabled={disabled} {...chakraProps}>{chakraProps.children}</ChakraButton>
}
