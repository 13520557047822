import Pager from "components/layout/Pager";
import {useContext} from "react";
import {ActivityContext} from "components/course/Activity/ActivityContext";

export default function ActivityPager({}) {
  const {pagerType, totalPages, currentPage, setCurrentPage, canNavigate} = useContext(ActivityContext);

  return pagerType === 'full' && <Pager type={pagerType} totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage}
         canNavigate={canNavigate}/>
}
