import React, {useContext, useState} from "react";
import {
  Text,
  Grid,
  GridItem,
  useCheckbox,
  useCheckboxGroup,
  Box,
} from '@chakra-ui/react';
import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import CompleteIcon from 'icons/CompleteIcon';
import CloseIcon from 'icons/CloseIcon';
import {playSound} from "app/sounds";
import {ActivityContext} from "components/course/Activity/ActivityContext";

function Word(props) {
  const { getInputProps, getCheckboxProps } = useCheckbox(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as='label'>
      <input {...input} />
      <Box
        {...checkbox}
        cursor='pointer'
        borderWidth='1px'
        borderColor='white'
        borderRadius='full'
        position='relative'
        px={8}
        py={2}
        _checked={{
          bg: props.isCorrect ? 'secondary.tangerine' : 'washedOut.orange',
          borderColor: 'transparent',
          color: 'primary.navy',
        }}
        _disabled={{
          cursor: 'auto',
        }}
      >
        {props.isChecked && (
          <Box position='absolute' left='6px' top='50%' transform='translateY(-50%)'>
            {props.isCorrect ? <CompleteIcon color={'navy'} /> : <CloseIcon />}
          </Box>
        )}
        <Text align='center' fontSize='sm' fontWeight='semibold'>
          {props.children}
        </Text>
      </Box>
    </Box>
  );
}

export default function SelectWord({data}) {
  const {onNext} = useContext(ActivityContext);
  const { content } = data;
  const [selected, setSelected] = useState([]);

  const options = content?.content?.options || [];
  const correctOptions = options.filter(option => option.correct)
    .map(option => option.value);
  const isValid = correctOptions.every(option => selected.includes(option));

  const handleChange = (newValues) => {
    setSelected(newValues);
    const diff = newValues.filter(el => !selected.includes(el));
    // We need to know which exact new item was clicked. Diff should be just one
    // element in this case.
    const newValue = diff[0];
    if (newValue !== undefined) {
      correctOptions.includes(newValue) ? playSound('complete') : playSound('incorrect');
    }
  };

  const { getCheckboxProps } = useCheckboxGroup({
    onChange: handleChange,
  });

  return (
    <ActivityContext.Provider value={{
      ...useContext(ActivityContext),
      canSubmit: isValid,
      onNext: () => {
        onNext(selected.filter(value => correctOptions.includes(value)));
      },
    }}>
      <ActivityWrapper title={data?.content?.title} description={data?.content?.description}>
        <Grid templateColumns={{lg: '1fr 1fr'}} gap={4} mt={4}>
          {options.map(({value, correct}) => {
            const checkbox = getCheckboxProps({ value });
            return (
              <GridItem key={value}>
                <Word isCorrect={correct} isDisabled={checkbox.isChecked || isValid} {...checkbox}>
                  {value}
                </Word>
              </GridItem>
            );
          })}
        </Grid>
      </ActivityWrapper>
    </ActivityContext.Provider>
  )
}
