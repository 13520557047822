import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import encouragementsService from "../encouragements/encouragementsService";
import {handleApiError} from "../auth/authSlice";

const encouragementsAdapter = createEntityAdapter({
  sortComparer: (a, b) => {
    const dateA = new Date(a.created?.value);
    const dateB = new Date(b.created?.value);
    return dateA === dateB ? 0 : (dateA > dateB ? -1 : 1);
  },
});
export const encouragementsSelector = encouragementsAdapter.getSelectors(state => state.encouragements);

export const fetchEncouragements = createAsyncThunk('encouragements/fetchEncouragements', async (params, thunkAPI) => {
  try {
    return await encouragementsService.getEncouragements(params);
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
});

const encouragementsSlice = createSlice({
  name: 'encouragements',
  initialState: encouragementsAdapter.getInitialState({
    isLoading: false,
    isSuccess: false,
    isError: false,
  }),
  extraReducers: (builder) => {
    builder
      .addCase(fetchEncouragements.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchEncouragements.fulfilled, (state, action) => {
        encouragementsAdapter.setMany(state, action.payload.items);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(fetchEncouragements.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
  },
});

export default encouragementsSlice.reducer;
