import * as yup from 'yup' 

export const loginSchema = yup.object().shape({
    email: yup.string().email("Must be a valid email").required("Required"),
    password: yup.string().required("Required")
})

export const newPasswordRequestSchema = yup.object().shape({
    email: yup.string().email("Must be a valid email").required("Required"),
})

// @todo Use https://www.npmjs.com/package/yup-password instead of regex for password validation?
export const passwordSchema = yup.object().shape({
    password: yup.string().required("Required").matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPassword: yup.string().required("Required").oneOf([yup.ref("password")], "Passwords must match")
})

export const profileSchema = yup.object().shape({
    mail: yup.string().email("Must be a valid email"),
    first_name: yup.string().required("Required field"),
    last_name: yup.string().required("Required field"),
    phone_number:  yup.string(),
})

export const contactSchema = yup.object().shape({
    from: yup.string().email("Must be a valid email").required("Required"),
    subject: yup.string(),
    message: yup.string().required("Required"),
});

export const getInTouchSchema = yup.object().shape({
    from: yup.string().email("Must be a valid email").required("Required"),
    message: yup.string().required("Required"),
});
