import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import consultantsService from "./consultantsService"
import {handleApiError} from "../auth/authSlice";

const initialState = {
  data: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

export const fetchConsultants = createAsyncThunk('consultants/getConsultants', async (params, thunkAPI) => {
  try {
    return await consultantsService.getConsultants(params);
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
});

export const consultantsSlice = createSlice ({
  name: 'consultants',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchConsultants.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchConsultants.fulfilled, (state, action) => {
        return {
          ...initialState,
          data: action.payload.items,
          isSuccess: true,
        };
      })
      .addCase(fetchConsultants.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      });
  }
});

export default consultantsSlice.reducer;
