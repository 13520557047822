import Filter from "./Filter";
import {useCoursesData} from "features/course/courseHooks";

export default function ModulesFilter({include = [], onChange}) {
  const [courses] = useCoursesData();
  const options = [];

  courses.forEach(course => {
    if (course?.content?.length > 0 && options.length > 0) {
      options.push({
        id: course.id,
        divider: true,
      });
    }

    course?.content?.forEach(child => {
      if (include.length === 0 || include.includes(child.id)) {
        options.push({
          id: child.id,
          title: child.content.title,
        });
      }
    });
  });

  return options.length > 0 && <Filter options={options} onChange={onChange}/>
}