import {colorToHex} from "app/helpers";

function PinIcon({color = 'navy', variant = 'solid', size = 24}) {
  if (variant === 'outline') {
    return (
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.5 12.2L17.3 14V15.5H12.75V21L12 21.75L11.25 21V15.5H6.69995V14L8.49995 12.2V5H7.49995V3.5H16.5V5H15.5V12.2ZM8.84995 14H15.15L14 12.85V5H9.99995V12.85L8.84995 14Z"
          fill={colorToHex(color)}/>
      </svg>
    );
  }

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5 12.2L17.3 14V15.5H12.75V21L12 21.75L11.25 21V15.5H6.69995V14L8.49995 12.2V5H7.49995V3.5H16.5V5H15.5V12.2Z"
        fill={colorToHex(color)}/>
    </svg>
  )
}

export default PinIcon;
