import {getLikesByType} from "features/like/likesSlice";
import {useSelector} from "react-redux";
import Layout from "../../components/layout/Layout";
import {Grid, Text} from "@chakra-ui/react";
import Block from "../../components/layout/Block";
import {getCourseContent} from "features/course/courseContentSlice";
import {useState} from "react";
import ModulesFilter from "../../components/listing/ModulesFilter";
import Sort from "../../components/listing/Sort";
import PageHeading from "../../components/layout/PageHeading";
import FilterWrapper from "../../components/listing/FilterWrapper";
import BulbIcon from "icons/BulbIcon";
import EncouragementCard from "components/course/EncouragementCard";
import {encouragementsSelector} from "features/encouragements/encouragementsSlice";
import EmptyList from "components/listing/EmptyList";
import HeartIcon from "icons/HeartIcon";

export default function EncouragementsPage() {
  const defaultSort = 'timestamp_desc';
  const includeModules = [];
  const [selectedModules, setSelectedModules] = useState([]);
  const [sort, setSort] = useState(defaultSort);
  const likes = useSelector(state => {
    const likes = getLikesByType(state, 'encouragement');
    const [sortBy, sortDir] = sort.split('_');

    return likes.map(like => {
      const content = encouragementsSelector.selectById(state, like.entity_id);
      const courseContent = getCourseContent(state, content?.course_content[0]?.id);

      const module = courseContent?.module;
      if (module?.id && !includeModules.includes(module.id)) {
        includeModules.push(module.id);
      }
      return {...like, content, module};
    })
      .filter(like => like && (selectedModules.length === 0 || selectedModules.includes(like?.module?.id)))
      .sort((a, b) => {
        let propA;
        let propB;
        switch (sortBy) {
          case 'timestamp':
            propA = new Date(a.timestamp?.value);
            propB = new Date(b.timestamp?.value);
            break;
          case 'weight':
            propA = parseInt(a.module?.weight) || 0;
            propB = parseInt(b.module?.weight) || 0;
            break;
          default:
            return 0;
        }

        const aSticky = !!a.sticky;
        const bSticky = !!b.sticky;
        if (aSticky !== bSticky) {
          return aSticky > bSticky ? -1 : 1;
        }

        if (propA < propB) {
          return sortDir === 'asc' ? -1 : 1;
        }
        if (propA > propB) {
          return sortDir === 'asc' ? 1 : -1;
        }

        return 0;
      });
  });

  const header = {
    title: 'Encouragements',
  };

  const sortOptions = [
    {
      id: 'timestamp_desc',
      title: 'Newest',
    },
    {
      id: 'timestamp_asc',
      title: 'Oldest',
    },
    {
      id: 'weight_asc',
      title: 'Module (↑)',
    },
    {
      id: 'weight_desc',
      title: 'Module (↓)',
    }
  ];

  const handleFilter = (selected) => {
    setSelectedModules(selected);
  };

  const handleSort = (selected) => {
    setSort(selected);
  };

  return <Layout headerInfo={header}>
    <Block>
      <PageHeading title={header.title} icon={<BulbIcon size={24} color={'navy'} variant={'outline'}/>} isMain={true} size={'md'}>
        {<FilterWrapper>
          <ModulesFilter onChange={handleFilter} include={includeModules}/>
          <Sort options={sortOptions} defaultValue={defaultSort} onChange={handleSort} />
        </FilterWrapper>}
      </PageHeading>
      <Grid gap={4}>
        {likes.length > 0 ?
          <div className={'encouragement-list'}>
            {likes.map(like => <EncouragementCard key={like.entity_id} id={like.entity_id} variant={'full'} />)}
          </div> : <EmptyList title={'It looks like you haven’t saved any Encouragements yet.'} icon={<HeartIcon size={24} color={'flame'}/>}>
            <Text>Use the heart button on encouragements that resonate with you to revisit them at a during the course and inspire yourself to change.</Text>
          </EmptyList>}
      </Grid>
    </Block>
  </Layout>;
}
