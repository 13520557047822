import {Button, Flex, Menu, MenuButton, MenuDivider, MenuItemOption, MenuList, MenuOptionGroup} from "@chakra-ui/react";
import ChevronDown from "icons/ChevronDown";
import {useState} from "react";
import {cn} from "app/helpers";

export default function OptionsDropdown({title, icon, variant = 'outlineNavy', options = [], defaultValue, onChange, isMultiple = false}) {
  const classes = cn('dropdown-options');
  const [selected, setSelected] = useState(defaultValue || (isMultiple ? [] : ''));

  const handleChange = (selected) => {
    setSelected(selected);
    onChange && onChange(selected);
  };

  return <Menu closeOnSelect={false}>
    <MenuButton as={Button}
                aria-label='Filters'
                leftIcon={icon}
                rightIcon={<ChevronDown />}
                variant={variant}
    >
      {title}
    </MenuButton>
    {options.length > 0 && <MenuList className={classes({
      multiple: isMultiple,
    })} zIndex={"docked"}>
      <MenuOptionGroup className={classes('group')} value={selected} defaultValue={defaultValue} onChange={handleChange} type={isMultiple ? 'checkbox' : 'radio'}>
        {options.map(option => option.divider ?
          <MenuDivider key={'divider-' + option.id} /> :
          <MenuItemOption value={option.id} key={option.id} className={classes('item')}>{option.title}</MenuItemOption>
        )}
      </MenuOptionGroup>
    </MenuList>}
  </Menu>
}
