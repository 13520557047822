import AnimationPlayer from "components/media/AnimationPlayer";
import {cn} from "app/helpers";
import {useCallback, useContext, useState} from "react";
import ActivityWrapper from "components/course/Activity/ActivityWrapper";
import {ActivityContext} from "components/course/Activity/ActivityContext";

export default function Animation({data}) {
  const classes = cn('animation-activity');
  const {progress} = data;
  const {animation, muted} = data?.content?.content;
  const {setFocused} = useContext(ActivityContext);
  const [canSubmit, setCanSubmit] = useState(progress?.complete === 1);

  const handleComplete = useCallback(() => {
    setFocused(true);
    setCanSubmit(true);
  }, [setFocused]);

  return <div className={classes()}>
    <ActivityContext.Provider value={{
      ...useContext(ActivityContext),
      canSubmit,
    }}>
      <ActivityWrapper>
        <AnimationPlayer files={animation} controls={true} loop={false} muted={muted || false} onComplete={handleComplete} isMain={true} />
      </ActivityWrapper>
    </ActivityContext.Provider>
  </div>
}
