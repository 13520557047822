import {Flex, Grid, Image} from '@chakra-ui/react';
import ActivityWrapper from "components/course/Activity/ActivityWrapper";
import RichText from "./RichText";

function FlowItem({image, description}) {
  return (
    <Grid autoColumns='1fr 2fr' autoFlow='column' gap={4} width='100%' alignItems='start' color={'primary.navy'}>
      {image && <Image display={{lg: 'none'}} src={image.url} alt={image.alt} htmlWidth={image.width} htmlHeight={image.height} width='100%' loading='lazy' />}

      <RichText text={description} borderRadius='2xl' p={4} bg='white' color='gray.charcoal' />
    </Grid>
  );
}

export default function Flow({data}) {
  const { content } = data
  const image = content?.content?.image?.file;
  const items = content?.content?.items || [];

  return(
    <ActivityWrapper title={content?.title} description={content?.description}>
      {image &&
      <Flex display={{base: 'none', lg: 'flex'}} justify='center' width='100%'>
        <Image src={image.url} alt={image.alt} htmlWidth={image.width} htmlHeight={image.height} loading='lazy' />
      </Flex>
      }
      {items.length &&
      <Grid autoColumns='minmax(0, 1fr)' autoFlow={{base: 'row', lg: 'column'}} gap={4} width='100%' mt={4}>
        {items.map(item => <FlowItem image={item.image?.file} description={item.description} />)}
      </Grid>
      }

    </ActivityWrapper>
  )
}
