import {colorToHex} from "app/helpers";

function ProgressIcon({color = 'navy', variant = 'solid', size = 24}) {
  if (variant === 'outline') {
    return (
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7 13.25C7.35 13.25 7.646 13.129 7.888 12.887C8.12933 12.6457 8.25 12.35 8.25 12C8.25 11.65 8.12933 11.3543 7.888 11.113C7.646 10.871 7.35 10.75 7 10.75C6.65 10.75 6.35433 10.871 6.113 11.113C5.871 11.3543 5.75 11.65 5.75 12C5.75 12.35 5.871 12.6457 6.113 12.887C6.35433 13.129 6.65 13.25 7 13.25ZM12 13.25C12.35 13.25 12.6457 13.129 12.887 12.887C13.129 12.6457 13.25 12.35 13.25 12C13.25 11.65 13.129 11.3543 12.887 11.113C12.6457 10.871 12.35 10.75 12 10.75C11.65 10.75 11.3543 10.871 11.113 11.113C10.871 11.3543 10.75 11.65 10.75 12C10.75 12.35 10.871 12.6457 11.113 12.887C11.3543 13.129 11.65 13.25 12 13.25ZM17 13.25C17.35 13.25 17.646 13.129 17.888 12.887C18.1293 12.6457 18.25 12.35 18.25 12C18.25 11.65 18.1293 11.3543 17.888 11.113C17.646 10.871 17.35 10.75 17 10.75C16.65 10.75 16.3543 10.871 16.113 11.113C15.871 11.3543 15.75 11.65 15.75 12C15.75 12.35 15.871 12.6457 16.113 12.887C16.3543 13.129 16.65 13.25 17 13.25ZM12 21.5C10.6833 21.5 9.446 21.25 8.288 20.75C7.12933 20.25 6.125 19.575 5.275 18.725C4.425 17.875 3.75 16.8707 3.25 15.712C2.75 14.554 2.5 13.3167 2.5 12C2.5 10.6833 2.75 9.44567 3.25 8.287C3.75 7.129 4.425 6.125 5.275 5.275C6.125 4.425 7.12933 3.75 8.288 3.25C9.446 2.75 10.6833 2.5 12 2.5C13.3167 2.5 14.5543 2.75 15.713 3.25C16.871 3.75 17.875 4.425 18.725 5.275C19.575 6.125 20.25 7.129 20.75 8.287C21.25 9.44567 21.5 10.6833 21.5 12C21.5 13.3167 21.25 14.554 20.75 15.712C20.25 16.8707 19.575 17.875 18.725 18.725C17.875 19.575 16.871 20.25 15.713 20.75C14.5543 21.25 13.3167 21.5 12 21.5ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
          fill={colorToHex(color)}/>
      </svg>
    );
  }

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 13.25C7.35 13.25 7.646 13.129 7.888 12.887C8.12933 12.6457 8.25 12.35 8.25 12C8.25 11.65 8.12933 11.3543 7.888 11.113C7.646 10.871 7.35 10.75 7 10.75C6.65 10.75 6.35433 10.871 6.113 11.113C5.871 11.3543 5.75 11.65 5.75 12C5.75 12.35 5.871 12.6457 6.113 12.887C6.35433 13.129 6.65 13.25 7 13.25ZM12 13.25C12.35 13.25 12.6457 13.129 12.887 12.887C13.129 12.6457 13.25 12.35 13.25 12C13.25 11.65 13.129 11.3543 12.887 11.113C12.6457 10.871 12.35 10.75 12 10.75C11.65 10.75 11.3543 10.871 11.113 11.113C10.871 11.3543 10.75 11.65 10.75 12C10.75 12.35 10.871 12.6457 11.113 12.887C11.3543 13.129 11.65 13.25 12 13.25ZM17 13.25C17.35 13.25 17.646 13.129 17.888 12.887C18.1293 12.6457 18.25 12.35 18.25 12C18.25 11.65 18.1293 11.3543 17.888 11.113C17.646 10.871 17.35 10.75 17 10.75C16.65 10.75 16.3543 10.871 16.113 11.113C15.871 11.3543 15.75 11.65 15.75 12C15.75 12.35 15.871 12.6457 16.113 12.887C16.3543 13.129 16.65 13.25 17 13.25ZM12 21.5C10.6833 21.5 9.446 21.25 8.288 20.75C7.12933 20.25 6.125 19.575 5.275 18.725C4.425 17.875 3.75 16.8707 3.25 15.712C2.75 14.554 2.5 13.3167 2.5 12C2.5 10.6833 2.75 9.44567 3.25 8.287C3.75 7.129 4.425 6.125 5.275 5.275C6.125 4.425 7.12933 3.75 8.288 3.25C9.446 2.75 10.6833 2.5 12 2.5C13.3167 2.5 14.5543 2.75 15.713 3.25C16.871 3.75 17.875 4.425 18.725 5.275C19.575 6.125 20.25 7.129 20.75 8.287C21.25 9.44567 21.5 10.6833 21.5 12C21.5 13.3167 21.25 14.554 20.75 15.712C20.25 16.8707 19.575 17.875 18.725 18.725C17.875 19.575 16.871 20.25 15.713 20.75C14.5543 21.25 13.3167 21.5 12 21.5Z"
        fill={colorToHex(color)}/>
    </svg>
  );
}

export default ProgressIcon