import { Container, Flex, Image, Center } from '@chakra-ui/react'
import neuroChangeSolutions from "../../img/neuroChangeSolutions.svg"

export default function SignInFormPanel({children}) {

    return (

    <Container maxW={'508px'} bg={{ lg: 'white' }} boxShadow={{lg: 'base'}} py={{base: '88px', lg: '48px'}} px={{ lg: '48px'}}  borderRadius={{lg: '24px'}}>
        <Flex direction={'column'}>
            <Center>
                <Image maxW={'272px'} pb={'35px'} src={neuroChangeSolutions} alt="logo" />
            </Center>
            {children}
        </Flex>
    </Container>

    );
}