import React from "react";

function Home({color}) {
    const {isActive} = color
   
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill={isActive? "#EE5622" : "#0C1851"}
      viewBox="0 0 24 24"
      className={isActive? "fill-gray-900" : "fill-gray-300"}
    >
      <mask
        id="mask0_284_19347"
        style={{ maskType: "alpha" }}
        width="24"
        height="24"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill={isActive? "black" : "red"} d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_284_19347)">
        <path
          fill={isActive? "fill-gray-900" : "fill-gray-300"}
          d="M6 19h3.35v-5.95h5.3V19H18v-9l-6-4.525L6 10v9zm-1.5 1.5V9.25L12 3.6l7.5 5.65V20.5h-6.35v-5.95h-2.3v5.95H4.5z"
        ></path>
      </g>
    </svg>
  );
}

export default Home;