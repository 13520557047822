import {colorToHex} from "app/helpers";

export default function VolumeIcon({size = 24, color = 'white'}) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 20.7251V19.1751C15.6167 18.7084 16.9375 17.8126 17.9625 16.4876C18.9875 15.1626 19.5 13.6584 19.5 11.9751C19.5 10.2918 18.9917 8.78343 17.975 7.4501C16.9583 6.11676 15.6333 5.2251 14 4.7751V3.2251C16.0667 3.69176 17.75 4.7376 19.05 6.3626C20.35 7.9876 21 9.85843 21 11.9751C21 14.0918 20.35 15.9626 19.05 17.5876C17.75 19.2126 16.0667 20.2584 14 20.7251ZM3 15.0001V9.0001H7L12 4.0001V20.0001L7 15.0001H3ZM13.5 16.2001V7.7751C14.4167 8.05843 15.1458 8.59176 15.6875 9.3751C16.2292 10.1584 16.5 11.0334 16.5 12.0001C16.5 12.9501 16.225 13.8168 15.675 14.6001C15.125 15.3834 14.4 15.9168 13.5 16.2001ZM10.5 7.8001L7.675 10.5001H4.5V13.5001H7.675L10.5 16.2251V7.8001Z"
        fill={colorToHex(color)}/>
    </svg>
  )
}
