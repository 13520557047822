import { forwardRef, Textarea as ChakraTextarea } from '@chakra-ui/react';
import ResizeTextarea from "react-textarea-autosize";

// @see https://github.com/chakra-ui/chakra-ui/issues/670
const Textarea = forwardRef((props, ref) => {
  return (
    <ChakraTextarea
      resize="none"
      minHeight="auto"
      minRows={3}
      as={ResizeTextarea}
      ref={ref}
      {...props}
    />
  );
});

export default Textarea;
