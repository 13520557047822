import {debounce} from "lodash";
import {useEffect, useMemo, useState} from "react";

export default function ProgressBar({currentTime = 0, duration = 0, onChange}) {
  const steps = 100;
  const [value, setValue] = useState(currentTime * steps);
  const [attached, setAttached] = useState(true);

  const debouncedOnChange = useMemo(() => {
    return debounce(timestamp => {
      onChange && onChange(timestamp);
    }, 50);
  }, [onChange]);

  const handleChange = (event) => {
    const timestamp = event.target.value / steps;
    setValue(event.target.value);
    debouncedOnChange(timestamp);
  };

  const handleMouseDown = () => {
    setAttached(false);
  }

  const handleMouseUp = () => {
    setAttached(true);
  }

  const makeColor = (color, fract) => {
    return color + ' ' + (fract * steps) + '%';
  }

  const gradient = [
    'to right',
    makeColor('#FF8B65', 0),
    makeColor('#FF8B65', duration > 0 ? value / steps / duration : 0),
    makeColor('#E2E2E2', duration > 0 ? value / steps / duration : 0),
    makeColor('#E2E2E2', 1),
  ];

  useEffect(() => {
    attached && setValue(currentTime * steps);
  }, [currentTime, attached]);

  return <div className="player-controls__progress">
    <input type="range" min={0} max={Math.ceil(duration * steps)} value={value}
           onMouseDown={handleMouseDown}
           onTouchStart={handleMouseDown}
           onMouseUp={handleMouseUp}
           onTouchEnd={handleMouseUp}
           onChange={handleChange} style={{
      background: 'linear-gradient(' + gradient.join(',') + ')',
    }}/>
  </div>
}
