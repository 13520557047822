import { signal } from "@preact/signals-react";
import {sleep} from "app/helpers";
import authService from "../auth/authService";

const currentWipe = signal({});
const wipeQueue = signal([]);

const showWipe = async (wipe) => {
  if (Array.isArray(wipe)) {
    wipe.forEach(item => showWipe(item));
  }
  else {
    // Load wipe src.
    try {
      const delay = wipe.animation?.in?.speed || 0;

      if (!currentWipe.value.video) {
        currentWipe.value = wipe;
      }
      else if (currentWipe.value.id !== wipe.id) {
        wipeQueue.value.push({...wipe});
      }

      if (delay > 0) {
        await sleep(delay);
      }
    }
    catch (e) {
      console.error(e);
    }
  }
}

const getWipeByName = async (machine_name) => {
  const response = await authService.getClient().request({
    url: `/api/v1/wipe/name/${machine_name}`,
    method: 'GET',
  })
  return response.data
}

const wipeService = {
  currentWipe,
  wipeQueue,
  showWipe,
  getWipeByName,
}

export default wipeService
