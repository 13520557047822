import {colorToHex} from "app/helpers";

function DownloadIcon({color = 'navy', size = 24}) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.3 19.5C5.8 19.5 5.375 19.325 5.025 18.975C4.675 18.625 4.5 18.2 4.5 17.7V15H6V17.7C6 17.7667 6.03333 17.8333 6.1 17.9C6.16667 17.9667 6.23333 18 6.3 18H17.7C17.7667 18 17.8333 17.9667 17.9 17.9C17.9667 17.8333 18 17.7667 18 17.7V15H19.5V17.7C19.5 18.2 19.325 18.625 18.975 18.975C18.625 19.325 18.2 19.5 17.7 19.5H6.3ZM12 15.625L7.725 11.35L8.775 10.25L11.25 12.725V4.325H12.75V12.725L15.225 10.25L16.275 11.35L12 15.625Z"
        fill={colorToHex(color)}/>
    </svg>
  );
}

export default DownloadIcon;
