import {useContext, useEffect, useRef, useState} from 'react';
import { useDrag, useDragLayer, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { throttle } from 'lodash/function';
import { cn } from 'app/helpers';
import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import { DndProvider } from './Drag';
import {ActivityContext} from "components/course/Activity/ActivityContext";

const classes = cn('drag-to-select');

// @todo Refactor draggable activities and extract reusable elements.
// @todo Move to CSS?
const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

function DragLayer() {
  // @see https://github.com/react-dnd/react-dnd/issues/3482
  // @see https://github.com/TechStark/react-dnd-scrolling/issues/5#issuecomment-900021719
  const isScrolling = useRef(false);

  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  function renderItem() {
    switch (itemType) {
      case 'item':
        return <OptionItem label={item.label} />
      default:
        return null
    }
  }

  const goDown = () => {
    // Frequent scrolling by small offset doesn't work well with smooth behavior
    // (which is also set via CSS) - "speed" is not much affected by changing
    // the offset value - so let's scroll with instant behavior instead.
    window.scrollBy({
      top: 6,
      behavior: 'instant',
    });

    const { scrollTop, scrollHeight } = document.documentElement;
    const isScrollEnd = window.innerHeight + scrollTop >= scrollHeight;

    if (isScrolling.current && !isScrollEnd) {
      window.requestAnimationFrame(goDown);
    }
  };

  const goUp = () => {
    window.scrollBy({
      top: -6,
      behavior: 'instant',
    });

    if (isScrolling.current && window.scrollY > 0) {
      window.requestAnimationFrame(goUp);
    }
  };

  const scrollOnDrag = (top, bottom) => {
    // @todo Think about delta when to consider a screen border touch.
    const isMouseOnTop = top <= 0;
    const isMouseOnBottom = window.innerHeight - bottom <= 0;

    if (!isScrolling.current && (isMouseOnTop || isMouseOnBottom)) {
      isScrolling.current = true;

      if (isMouseOnTop) {
        window.requestAnimationFrame(goUp);
      }

      if (isMouseOnBottom) {
        window.requestAnimationFrame(goDown);
      }
    }
    else if (!isMouseOnTop && !isMouseOnBottom) {
      isScrolling.current = false;
    }
  };

  const throttled = throttle(scrollOnDrag);

  useEffect(() => {
    // For some reason it rarely happens that isDragging is true but
    // currentOffset is null, so it breaks the app with JS error
    // (TypeError: Cannot read properties of null). Using 0 as a fallback for Y
    // coordinate doesn't make much sense. According to React DnD docs null
    // value is returned if no item is being dragged.
    // @see https://react-dnd.github.io/react-dnd/docs/api/drag-layer-monitor
    if (isDragging && currentOffset) {
      // It's supposed here that height of custom preview within custom drag
      // layer is the same as draggable element height.
      // @todo Forward ref and gather real preview height.
      throttled(currentOffset.y, currentOffset.y + item.height);
    }
  });

  if (!isDragging) {
    if (isScrolling.current) {
      isScrolling.current = false;
    }
    return null;
  }

  const style = {
    width: `${item.width}px`,
    transform: `translate(${currentOffset?.x || 0 }px,${currentOffset?.y || 0 }px)`,
  };
  return (
    <div style={layerStyles}>
      <div style={style}>
        {renderItem()}
      </div>
    </div>
  )
}

function OptionItem({label, isPlaceholder = false}) {
  return (
    <div
      className={classes('option', {placeholder: isPlaceholder})}
    >
      {label}
    </div>
  );
}

export const DraggableOptionItem = ({ label, isCorrect }) => {
  const ref = useRef(null);

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'item',
    item: (...args) => {
      return {
        label,
        width: ref.current?.getBoundingClientRect().width,
        height: ref.current?.getBoundingClientRect().height,
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: (monitor) => !isCorrect,
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, []);

  drag(ref);

  return (
    <div ref={ref}>
      <OptionItem label={label} isPlaceholder={isCorrect || isDragging} />
    </div>
  );
}

function DroppableItem({ index, onDrop, children, isEmpty = false }) {
  const [{ isOver }, drop] = useDrop({
    accept: 'item',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
    drop(item) {
      onDrop(item.label, index);
    },
  });

  return (
    <div
      className={classes('answer-container', {empty: isEmpty, highlighted: isOver })}
      ref={drop}
    >
      {children}
    </div>
  );
}

export default function DragToSelect({data}) {
  const {onNext} = useContext(ActivityContext);
  const {content, answer} = data;
  const options = content?.content?.options || [];
  const savedValues = answer?.value || [];
  const answersNumber = content?.content?.answers_number || 1;

  const [values, setValues] = useState(
    Array.from({length: answersNumber})
      .map((_, index) => savedValues[index] && options.includes(savedValues[index]) ? savedValues[index] : '')
  );

  // Activity is completed if all empty slots are filled.
  const isValid = values.filter(value => !!value).length === answersNumber;

  const handleDrop = (value, index) => {
    const newValues = Array.from(values);
    newValues[index] = value;
    setValues(newValues);
  };

  const handleSubmit = () => {
    onNext(values);
  };

  return (
    <ActivityContext.Provider value={{
      ...useContext(ActivityContext),
      canSubmit: isValid,
      onNext: handleSubmit,
    }}>
      <DndProvider>
        <DragLayer />
        <ActivityWrapper title={content?.title} description={content?.description}>
          <div className={classes()}>
            <div className={classes('answers-container')}>
              <div className={classes('answers')}>
                {values.map((value, index) => {
                  const isEmpty = !options.includes(value);

                  return (
                    <DroppableItem index={index} onDrop={handleDrop} isEmpty={isEmpty} key={index}>
                      {!isEmpty && <OptionItem label={value} />}
                    </DroppableItem>
                  );
                })}
              </div>
            </div>

            <div className={classes('options-container')}>
              <div className={classes('options')}>
                {options.map((label, index) => (
                  <DraggableOptionItem
                    label={label}
                    index={index}
                    isCorrect={values.includes(label)}
                    key={index}
                  />
                ))}
              </div>
            </div>
          </div>
        </ActivityWrapper>
      </DndProvider>
    </ActivityContext.Provider>
  );
}
