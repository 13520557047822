import { useState } from "react";
import TipContainer from './TipContainer';
import RichText from '../Activities/RichText';
import {Box} from "@chakra-ui/react";
import HelpIcon from 'icons/HelpIcon';
import {cn} from "app/helpers";

export default function Tips({tips}) {
    const classes = cn('tips');
    const [isVisible, setVisible] = useState(false);

    if (Array.isArray(tips) && tips.length > 0) {
        // @todo add support for multiple tips with pagination
        const currentTip = tips[0];
        
        return <>
            <Box className={classes('trigger')} position={'absolute'} left={'24px'} top={{base: 'auto', lg: '24px'}} bottom={{base: '24px', lg: 'auto'}} zIndex={2}>
                <button onClick={() => {setVisible(!isVisible)}} style={{
                    display: 'block',
                }}><HelpIcon fill='#fff' size='40'/></button>
            </Box>
            <TipContainer className={classes('container')} title={currentTip.title} isVisible={isVisible} onClose={() => setVisible(false)} icon={<HelpIcon />}>
                <RichText text={currentTip.description} className='tip__body' />
            </TipContainer>
        </>
    }
}
