import {useContext, useState} from 'react';
import { Box, IconButton, Image, Text, useMediaQuery } from '@chakra-ui/react';
import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import TipContainer from '../tips/TipContainer';
import RichText from './RichText';
import Voiceover from '../media/Voiceover';
import { ReactComponent as PlusIcon } from '../../icons/PlusIcon.svg';
import {ActivityContext} from "components/course/Activity/ActivityContext";

function imageHotspotsSortCallback(a, b) {
  const breakpointA = a.breakpoint || {weight: 0},
        breakpointB = b.breakpoint || {weight: 0};

  if (breakpointA.weight < breakpointB.weight) {
    return -1;
  }
  else if (breakpointA.weight > breakpointB.weight) {
    return 1;
  }
  return 0;
}

function HotspotButton({hotspot, isVisited, ...rest}) {
  return <IconButton size='md'
                     position='absolute'
                     left={`${hotspot.offset_x}%`}
                     top={`${hotspot.offset_y}%`}
                     aria-label={`Open "${hotspot.title}" hotspot`}
                     bg={isVisited ? 'washedOut.blue' : 'white'}
                     border='1px'
                     borderColor={isVisited ? 'secondary.lightblue' : 'washedOut.blue'}
                     transform='translate(-50%, -50%)'
                     isRound={true}
                     icon={<PlusIcon/>}
                     {...rest}

  />
}

export default function Hotspots({data}) {
  const { content } = data,
        // Make shallow copy to not affect order in original data when sorting.
        // Sort image hotspots by breakpoints' weight assuming they go from
        // lower resolutions to higher.
        imageHotspots = Array.from(content?.content?.image_hotspots || []).sort(imageHotspotsSortCallback),
        mediaQueries = imageHotspots.map(imageHotspot => imageHotspot.breakpoint?.mediaQuery || '');

  let minKey = undefined,
      minVisited = Number.POSITIVE_INFINITY;

  for (let i = 0; i < imageHotspots.length; i++) {
    if (imageHotspots[i].hotspots.length < minVisited) {
      minVisited = imageHotspots[i].hotspots.length;
      minKey = i;
    }
  }

  if (minKey === undefined) {
    minVisited = 0;
  }

  // SSR setting is true by default which results to false for all media queries
  // on the first run.
  const mediaMatches = useMediaQuery(mediaQueries, {ssr: false}),
        // Find last matched media query.
        mediaMatchedKey = mediaMatches.findLastIndex(match => !!match);

  const imageHotspot = (mediaMatchedKey > -1) ? imageHotspots[mediaMatchedKey] : null;

  const [selected, setSelected] = useState(imageHotspot?.hotspots[0] ? 0 : -1);
  const selectedHotspot = imageHotspot?.hotspots[selected] || null;

  const [showHotspot, setShowHotspot] = useState(false);
  const [showError, setShowError] = useState(false);

  const isComplete = (data.progress?.complete || 0) >= 1;
  const [visited, setVisited] = useState(isComplete ? Array(minVisited).fill(true) : []);
  const allVisited = visited.filter(item => !!item).length >= minVisited;

  const markVisited = (index) => {
    const visitedNew = [...visited];
    visitedNew[index] = true;
    setVisited(visitedNew);
  }

  const handleClick = (hotspot, index) => {
    // Don't allow to switch to another hotspot if another is open and voiceover
    // is not listened.
    if (showHotspot && selected > - 1 && index !== selected && !visited[selected]) {
      setShowError(true);
    }
    else {
      // Mark hotspot as visited if it doesn't have a voiceover. Otherwise it'll
      // be marked as visited only when voiceover is listened.
      if (!hotspot.voiceover) {
        markVisited(index);
      }
      if (index !== selected) {
        setShowError(false);
        setSelected(index);
      }
      setShowHotspot(true);
    }
  }

  const handleClose = () => {
    if (!visited[selected]) {
      setShowError(true);
    }
    else {
      setShowHotspot(false);
      setShowError(false);
    }
  }

  return (
    <ActivityContext.Provider value={{
      ...useContext(ActivityContext),
      canSubmit: allVisited,
    }}>
      <ActivityWrapper title={data.content?.title} description={data.content?.description}>
        {imageHotspot &&
          <Box mt={4} display='inline-block' position='relative'>
            <Image src={imageHotspot.image.file.url} alt={imageHotspot.image.file.alt} htmlWidth={imageHotspot.image.file.width} htmlHeight={imageHotspot.image.file.height} loading='lazy' />
            {imageHotspot.hotspots.map((hotspot, index) => <HotspotButton hotspot={hotspot} key={index} isVisited={!!visited[index]} onClick={() => handleClick(hotspot, index)} />)}
          </Box>
        }
      </ActivityWrapper>
      {selectedHotspot &&
        <TipContainer title={selectedHotspot.title} isVisible={showHotspot} onClose={handleClose} slideFrom='right'>
          <RichText text={selectedHotspot.body} className='hotspot__body' />
          <Text color='secondary.red' my={4} visibility={showError ? 'visible' : 'hidden'}>
            Please complete listening to the audio before proceeding.
          </Text>

          {selectedHotspot.voiceover && showHotspot &&
            <Voiceover media={selectedHotspot.voiceover} onComplete={() => markVisited(selected)} />
          }
        </TipContainer>
      }
    </ActivityContext.Provider>
  );

}
