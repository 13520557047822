function WarningIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="18"
        fill="none"
        viewBox="0 0 22 18"
      >
        <path
          fill="#DE0808"
          d="M.875 17.5L11 0l10.125 17.5H.875zM11 14.8a.779.779 0 00.575-.225A.779.779 0 0011.8 14a.779.779 0 00-.225-.575A.779.779 0 0011 13.2a.779.779 0 00-.575.225.779.779 0 00-.225.575c0 .233.075.425.225.575.15.15.342.225.575.225zm-.75-2.6h1.5v-5h-1.5v5z"
        ></path>
      </svg>
    );
  }
  
  export default WarningIcon;
  