import {  Flex } from '@chakra-ui/react'



export default function RegisterLoginLayout({children}) {
   
    return (
        <Flex alignItems={'center'} w={'100%'}  minHeight={'100vh'} h={'100%'} bg={{lg: 'lightGrey.200', base: 'lightGrey.100'}}>
            {children}       
        </Flex>
       
    );
}