function VisibilityHide() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_610_42545"
        style={{ maskType: "alpha" }}
        width="24"
        height="24"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_610_42545)">
        <path
          fill="#0C1851"
          d="M12 15.575c1.133 0 2.096-.396 2.887-1.188.792-.791 1.188-1.754 1.188-2.887s-.396-2.096-1.188-2.887c-.791-.792-1.754-1.188-2.887-1.188s-2.096.396-2.887 1.188c-.792.791-1.188 1.754-1.188 2.887s.396 2.096 1.188 2.887c.791.792 1.754 1.188 2.887 1.188zm0-1.375c-.75 0-1.387-.263-1.912-.788A2.601 2.601 0 019.3 11.5c0-.75.263-1.388.788-1.913A2.603 2.603 0 0112 8.8c.75 0 1.388.262 1.913.787.525.525.787 1.163.787 1.913s-.262 1.387-.787 1.912A2.605 2.605 0 0112 14.2zm0 4.3c-2.3 0-4.396-.633-6.287-1.9-1.892-1.267-3.28-2.967-4.163-5.1.883-2.133 2.271-3.833 4.163-5.1C7.604 5.133 9.7 4.5 12 4.5s4.396.633 6.288 1.9c1.891 1.267 3.279 2.967 4.162 5.1-.883 2.133-2.27 3.833-4.162 5.1-1.892 1.267-3.988 1.9-6.288 1.9zm0-1.5a9.545 9.545 0 005.188-1.488A9.77 9.77 0 0020.8 11.5a9.777 9.777 0 00-3.612-4.013A9.55 9.55 0 0012 6a9.55 9.55 0 00-5.188 1.487A9.777 9.777 0 003.2 11.5a9.77 9.77 0 003.612 4.012A9.545 9.545 0 0012 17z"
        ></path>
      </g>
    </svg>
  );
}

export default VisibilityHide;