import {colorToHex} from "app/helpers";

function MutedIcon({color = 'white', size = 24}) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.325 22.6L17.025 19.3C16.5583 19.6334 16.0541 19.9209 15.5125 20.1625C14.9708 20.4042 14.4083 20.5917 13.825 20.725V19.175C14.2083 19.0584 14.5791 18.9292 14.9375 18.7875C15.2958 18.6459 15.6333 18.4584 15.95 18.225L11.825 14.075V20L6.82498 15H2.82498V9.00005H6.72498L1.22498 3.50005L2.29998 2.42505L21.4 21.5L20.325 22.6ZM19.425 16.8L18.35 15.725C18.6833 15.1584 18.9291 14.5584 19.0875 13.925C19.2458 13.2917 19.325 12.6417 19.325 11.975C19.325 10.2584 18.825 8.72088 17.825 7.36255C16.825 6.00422 15.4916 5.14172 13.825 4.77505V3.22505C15.8916 3.69172 17.575 4.73755 18.875 6.36255C20.175 7.98755 20.825 9.85838 20.825 11.975C20.825 12.825 20.7083 13.6584 20.475 14.475C20.2416 15.2917 19.8916 16.0667 19.425 16.8ZM16.075 13.45L13.825 11.2V7.95005C14.6083 8.31672 15.2208 8.86672 15.6625 9.60005C16.1041 10.3334 16.325 11.1334 16.325 12C16.325 12.25 16.3041 12.4959 16.2625 12.7375C16.2208 12.9792 16.1583 13.2167 16.075 13.45ZM11.825 9.20005L9.22498 6.60005L11.825 4.00005V9.20005ZM10.325 16.35V12.6L8.22498 10.5H4.32498V13.5H7.47498L10.325 16.35Z"
        fill={colorToHex(color)}/>
    </svg>
  );
}

export default MutedIcon;
