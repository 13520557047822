import {useNavigate} from "react-router-dom";
import {Flex, Link, Text, Heading, Box} from '@chakra-ui/react'
import NewPasswordForm from "../components/NewPassowrdForm";
import {useSelector, useDispatch} from 'react-redux'
import {requestNewPassword, setSuccess, reset} from '../features/auth/authSlice'
import RegisterLoginLayout from "../components/layout/RegisterLoginLayout";
import Btn from "../components/Button";
import SignInFormPanel from "../components/layout/SiginInFormPanel";
import {useEffect} from "react";
import {toast} from 'react-toastify'


export default function NewPassword() {

  const navigate = useNavigate()

  const goBack = () => {
    dispatch(reset())
    navigate('/login')
  }

  const dispatch = useDispatch()
  const {isError, isSuccess, message, email} = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
    if (isSuccess) {
      toast.success('A password reset link has been sent to your email address.')
    }
  }, [isError, isSuccess, message])

  const handleClick = () => {
    const user = {email: email}
    dispatch(requestNewPassword(user))
  }
  return (
    <RegisterLoginLayout>
      <SignInFormPanel>
        {email ?
          (<Flex alignItems={'center'} direction={'column'} color={'primary.navy'}>
            <Box pb={20}>
              <Heading size={'lg'} color={'primary.navy'} as={'h3'}>Request a new password</Heading>
              <Text mt={4} fontSize={'sm'}>A password reset link has been sent to your email address. Please make sure
                to check your spam folder. </Text>
              <Text my={10} fontSize={'sm'}>Haven't received your reset link yet? <Link onClick={handleClick}
                                                                                        color={'other.hyperlink'}
                                                                                        fontSize={'sm'}
                                                                                        textDecoration={'underline'}>Resend
                link</Link></Text>
            </Box>
            <Btn onClick={goBack} name={"submit"} copy={"Back to Sign In"} bgColor={'primary.flame'}
                 colorScheme={'primary.navy'}/>
          </Flex>)
          :
          <NewPasswordForm/>
        }

      </SignInFormPanel>
    </RegisterLoginLayout>
  );
}