import { defineStyleConfig } from '@chakra-ui/react'

// define the base component styles
const baseStyle = {
    paddingRight: '20px',
    paddingLeft: '20px',
    sizes: {
        maxWidth: '1456px'
    }
}

// export the component theme
export const containerStyles = defineStyleConfig({ baseStyle })