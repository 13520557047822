import { Button } from '@chakra-ui/react'

const Btn = ({ isLoading, colorScheme, variant, onClick, txColor, bgColor, name, copy, type }) => {
    return (
        <Button isLoading={isLoading} onClick={onClick} width={'248px'} variant={variant} type={type} fontFamily={"button"} colorScheme={colorScheme} borderRadius={'3xl'} name={name} color={txColor} bg={bgColor}>
            {copy}
        </Button>
    )
}

export default Btn