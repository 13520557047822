import {Fragment, useContext, useEffect, useMemo, useRef, useState} from 'react';
import { useDrag, useDragLayer, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import shuffle from 'just-shuffle';
import { cn } from 'app/helpers';
import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import { throttle } from 'lodash/function';
import { DndProvider } from './Drag';
import BackArrow from 'icons/BackArrow';
import CompleteIcon from 'icons/CompleteIcon';
import CloseIcon from 'icons/CloseIcon';
import {playSound} from "app/sounds";
import {ActivityContext} from "components/course/Activity/ActivityContext";

const classes = cn('drag-to-select');

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

function DragLayer() {
  const isScrolling = useRef(false);

  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  function renderItem() {
    switch (itemType) {
      case 'item':
        return <OptionItem label={item.label} />
      default:
        return null
    }
  }

  const goDown = () => {
    window.scrollBy({
      top: 6,
      behavior: 'instant',
    });

    const { scrollTop, scrollHeight } = document.documentElement;
    const isScrollEnd = window.innerHeight + scrollTop >= scrollHeight;

    if (isScrolling.current && !isScrollEnd) {
      window.requestAnimationFrame(goDown);
    }
  };

  const goUp = () => {
    window.scrollBy({
      top: -6,
      behavior: 'instant',
    });

    if (isScrolling.current && window.scrollY > 0) {
      window.requestAnimationFrame(goUp);
    }
  };

  const scrollOnDrag = (top, bottom) => {
    const isMouseOnTop = top <= 0;
    const isMouseOnBottom = window.innerHeight - bottom <= 0;

    if (!isScrolling.current && (isMouseOnTop || isMouseOnBottom)) {
      isScrolling.current = true;

      if (isMouseOnTop) {
        window.requestAnimationFrame(goUp);
      }

      if (isMouseOnBottom) {
        window.requestAnimationFrame(goDown);
      }
    }
    else if (!isMouseOnTop && !isMouseOnBottom) {
      isScrolling.current = false;
    }
  };

  const throttled = throttle(scrollOnDrag);

  useEffect(() => {
    if (isDragging && currentOffset) {
      throttled(currentOffset.y, currentOffset.y + item.height);
    }
  });

  if (!isDragging) {
    if (isScrolling.current) {
      isScrolling.current = false;
    }
    return null;
  }

  const style = {
    width: `${item.width}px`,
    transform: `translate(${currentOffset?.x || 0 }px,${currentOffset?.y || 0 }px)`,
  };
  return (
    <div style={layerStyles}>
      <div style={style}>
        {renderItem()}
      </div>
    </div>
  )
}

function OptionItem({label, isIncorrect = false, isPlaceholder = false}) {
  return (
    <div
      className={classes('option', {incorrect: isIncorrect, placeholder: isPlaceholder})}
    >
      {label}
      {isIncorrect && <div className={classes('icon')}>
        <CloseIcon />
      </div>}
    </div>
  );
}

export const DraggableOptionItem = ({ label, onDragStart, canDrag, isCorrect, isIncorrect }) => {
  const ref = useRef(null);

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'item',
    item: (...args) => {
      onDragStart();
      return {
        label,
        width: ref.current?.getBoundingClientRect().width,
        height: ref.current?.getBoundingClientRect().height,
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: (monitor) => canDrag,
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, []);

  drag(ref);

  return (
    <div ref={ref}>
      <OptionItem label={label} isIncorrect={isIncorrect} isPlaceholder={isCorrect || isDragging} />
    </div>
  );
}

function DroppableItem({ label, onDrop }) {
  const [{ isOver }, drop] = useDrop({
    accept: 'item',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
    drop(item) {
      onDrop(item.label, label);
    },
  });

  return (
    <div
      className={classes('answer-container', {empty: true, highlighted: isOver })}
      ref={drop}
    ></div>
  );
}

export default function DragToSequence({data, isSubmitting, setSubmitting, setCanSubmit, onSubmitted, onError}) {
  const {id, content} = data;
  const answers = content?.content?.answers || [];
  const answersNonSticky = answers.filter(answer => !answer.sticky);

  const options = useMemo(() => {
    // Merge options with non-sticky answers, then shuffle.
    return shuffle(answersNonSticky.map(answer => answer.value).concat(content?.content?.options || []));
    // Maybe should depend on data instead of id, but for now it actually
    // triggers the callback more than once.
  }, [id]);

  const [correct, setCorrect] = useState([]);
  const [incorrect, setIncorrect] = useState([]);
  const [showMessage, setShowMessage] = useState(false);

  // Activity is completed if all empty slots are correctly filled.
  const isValid = correct.length === answersNonSticky.length;

  const handleDragStart = () => {
    if (showMessage) {
      setShowMessage(false);
    }
  };

  const handleDrop = (draggableLabel, droppableLabel) => {
    if (draggableLabel === droppableLabel && !correct.includes(draggableLabel)) {
      const newCorrect = [...correct, draggableLabel];
      setCorrect(newCorrect);
      if (answers.length === newCorrect.length) {
        playSound('complete');
      }
      else {
        playSound('correct');
      }
    }

    if (draggableLabel !== droppableLabel) {
      setShowMessage(true);
      playSound('incorrect');

      // If initially there is only one slot, mark tried options as incorrect to
      // disallow further dragging.
      // @todo In case of multiple available slots mark option as incorrect when all slots are tried.
      if (answersNonSticky.length === 1 && !incorrect.includes(draggableLabel)) {
        setIncorrect([...incorrect, draggableLabel]);
      }
    }
  };

  const {onNext} = useContext(ActivityContext);
  const handleSubmit = () => {
    // Backend expects all answers (values in the right sidebar) including
    // both sticky and non-sticky in correct order.
    const values = answers
      .filter(answer => answer.sticky || correct.includes(answer.value))
      .map(answer => answer.value);
    onNext(values);
  };


  return (
    <ActivityContext.Provider value={{
      ...useContext(ActivityContext),
      canSubmit: isValid,
      onNext: handleSubmit,
    }}>
      <DndProvider>
        <DragLayer />
        <ActivityWrapper title={content?.title} description={content?.description}>
          <div className={classes()}>
            <div className={classes('answers-container')}>
              <div className={classes('answers')}>
                {answers.map((answer, index) => {
                  const isCorrect = correct.includes(answer.value);
                  const isEmpty = !answer.sticky && !isCorrect;

                  return (
                    <Fragment key={index}>
                      {isEmpty && <DroppableItem label={answer.value} onDrop={handleDrop} />}
                      {!isEmpty && <div className={classes('answer', { sticky: !!answer.sticky })}>
                        {answer.value}
                        {isCorrect && <div className={classes('icon')}>
                          {<CompleteIcon size={22} color={'navy'} />}
                        </div>}
                      </div>}
                      {index !== (answers.length - 1) && <div className={classes('separator')}>
                        <BackArrow color="#fff" />
                      </div>}
                    </Fragment>
                  );
                })}
              </div>

              <div className={classes('message', {hidden: !showMessage})}>
                <CloseIcon color={'orangeWashed'} />
                <div>Incorrect</div>
              </div>
            </div>

            <div className={classes('options-container')}>
              <div className={classes('options')}>
                {options.map((label, index) => (
                  <DraggableOptionItem
                    label={label}
                    onDragStart={handleDragStart}
                    canDrag={!isValid && !incorrect.includes(label) && !correct.includes(label)}
                    isCorrect={correct.includes(label)}
                    isIncorrect={incorrect.includes(label)}
                    key={index}
                  />
                ))}
              </div>
            </div>
          </div>
        </ActivityWrapper>
      </DndProvider>
    </ActivityContext.Provider>
  );
}
