import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import { Image } from '@chakra-ui/react';

export default function Quote({data}) {
  const {content} = data
  const image = content?.content?.image?.file;
  const aside = image ? <Image src={image.url} alt={image.alt} /> : null;

  return (
    <ActivityWrapper title={content?.title} description={content?.description} aside={aside}/>
  )
}
