import authService from "features/auth/authService";

const submitWebform = async (params) => {
  const response = await authService.getClient().request({
    url: `/api/v1/webform/submit`,
    method: 'POST',
    data: params,
  });

  return response.data;
}

const webformService = {
  submitWebform,
};

export default webformService;
