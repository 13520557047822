import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import SegmentCard from "../course/SegmentCard";
import {Box} from "@chakra-ui/react";
import {cn} from "app/helpers";
import Block from "../layout/Block";
import {useLikesData} from "features/like/likesHooks";
import {useState} from "react";
import {getCourseContent} from "features/course/courseContentSlice";
import {useSelector} from "react-redux";

export default function SavedContentBlock() {
  const maxItems = 8;
  const classes = cn('saved-content');
  // useState() is used here to make the params object not change during
  // re-renders and to not trigger additional data fetching in result.
  // @todo Use useMemo() instead? Moving `params` definition outside of the function may also fit (but the object will stay the same even on component unmount).
  const [params] = useState( {
    'entity_type': 'course_content',
    'bundle': 'course_segment',
    'items_per_page': maxItems,
    'sort_by': 'timestamp',
    'sort_order': 'DESC',
  });
  const [likes] = useLikesData(params);
  const items = useSelector(state => likes.filter(like => {
    const content = getCourseContent(state, like.entity_id);
    if (content?.isError) return false;

    return like;
  }));

  return items.length > 0 && <Box className={classes()}>
    <Block title={'Saved content'} to={'/course/saved'}>
      <Swiper
        slidesPerView={'auto'}
        className={classes('carousel')}
        modules={[Navigation]}
        navigation
      >
        {items.map(like => <SwiperSlide key={like.entity_id} className={classes('slide')}><SegmentCard id={like.entity_id} vertical={true} /></SwiperSlide>)}
      </Swiper>
    </Block>
  </Box>
}
