import {useState, useEffect, useContext} from "react";
import { useDispatch } from "react-redux";
import { HStack, NumberInput, NumberInputField } from '@chakra-ui/react';
import {submitAnswer} from "../../features/course/courseContentSlice";
import ActivityWrapper from "components/course/Activity/ActivityWrapper";
import Notice from '../error/Notice';
import {ActivityContext} from "components/course/Activity/ActivityContext";

function getDateString(date) {
  const year = String(date.getFullYear()).padStart(4, "0"),
        // Month is number 0-11.
        month = String(date.getMonth() + 1).padStart(2, "0"),
        day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export default function DateActivity({data}) {
  const {onNext} = useContext(ActivityContext);
  const { answer } = data;

  let matches = [];
  if (answer?.value) {
    matches = answer.value.match(/(\d+)-(\d+)-(\d+)/) || [];
  }

  const [day, setDay] = useState(matches[3] || '');
  const [month, setMonth] = useState(matches[2] || '');
  const [year, setYear] = useState(matches[1] || '');

  const dateValue = new Date(`${year}-${month}-${day}`);
  const today = new Date();
  // Set time to 00:00:00.
  today.setHours(0, 0, 0, 0);
  const allFilled = !!(day.length && month.length && year.length === 4);
  const dateIsValid = !isNaN(dateValue);
  const dateIsPast = dateValue < today;
  // Allow to submit previously saved answer even if it became a past date since
  // it was initially saved.
  const isSavedAnswer = !!(answer?.value && dateIsValid && getDateString(dateValue) === answer.value);
  const isValid = allFilled && dateIsValid && (!dateIsPast || isSavedAnswer);

  const handleSubmit = () => {
    onNext(getDateString(dateValue));
  }

  // @todo Should behave as typical datepicker - navigate to next input once current reached max length. Use native date input?
  return (
    <ActivityContext.Provider value={{
      ...useContext(ActivityContext),
      canSubmit: isValid,
      onNext: handleSubmit,
    }}>
      <ActivityWrapper title={data.content?.title} description={data.content?.description}>
        <HStack mt={4}>
          <NumberInput inputMode={'numeric'} value={day} onChange={setDay} min={1} max={31} pattern={'[0-9]*'} isRequired clampValueOnBlur={false} aria-label={'Day'}>
            <NumberInputField name={'day'} placeholder={'DD'} size={2} maxLength={2} bg={'white'} textAlign={['center']} ps={2} pe={2} minWidth='48px' />
          </NumberInput>
          <NumberInput inputMode={'numeric'} value={month} onChange={setMonth} min={1} max={12} pattern={'[0-9]*'} isRequired clampValueOnBlur={false} aria-label={'Month'}>
            <NumberInputField name={'month'} bg={'white'} placeholder={'MM'} size={2} maxLength={2} textAlign={['center']} ps={2} pe={2} minWidth='48px' />
          </NumberInput>
          <NumberInput inputMode={'numeric'} value={year} onChange={setYear} pattern={'[0-9]*'} isRequired clampValueOnBlur={false} aria-label={'Year'}>
            <NumberInputField name={'year'} bg={'white'} placeholder={'YYYY'} size={4} maxLength={4} textAlign={['center']} ps={2} pe={2} minWidth='68px' />
          </NumberInput>
        </HStack>

        {allFilled && !isValid &&
        <Notice>
          {(dateIsValid && dateIsPast) ? "Date can not be in the past." : "Please enter a valid date."}
        </Notice>
        }
      </ActivityWrapper>
    </ActivityContext.Provider>
  )

}
