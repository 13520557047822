import {cn} from "app/helpers";
import ProgressBar from "./ProgressBar";
import {useCallback, useEffect, useState} from "react";
import PlayButton from "./PlayButton";
import Volume from "./Volume";
import {Box, Switch, useBreakpointValue} from "@chakra-ui/react";
import TimeDuration from "./TimeDuration";
import FullscreenButton from "./FullscreenButton";
import SettingsButton from "./SettingsButton";
import CaptionsIcon from "icons/CaptionsIcon";
import AutoplayIcon from "icons/AutoplayIcon";
import "styles/components/media/_controls.scss";
import CaptionsButton from "./CaptionsButton";
import {useDispatch, useSelector} from "react-redux";
import {
  getMediaUserSettings,
  enableMediaAutoplay,
  enableMediaCaptions, setMediaVolume, setMediaMuted,
} from "features/media/mediaSlice";

const SettingSwitch = ({id, title, icon, value, setValue}) => {
  const classes = cn('player-controls');
  const variant = {};
  variant[id] = true;

  const handleChange = (e) => {
    setValue && setValue(e.target.checked);
  };

  return <div className={classes('setting', variant)}>
    <label className={classes('setting-label')}>
      {icon}
      <span>{title}</span>
      <Switch size="sm" variant="outline" isChecked={value} onChange={handleChange}/>
    </label>
  </div>
}

export default function Controls({
                                   media = null,
                                   currentTime,
                                   duration,
                                   volume,
                                   isPlaying,
                                   isEnded,
                                   isMuted,
                                   onPlay,
                                   onPause,
                                   onProgressChange,
                                   onVolumeChange,
                                   allowAutoPlay = true,
                                   allowCaptions = true,
                                   allowFullscreen,
                                   toggleFullscreen,
                                   classNames = [],
                                   captionsButton = 'default'
                                 }) {
  const dispatch = useDispatch();
  const classes = cn('player-controls');
  const showCaptionsButton = useBreakpointValue({
    base: false,
    lg: true,
  }) && (captionsButton === 'default' || captionsButton);
  const [showSettings, setShowSettings] = useState(false);
  const userSettings = useSelector(state => getMediaUserSettings(state));
  const hasSettings = allowAutoPlay || (allowCaptions && !showCaptionsButton);

  const [state, setState] = useState({
    isPlaying: media ? (media.autoplay || !media.paused) : false,
    isEnded: false,
    isMuted: userSettings.muted,
    currentTime: 0,
    duration: 0,
    volume: userSettings.volume,
  });

  const handleState = useCallback((event) => {
    const media = event.target;

    setState({
      ...state,
      isPlaying: !media.paused,
      isEnded: media.ended,
      isMuted: media.muted,
      currentTime: media.currentTime,
      duration: !isNaN(media.duration) ? media.duration : 0,
      volume: media.volume,
    });
  }, [state]);

  useEffect(() => {
    if (media) {
      media.addEventListener('timeupdate', handleState);
      media.addEventListener('durationchange', handleState);
      media.addEventListener('play', handleState);
      media.addEventListener('pause', handleState);
      media.addEventListener('volumechange', handleState);
    }

    return () => {
      if (media) {
        media.removeEventListener('timeupdate', handleState);
        media.removeEventListener('durationchange', handleState);
        media.removeEventListener('play', handleState);
        media.removeEventListener('pause', handleState);
        media.removeEventListener('volumechange', handleState);
      }
    }
  }, [media, handleState]);

  const enableCaptions = (enabled) => {
    dispatch(enableMediaCaptions(enabled));
  }

  const enableAutoplay = (enabled) => {
    dispatch(enableMediaAutoplay(enabled));
  }

  const isFullScreenEnabled = () => {
    if (document.fullscreenEnabled) {
      return !!document.fullscreenElement;
    }

    if (media && media.webkitSupportsFullscreen) {
      return media.webkitDisplayingFullscreen;
    }
    return false;
  };

  const enterFullScreen = async () => {
    try {
      if (document.fullscreenEnabled) {
        if (document.pictureInPictureElement) {
          await document.exitPictureInPicture();
        }
        const fullScreenElement = this.config_.fullScreenElement;
        await fullScreenElement.requestFullscreen({navigationUI: 'hide'});
      } else {
        if (media && media.webkitSupportsFullscreen) {
          media.webkitEnterFullscreen();
        }
      }
    } catch (e) {
    }
  }

  const exitFullScreen = async () => {
    if (document.fullscreenEnabled) {
      await document.exitFullscreen();
    } else {
      if (media && media.webkitSupportsFullscreen) {
        media.webkitExitFullscreen();
      }
    }
  }

  /** Set default values */
  if (media) {
    isPlaying = isPlaying || state.isPlaying;
    isEnded = isEnded || state.isEnded;
    isMuted = isMuted || state.isMuted;
    currentTime = isNaN(currentTime) ? state.currentTime : currentTime;
    duration = isNaN(duration) ? state.duration : duration;
    volume = isNaN(volume) ? state.volume : volume;
  }
  else {
    currentTime = isNaN(currentTime) ? 0 : currentTime;
    duration = isNaN(duration) ? 0 : duration;
  }
  toggleFullscreen = typeof toggleFullscreen === 'function' ? toggleFullscreen : async () => {
    if (isFullScreenEnabled()) {
      await exitFullScreen();
    } else {
      await enterFullScreen();
    }
  };

  const handlePlay = useCallback(() => {
    if (media && !onPlay) {
      media.play();
    }
    onPlay && onPlay();
  }, [media, onPlay]);

  const handlePause = useCallback(() => {
    if (media && !onPause) {
      media.pause();
    }
    onPause && onPause();
  }, [media, onPause]);

  const handleProgress = useCallback((timestamp) => {
    if (media && !onProgressChange) {
      media.currentTime = timestamp;
    }
    onProgressChange && onProgressChange(timestamp);
  }, [media, onProgressChange]);

  const handleVolume = useCallback((volume, isMuted) => {
    if (media && !onVolumeChange) {
      media.muted = isMuted;
      media.volume = volume;
    }

    dispatch(setMediaVolume(volume));
    dispatch(setMediaMuted(isMuted));
    onVolumeChange && onVolumeChange(volume, isMuted);
  }, [dispatch, media, onVolumeChange]);

  return <Box className={classes({}, classNames)}>
    {hasSettings && <Box className={classes('settings', {hidden: !showSettings})}>
      {(allowCaptions && !showCaptionsButton) &&
      <SettingSwitch key={'captions'} id={'captions'} title={'Captions'} icon={<CaptionsIcon size={16}/>}
                     value={userSettings.captions} setValue={enableCaptions}/>}
      {allowAutoPlay && <SettingSwitch key={'autoplay'} id={'autoplay'} title={'Autoplay'} icon={<AutoplayIcon/>}
                                       value={userSettings.autoPlay} setValue={enableAutoplay}/>}
    </Box>}
    <ProgressBar currentTime={currentTime} duration={duration} onChange={handleProgress}/>
    <Box className={classes('buttons')}>
      <PlayButton isPlaying={isPlaying} isEnded={isEnded} onPlay={handlePlay} onPause={handlePause}/>
      <TimeDuration currentTime={currentTime} duration={duration}/>
      <Box className={classes('separator')}/>
      <Volume isMuted={isMuted} volume={volume} onChange={handleVolume}/>
      {(allowCaptions && showCaptionsButton) &&
      <CaptionsButton enabled={userSettings.captions} setCaptions={enableCaptions}/>}
      {hasSettings && <SettingsButton {...{showSettings, setShowSettings}}/>}
      {allowFullscreen && <FullscreenButton {...{toggleFullscreen}}/>}
    </Box>
  </Box>
}
