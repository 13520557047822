import {cn} from "app/helpers";
import Voiceover from "components/media/Voiceover";
import Pager from "components/layout/Pager";
import {Flex} from "@chakra-ui/react";
import {useNavigation} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {VoiceoverContext} from "components/media/VoiceoverContext";
import {ActivityContext} from "components/course/Activity/ActivityContext";

export default function ActivityFooter({}) {
  const classes = cn('activity');
  const navigation = useNavigation();
  const {
    isSubmitting,
    voiceoverRequired = true,
    showFooter,
    pagerType,
    totalPages = 0,
    currentPage = 0,
    setCurrentPage,
    onNext,
    onPrev,
    canSubmit,
    canNavigate,
    nextLabel,
    toggleFullscreen
  } = useContext(ActivityContext);
  const {voiceover} = useContext(VoiceoverContext);

  const [voiceoverComplete, setVoiceoverComplete] = useState(false);
  const handleVoiceoverComplete = () => {
    setVoiceoverComplete(true);
  };

  useEffect(() => {
    setVoiceoverComplete(false);
  }, [voiceover]);

  const disabled = voiceover && voiceoverRequired && !voiceoverComplete;

  return showFooter && <Flex className={classes('footer')}>
    {voiceover &&
    <Voiceover media={voiceover} onComplete={handleVoiceoverComplete} toggleFullscreen={toggleFullscreen}/>}
    <Pager type={pagerType === 'mini' ? 'mini' : null}
           totalPages={totalPages}
           currentPage={currentPage}
           setCurrentPage={setCurrentPage}
           canNavigate={canNavigate}
           onNext={onNext}
           onPrev={onPrev}
           isLoading={isSubmitting || navigation.state === 'loading'}
           disabled={disabled || !canSubmit}
           nextLabel={nextLabel}
    />
  </Flex>
}
