import {colorToHex} from "app/helpers";

function NotesIcon({color = 'navy', variant = 'solid', size = 24}) {
  if (variant === 'outline') {
    return (
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2 24V21H22V24H2ZM4.5 18.5V15.45L13.425 6.525L16.475 9.575L7.55 18.5H4.5ZM6 17H6.9L14.375 9.575L13.425 8.625L6 16.1V17ZM17.475 8.525L14.45 5.5L15.925 4.025C16.1083 3.84167 16.3377 3.75 16.613 3.75C16.8877 3.75 17.1167 3.84167 17.3 4.025L18.925 5.675C19.1083 5.85833 19.2043 6.08333 19.213 6.35C19.221 6.61667 19.1333 6.85 18.95 7.05L17.475 8.525Z"
          fill={colorToHex(color)}/>
      </svg>
    );
  }

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 24V21H22V24H2ZM4.5 18.5V15.45L13.425 6.525L16.475 9.575L7.55 18.5H4.5ZM17.475 8.525L14.45 5.5L15.925 4.025C16.1083 3.84167 16.3377 3.75 16.613 3.75C16.8877 3.75 17.1167 3.84167 17.3 4.025L18.925 5.675C19.1083 5.85833 19.2043 6.08333 19.213 6.35C19.221 6.61667 19.1333 6.85 18.95 7.05L17.475 8.525Z"
        fill={colorToHex(color)}/>
    </svg>
  );
}

export default NotesIcon;
