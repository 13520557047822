import {cn} from "../../app/helpers";
import {Box, Link} from "@chakra-ui/react";
import {Link as ReachLink} from "react-router-dom";
import ChevronRightSquare from "../../icons/ChevronRightSquare";

export default function Block({title, to, toLabel = 'View all', children, className}) {
  const classes = cn('block');
  const extraClasses = className ? className.split(' ') : [];

  return <Box className={classes({}, extraClasses)}>
    {title && <Box className={classes('header')}>
      <h2 className={classes('title')}>{title}</h2>
      {to && <Box className={classes('view-more')}>
        <Link as={ReachLink} to={to}>
          {toLabel}
          <ChevronRightSquare/>
        </Link>
      </Box>}
    </Box>}
    {children && <Box className={classes('content')}>
      {children}
    </Box>}
  </Box>
}
