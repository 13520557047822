import authService from "../auth/authService";

let getResourcesPromise;

const processResource = (data) => {
  data.locked = data.locked && !data.file;
}

const getResources = async () => {
  if (!getResourcesPromise) {
    getResourcesPromise = authService.getClient().request({
      url: `/api/v1/resources`,
      method: 'GET',
    });
    getResourcesPromise.then(() => {
      getResourcesPromise = null;
    }).catch(() => {
      getResourcesPromise = null;
    });
  }

  const response = await getResourcesPromise;

  if (response.data.items) {
    response.data.items.forEach(resource => {
      processResource(resource);
    })
  }

  return response.data;
}

const resourcesService = {
  getResources,
}

export default resourcesService
