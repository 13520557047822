import {
  Button,
  ButtonGroup,
} from "@chakra-ui/react";
import WarningIcon from "icons/WarningIcon";
import OutlineButton from "buttons/OutlineButton";
import ModalDialog from "components/modals/ModalDialog";

const ConfirmationModal = ({isOpen, onConfirm, onCancel, children, buttonText = 'Delete', buttonIcon}) => {
  return <ModalDialog title={'Warning'} icon={<WarningIcon/>} isOpen={isOpen} onClose={onCancel}
                      footer={<ButtonGroup>
                        <Button variant={'solidOrange'} leftIcon={buttonIcon} onClick={onConfirm}>{buttonText}</Button>
                        <OutlineButton _hover={{color: 'white', bg: 'primary.navy'}} copy={'Cancel'} variant={'outline'}
                                       txColor={'primary.navy'} onClick={onCancel}/>
                      </ButtonGroup>}>
    {children}
  </ModalDialog>
}

export default ConfirmationModal;
