import {colorToHex} from "app/helpers";

function EditIcon({color = 'navy', variant = 'outline', size = 24}) {
  if (variant === 'outline') {
    return (
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.15002 19H6.40002L15.65 9.75001L14.425 8.50001L5.15002 17.775V19ZM18.85 8.65001L15.475 5.30001L16.575 4.20001C16.925 3.86668 17.3544 3.70001 17.863 3.70001C18.371 3.70001 18.7917 3.86668 19.125 4.20001L20.15 5.22501C20.4334 5.52501 20.5834 5.87901 20.6 6.28701C20.6167 6.69568 20.475 7.04168 20.175 7.32501L18.85 8.65001ZM17.775 9.75001L7.02502 20.5H3.65002V17.125L14.4 6.37501L17.775 9.75001ZM15.025 9.12501L14.425 8.50001L15.65 9.75001L15.025 9.12501Z"
          fill={colorToHex(color)}/>
      </svg>
    );
  }

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.85 8.65001L15.475 5.30001L16.8 4.00001C17.0834 3.71668 17.4377 3.57501 17.863 3.57501C18.2877 3.57501 18.6417 3.71668 18.925 4.00001L20.15 5.22501C20.4334 5.52501 20.5834 5.87901 20.6 6.28701C20.6167 6.69568 20.475 7.04168 20.175 7.32501L18.85 8.65001ZM17.775 9.75001L7.02502 20.5H3.65002V17.125L14.4 6.37501L17.775 9.75001Z"
        fill={colorToHex(color)}/>
    </svg>
  );
}

export default EditIcon;
