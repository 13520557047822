import {cn} from "../../app/helpers";
import {Box} from "@chakra-ui/react";

export default function FilterWrapper({children}) {
  const classes = cn('filters');

  return <Box className={classes()}>
    {children}
  </Box>
}
