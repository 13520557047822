import { Heading, Flex, Hide, Box } from '@chakra-ui/react';
import FaqBlock from "../components/home/FaqBlock"

import Layout from "../components/layout/Layout";
import SavedContentBlock from "../components/home/SavedContentBlock";
import Block from "../components/layout/Block";
import CourseBlock from "../components/home/CourseBlock";
import {useCoursesData} from "features/course/courseHooks";
import Region from "../components/layout/Region";
import {useProfileData} from "features/profile/profileHooks";
import BackgroundSound from "components/media/BackgroundSound";
import ConsultantsBlock from '../components/home/ConsultantsBlock';
import ResumeCourseBlock from 'components/layout/ResumeCourseBlock';

export default function Main() {
  const [courses] = useCoursesData();
  const profile = useProfileData();

  const welcomeMessage = () => {
    if (profile?.data?.first_name) {
      const name = profile?.data?.first_name;
      if (profile?.data?.last_login) {
        return 'Welcome back, ' + name;
      }
      return 'Welcome, ' + name;
    }
    return 'Welcome to NCSU';
  }

  return (
    <Layout>
      <Block>
        <Flex flexFlow={'column'} rowGap={'24px'}>
          <Heading color={'primary.navy'}>{welcomeMessage()}</Heading>
          <Region id={'cta'} />
          <Hide below="lg">
            <ResumeCourseBlock />
          </Hide>
          { courses.map(course => course.id && <CourseBlock id={course.id} key={course.id}/>)}
          <SavedContentBlock />
          <Region id={'content_after'} />
        </Flex>
      </Block>
      <ConsultantsBlock />
      <FaqBlock />
      <BackgroundSound/>
      <Hide above="lg">
        <Box zIndex={'docked'} w={'100%'} mx={'auto'} position={'fixed'} left="0" bottom="60px">
          <ResumeCourseBlock full={false} />
        </Box>
      </Hide>
    </Layout>
  );
}
