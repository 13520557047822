import { Button } from '@chakra-ui/react'

const OutlineButton = ({ disabled, width, isLoading, colorScheme, variant, onClick, txColor, bgColor, name, copy, type, style, _hover}) => {
    return (
        <Button isDisabled={disabled} width={width} isLoading={isLoading} onClick={onClick} borderColor={txColor} variant={variant} type={type} fontFamily={"button"} colorScheme={colorScheme} borderRadius={'3xl'} _hover={_hover} name={name} color={txColor} bg={bgColor} style={style}>
            {copy}
        </Button>
    )
}

export default OutlineButton