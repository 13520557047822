import {useContext, useState} from 'react';
import {
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';
import ActivityWrapper from "components/course/Activity/ActivityWrapper";
import {ActivityContext} from "components/course/Activity/ActivityContext";

export default function Scale({data}) {
  const {onNext} = useContext(ActivityContext);
  const { content, answer } = data;
  const min = content?.content?.min || 0,
        max = content?.content?.max || 9;

  let defaultValue = parseInt(answer?.value);
  if (Number.isNaN(defaultValue) || defaultValue < min || defaultValue > max) {
    defaultValue = Math.ceil((min + max) / 2);
  }
  const [value, setValue] = useState(defaultValue);

  return (
    <ActivityContext.Provider value={{
      ...useContext(ActivityContext),
      onNext: () => {
        onNext(value);
      },
    }}>
      <ActivityWrapper title={content?.title} description={content?.description}>
        <Slider defaultValue={defaultValue} onChangeEnd={(value) => setValue(value)} min={min} max={max} mt={4}>
          <SliderMark value={min} color={'white'} fontSize={'0.875rem'} mt={5}>Low</SliderMark>
          <SliderMark value={max} color={'white'} fontSize={'0.875rem'} mt={5} transform={'translateX(-100%)'}>High</SliderMark>
          <SliderTrack h={4} borderRadius={'lg'}>
            <SliderFilledTrack bg={'secondary.tangerine'} />
          </SliderTrack>
          <SliderThumb bg={'secondary.red'} boxSize={6} />
        </Slider>
      </ActivityWrapper>
    </ActivityContext.Provider>
  )

}
