import {cn} from "app/helpers";
import PlayIcon from "icons/PlayIcon";
import PauseIcon from "icons/PauseIcon";
import ReplayIcon from "icons/ReplayIcon";

export default function PlayButton({isPlaying = false, isEnded = false, onPlay, onPause}) {
  const classes = cn('player-controls');
  const handleClick = (e) => {
    e.preventDefault();
    if (isPlaying) {
      onPause && onPause();
    } else {
      onPlay && onPlay();
    }
  }

  return <button className={classes('button', {'play': true})}
                 onClick={handleClick}>{isPlaying ? <PauseIcon/> : (isEnded ? <ReplayIcon/> : <PlayIcon/>)}</button>
}
