function ErrorIcon({fill = '#0C1851', size = 32}) {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0007 22.3002C16.3118 22.3002 16.5673 22.2002 16.7673 22.0002C16.9673 21.8002 17.0673 21.5446 17.0673 21.2335C17.0673 20.9224 16.9673 20.6668 16.7673 20.4668C16.5673 20.2668 16.3118 20.1668 16.0007 20.1668C15.6895 20.1668 15.434 20.2668 15.234 20.4668C15.034 20.6668 14.934 20.9224 14.934 21.2335C14.934 21.5446 15.034 21.8002 15.234 22.0002C15.434 22.2002 15.6895 22.3002 16.0007 22.3002ZM15.0007 17.4335H17.0007V9.4335H15.0007V17.4335ZM16.0007 28.6668C14.2451 28.6668 12.5953 28.3335 11.0513 27.6668C9.50643 27.0002 8.16732 26.1002 7.03398 24.9668C5.90065 23.8335 5.00065 22.4944 4.33398 20.9495C3.66732 19.4055 3.33398 17.7557 3.33398 16.0002C3.33398 14.2446 3.66732 12.5944 4.33398 11.0495C5.00065 9.5055 5.90065 8.16683 7.03398 7.0335C8.16732 5.90016 9.50643 5.00016 11.0513 4.3335C12.5953 3.66683 14.2451 3.3335 16.0007 3.3335C17.7562 3.3335 19.4064 3.66683 20.9513 4.3335C22.4953 5.00016 23.834 5.90016 24.9673 7.0335C26.1007 8.16683 27.0007 9.5055 27.6673 11.0495C28.334 12.5944 28.6673 14.2446 28.6673 16.0002C28.6673 17.7557 28.334 19.4055 27.6673 20.9495C27.0007 22.4944 26.1007 23.8335 24.9673 24.9668C23.834 26.1002 22.4953 27.0002 20.9513 27.6668C19.4064 28.3335 17.7562 28.6668 16.0007 28.6668ZM16.0007 26.6668C18.9784 26.6668 21.5006 25.6335 23.5673 23.5668C25.634 21.5002 26.6673 18.9779 26.6673 16.0002C26.6673 13.0224 25.634 10.5002 23.5673 8.4335C21.5006 6.36683 18.9784 5.3335 16.0007 5.3335C13.0229 5.3335 10.5007 6.36683 8.43398 8.4335C6.36732 10.5002 5.33398 13.0224 5.33398 16.0002C5.33398 18.9779 6.36732 21.5002 8.43398 23.5668C10.5007 25.6335 13.0229 26.6668 16.0007 26.6668Z"
        fill={fill}/>
    </svg>
  );
}

export default ErrorIcon;
