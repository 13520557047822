import {store} from "app/store";
import {fetchLikes} from "./likesSlice";

export default async function likesLoader() {
  const result = await store.dispatch(fetchLikes());
  return result.payload;
}

export async function savedContentLoader() {
  const result = await store.dispatch(fetchLikes({
    'entity_type': 'course_content',
    'bundle': 'course_segment',
  }));
  return result.payload;
}

export async function encouragementsLoader() {
  const result = await store.dispatch(fetchLikes({
    'entity_type': 'encouragement',
  }));
  return result.payload;
}
