import {
  ButtonGroup,
  Progress,
  useDisclosure,
} from "@chakra-ui/react";
import LockedIcon from '../../icons/LockedIcon'
import {useNavigate} from "react-router-dom";
import {useCourseContentData} from "features/course/courseHooks";
import {cn, formatPercentage} from "app/helpers";
import CompleteIcon from "icons/CompleteIcon";
import ReplayIcon from "icons/ReplayIcon";
import Button from "buttons/Button";
import ModalDialog from "components/modals/ModalDialog";

export default function ModuleCard({id}) {
  const classes = cn('module');
  const data = useCourseContentData(id);
  const {content, progress, locked} = data
  const {isOpen, onOpen, onClose} = useDisclosure()
  const navigate = useNavigate()

  const goToModule = () => {
    navigate(`/course/content/${id}`)
  }

  const goToSegment = () => {
    const segmentId = data?.children[0]?.id;
    segmentId && navigate(`/course/content/${segmentId}`);
  }

  const goToResume = () => {
    const activeCourse = data?.children?.find(course => course?.locked === false && course?.progress?.complete < 1);

    if (activeCourse) {
      navigate(`/course/content/${activeCourse?.id}`)
    } else {
      const segmentId = data?.children[0]?.id
      navigate(`/course/content/${segmentId}`)
    }
  }

  return (
    <div className={classes({
      card: true,
      locked,
      active: progress?.complete < 1 && !locked,
      loading: data?.isLoading,
    })}>
      <div className={classes('info')}>
        <div className={classes('title')}>
          {content?.title}
          {!locked && <div className={classes('status')}>
            {progress?.complete && progress.complete >= 1 ?
              <CompleteIcon color={'flame'} size={24}/> : formatPercentage(progress.complete) + '%'}
          </div>}
        </div>
        {content?.subtitle && <div className={classes('subtitle')}>
          {content?.subtitle}
        </div>}
      </div>

      <div className={classes('footer')}>
        {progress && <div className={classes('progress')}>
          <Progress boxShadow='inner' value={formatPercentage(progress.complete)} colorScheme={'orange'}
                    borderRadius={'24px'}/>
        </div>}

        <div className={classes('actions')}>
          {locked && <Button variant={'link'} leftIcon={<LockedIcon/>} onClick={onOpen}>Locked</Button>}

          {!locked && <>
            {progress?.complete > 0 && progress?.complete < 1 &&
            <Button onClick={goToResume} color={'orange'}>Resume</Button>}
            {progress?.complete <= 0 && <Button color={'orange'} onClick={goToSegment}>Get started</Button>}
            {progress?.complete >= 1 &&
            <Button onClick={goToSegment} variant={'outline'} leftIcon={<ReplayIcon/>}>Restart</Button>}
            <Button variant={'link'} onClick={goToModule}>View module</Button>
          </>}
        </div>
      </div>

      <ModalDialog title={'Locked'} icon={<LockedIcon/>} isOpen={isOpen} onOpen={onOpen} onClose={onClose} footer={
        <ButtonGroup spacing="0" flexWrap="wrap" gap={4} width="100%">
          <Button color={'orange'} onClick={onClose}>Resume Course</Button>
        </ButtonGroup>
      }>
        <p>All course content is only accessible in a sequential order. Please complete the previous course content
          first.</p>
      </ModalDialog>
    </div>
  )
}