import {useEffect, useState} from "react";
import VolumeIcon from "icons/VolumeIcon";
import MutedIcon from "icons/MutedIcon";
import {cn} from "app/helpers";

let timeout;
export default function Volume({volume = 100, isMuted = false, onChange}) {
  const classes = cn('player-controls');
  const steps = 100;
  const [isVisible, setVisible] = useState(false);
  const [overflow, setOverflow] = useState('hidden');

  const handleClick = (e) => {
    e.preventDefault();
    onChange && onChange(volume, !isMuted);
  };

  const handleChange = (event) => {
    const newVolume = event.target.value / steps;
    onChange && onChange(newVolume, newVolume <= 0);
  }

  const handleFocus = () => {
    clearTimeout(timeout);
    setVisible(true);
  }

  const handleBlur = () => {
    isVisible && (timeout = setTimeout(() => {
      setVisible(false);
    }, 400));
  }

  const makeColor = (color, fract) => {
    return color + ' ' + (fract * 100) + '%';
  }

  const gradient = [
    'to right',
    makeColor('#FFFFFF', 0),
    makeColor('#FFFFFF', isMuted ? 0 : volume),
    makeColor('#E2E2E2', isMuted ? 0 : volume),
    makeColor('#E2E2E2', 1),
  ];

  useEffect(() => {
    if (isVisible) {
      setOverflow('visible');
    } else {
      timeout = setTimeout(() => {
        setOverflow('hidden');
      }, 400);
    }
  }, [isVisible]);

  return <div className={classes('volume')}
              style={{
                overflow: overflow
              }}
              onMouseOver={handleFocus} onFocus={handleFocus}
              onMouseLeave={handleBlur} onBlur={handleBlur}>
    {/* @todo create separate component for range elements */}
    <div className={cn('range-container')({vertical: true})}>
    <input type="range" min={0} max={100} value={isMuted ? 0 : volume * steps} onChange={handleChange}
           style={{
             background: 'linear-gradient(' + gradient.join(',') + ')',
             opacity: isVisible ? 1 : 0,
           }}/>
  </div>
    <button className={classes('button')}
            onClick={handleClick}>{isMuted || volume <= 0 ? <MutedIcon/> : <VolumeIcon/>}</button>
  </div>
}
