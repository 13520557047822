import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleApiError } from 'features/auth/authSlice';
import webformService from './webformService';

// It's wrapped into thunk for consistency, for example in error handling.
// It doesn't have to be thunk though, as there is no real store slice for
// webforms.
export const submitWebform = createAsyncThunk('webform/submit', async (params, thunkAPI) => {
  try {
    return await webformService.submitWebform(params);
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
});
