import { useContext, useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import {ActivityContext} from "components/course/Activity/ActivityContext";
import { cn } from 'app/helpers';

const classes = cn('editable-table');

function EditableTableWithoutReference({ columnNames, rowsNumber, values, defaultValues, onInput}) {
  return (
    <Box className='parse-html'>
      <table>
        <thead>
          <tr>
            {columnNames.map((name, index) => {
              return <th key={index}>{name}</th>;
            })}
          </tr>
        </thead>
        <tbody>
        {Array.from({length: rowsNumber}).map((_, rowIndex) => (
          <tr key={rowIndex}>
            {columnNames.map((name, colIndex) => {
              return (
                <td
                  key={colIndex}
                  onInput={event => onInput(colIndex, rowIndex, event.target.textContent.trim())}
                  className={!values[`${colIndex}_${rowIndex}`] ? 'no-value' : ''}
                  contentEditable={true}
                  suppressContentEditableWarning={true}
                >
                  {/* Cell content shouldn't change between re-renders, that's why useMemo() hook is used. */}
                  {/* Truly controlled content will cause the cursor stay at the start on input. */}
                  {defaultValues[`${colIndex}_${rowIndex}`] || ''}
                </td>
              );
            })}
          </tr>
        ))}
        </tbody>
      </table>
    </Box>
  );
}

function EditableTableWithReference({ columnNames, rowsNumber, values, defaultValues, references, referenceName, referringName, onInput}) {
  const referenceValues = useMemo(() => {
    const values = columnNames.map(name => ({column: name, values: []}));

    references.forEach(reference => {
      const answerValues = reference.answer?.value || [];

      answerValues.forEach(answerItem => {
        // Supports both Editable Table and Open Question Multi answer structure.
        const name = answerItem.column || answerItem.item || '';
        const index = values.findIndex(column => column.column === name);
        if (index >= 0) {
          values[index].values = values[index].values.concat(answerItem.values || []);
        }
      })
    });

    return values;
  }, [columnNames, references]);

  return (
    <Box className='parse-html'>
      <table>
        <thead>
        <tr>
          <td></td>
          <th>{referenceName}</th>
          <th>{referringName}</th>
        </tr>
        </thead>
        <tbody>
        {columnNames.map((name, colIndex) => {
          // Appropriate element can always be found in referenceValues by name,
          // no checking for existence is needed here.
          const referenceColumnValues = referenceValues.find(value => value.column === name).values;
          const maxRows = Math.max(rowsNumber, referenceColumnValues.length);
          const isLastColumn = colIndex === (columnNames.length - 1);

          return (Array.from({ length: maxRows }).map((_, rowIndex) => (
            <tr key={`${colIndex}_${rowIndex}`}>
              {rowIndex === 0 && <th rowSpan={maxRows} className={classes('cell', {last: isLastColumn})}>{name}</th>}
              {(rowIndex < referenceColumnValues.length) && <td className={classes('cell')}>{referenceColumnValues[rowIndex]}</td>}
              {(rowIndex === referenceColumnValues.length) && <td rowSpan={maxRows - referenceColumnValues.length} className={classes('cell')}></td>}
              {(rowIndex < rowsNumber) && <td
                onInput={event => onInput(colIndex, rowIndex, event.target.textContent.trim())}
                className={classes('cell', {last: isLastColumn && rowIndex === (maxRows - 1)}, !values[`${colIndex}_${rowIndex}`] ? ['no-value'] : [])}
                contentEditable={true}
                suppressContentEditableWarning={true}
              >
                {/* Cell content shouldn't change between re-renders, that's why useMemo() hook is used. */}
                {/* Truly controlled content will cause the cursor stay at the start on input. */}
                {defaultValues[`${colIndex}_${rowIndex}`] || ''}
              </td>}
              {(rowIndex === rowsNumber) && <td rowSpan={maxRows - rowsNumber} className={classes('cell', {last: isLastColumn})}></td>}
            </tr>
          )));
        })}
        </tbody>
      </table>
    </Box>
  );
}

export default function EditableTable({ data }) {
  const { onNext } = useContext(ActivityContext);
  const { content } = data;
  const columnNames = content?.content?.column_names || [];
  const rowsNumber = content?.content?.rows_number || 1;

  const references = content?.content?.references || [];
  const referenceName = content?.content?.reference_name || '';
  const referringName = content?.content?.referring_name || '';

  const savedValues = data.answer?.value || [];
  const defaultValues = useMemo(() => {
    const defaultValues = {};

    columnNames.forEach((name, colIndex) => {
      const savedColumn = savedValues.find(item => item.column === name);
      if (!savedColumn) {
        return;
      }

      const savedAnswers = savedColumn.values || [];
      if (!savedAnswers.length) {
        return;
      }

      savedAnswers.slice(0, rowsNumber).forEach((_, rowIndex) => {
        if (savedAnswers[rowIndex]) {
          defaultValues[`${colIndex}_${rowIndex}`] = savedAnswers[rowIndex].trim();
        }
      });
    });

    return defaultValues;
  }, [data]);

  const [values, setValues] = useState(defaultValues);

  const handleInput = (colIndex, rowIndex, value) => {
    const key = `${colIndex}_${rowIndex}`;
    setValues(prevValues => ({...prevValues, [key]: value}));
  };

  const answers = columnNames.map((name, colIndex) => {
    return {
      column: name,
      values: Array.from({length: rowsNumber}).map((_, rowIndex) => values[`${colIndex}_${rowIndex}`] || ''),
    };
  });

  const isValid = answers.every(column => column.values.every(value => value !== ''));

  const handleSubmit = () => {
    onNext(answers);
  };

  // @todo Avoid the need to wrap tables into .parse-html to apply styles.
  return (
    <ActivityContext.Provider value={{
      ...useContext(ActivityContext),
      canSubmit: isValid,
      onNext: handleSubmit,
    }}>
      <ActivityWrapper
        title={content?.title}
        description={content?.description}
        classNames={references.length ? ['editable-table', 'editable-table--with-reference'] : ['editable-table']}
      >
        {references.length ?
          <EditableTableWithReference
            columnNames={columnNames}
            rowsNumber={rowsNumber}
            values={values}
            defaultValues={defaultValues}
            references={references}
            referenceName={referenceName}
            referringName={referringName}
            onInput={handleInput}
          /> :
          <EditableTableWithoutReference
            columnNames={columnNames}
            rowsNumber={rowsNumber}
            values={values}
            defaultValues={defaultValues}
            onInput={handleInput}
          />
        }
      </ActivityWrapper>
    </ActivityContext.Provider>
  );
}
