import {colorToHex} from "app/helpers";

function HeartIcon({color = 'navy', variant = 'solid', size = 24}) {
  if (variant === 'outline') {
    return (
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 20.325L10.9 19.325C9.23333 17.825 7.85833 16.5334 6.775 15.45C5.69167 14.3667 4.83333 13.404 4.2 12.562C3.56667 11.7207 3.125 10.954 2.875 10.262C2.625 9.57069 2.5 8.86669 2.5 8.15002C2.5 6.73336 2.97933 5.54569 3.938 4.58702C4.896 3.62902 6.08333 3.15002 7.5 3.15002C8.36667 3.15002 9.19167 3.35402 9.975 3.76202C10.7583 4.17069 11.4333 4.75836 12 5.52502C12.5667 4.75836 13.2417 4.17069 14.025 3.76202C14.8083 3.35402 15.6333 3.15002 16.5 3.15002C17.9167 3.15002 19.104 3.62902 20.062 4.58702C21.0207 5.54569 21.5 6.73336 21.5 8.15002C21.5 8.86669 21.375 9.57069 21.125 10.262C20.875 10.954 20.4333 11.7207 19.8 12.562C19.1667 13.404 18.3083 14.3667 17.225 15.45C16.1417 16.5334 14.7667 17.825 13.1 19.325L12 20.325ZM12 18.3C13.6 16.8667 14.9167 15.6334 15.95 14.6C16.9833 13.5667 17.8 12.6707 18.4 11.912C19 11.154 19.4167 10.4834 19.65 9.90002C19.8833 9.31669 20 8.73336 20 8.15002C20 7.15002 19.6667 6.31669 19 5.65002C18.3333 4.98336 17.5 4.65002 16.5 4.65002C15.7167 4.65002 14.9873 4.87502 14.312 5.32502C13.6373 5.77502 13.1083 6.40002 12.725 7.20002H11.275C10.875 6.38336 10.3417 5.75402 9.675 5.31202C9.00833 4.87069 8.28333 4.65002 7.5 4.65002C6.5 4.65002 5.66667 4.98336 5 5.65002C4.33333 6.31669 4 7.15002 4 8.15002C4 8.73336 4.11667 9.31669 4.35 9.90002C4.58333 10.4834 5 11.154 5.6 11.912C6.2 12.6707 7.01667 13.5624 8.05 14.587C9.08333 15.6124 10.4 16.85 12 18.3Z"
          fill={colorToHex(color)}/>
      </svg>
    );
  }

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 20.325L10.9 19.325C9.23333 17.825 7.85833 16.5334 6.775 15.45C5.69167 14.3667 4.83333 13.404 4.2 12.562C3.56667 11.7207 3.125 10.954 2.875 10.262C2.625 9.57069 2.5 8.86669 2.5 8.15002C2.5 6.73336 2.97933 5.54569 3.938 4.58702C4.896 3.62902 6.08333 3.15002 7.5 3.15002C8.36667 3.15002 9.19167 3.35402 9.975 3.76202C10.7583 4.17069 11.4333 4.75836 12 5.52502C12.5667 4.75836 13.2417 4.17069 14.025 3.76202C14.8083 3.35402 15.6333 3.15002 16.5 3.15002C17.9167 3.15002 19.104 3.62902 20.062 4.58702C21.0207 5.54569 21.5 6.73336 21.5 8.15002C21.5 8.86669 21.375 9.57069 21.125 10.262C20.875 10.954 20.4333 11.7207 19.8 12.562C19.1667 13.404 18.3083 14.3667 17.225 15.45C16.1417 16.5334 14.7667 17.825 13.1 19.325L12 20.325Z"
        fill={colorToHex(color)}/>
    </svg>
  )
}

export default HeartIcon;
