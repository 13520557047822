import {cn, fadeVolume} from "app/helpers";
import {useEffect, useState} from "react";
import MutedIcon from "icons/MutedIcon";
import VolumeIcon from "icons/VolumeIcon";

export default function BackgroundSound({volume = 0.5, muted = false}) {
  const classes = cn('background-sound');
  const [mediaRef, setMediaRef] = useState(null);
  const [isMuted, setMuted] = useState(muted);

  useEffect(() => {
    if (mediaRef) {
      if (mediaRef.paused) {
        mediaRef.play().catch(e => {
          if (e.name === "NotAllowedError") {
            setMuted(true);
          }
        });
      }

      const setVolume = (volume) => {
        mediaRef.volume = volume;
      };

      const from = isMuted ? mediaRef.volume : 0;
      const to = isMuted ? 0 : volume;
      mediaRef.muted = false;
      fadeVolume(setVolume, from, to).then(() => {
        mediaRef.muted = isMuted;
      });
    }
  }, [mediaRef, volume, isMuted]);

  const toggleMute = () => {
    setMuted(!isMuted);
  };

  return <div className={classes()}>
    <div className={classes('mute')} onClick={toggleMute}>
      {isMuted ? <MutedIcon size={40}/> : <VolumeIcon size={40}/>}
    </div>
    <audio ref={setMediaRef} src={'/sound/homepage.mp3'} loop={true}/>
  </div>
};
