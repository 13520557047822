import {
  Menu,
  MenuButton,
  Heading,
  Button,
  Flex,
  Box,
  Progress,
  MenuItem,
  Text,
  Spinner
} from "@chakra-ui/react";
import ChevronDown from "../../icons/ChevronDown";
import LockedIcon from "../../icons/LockedIcon";
import ProgressIcon from '../../icons/ProgressIcon'
import OutlineButton from "../../buttons/OutlineButton";
import {NavLink, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useCourseContentData, useCourseData} from "features/course/courseHooks";
import DropdownMenu from "components/menu/DropdownMenu";
import CompleteIcon from "icons/CompleteIcon";
import {cn} from "app/helpers";

const CourseContentIcon = ({data}) => {
  if (!data?.isSuccess) {
    return <Spinner width={'16px'} height={'16px'}/>
  }
  if (data?.locked) {
    return <LockedIcon size={24}/>
  }
  if (data?.progress?.complete >= 1) {
    return <CompleteIcon size={24} color={'flame'}/>
  }
  if (data?.progress?.complete >= 0 && data?.progress?.complete < 1) {
    return <ProgressIcon size={24} color={'navy'} variant={'outline'}/>
  }
}

const ModuleLinkWrapper = ({data, setSelectedModule, children, className}) => {
  if (data.locked) {
    return <Box className={className}>
      {children}
    </Box>
  }

  if (typeof setSelectedModule === 'function') {
    return <Box onClick={() => setSelectedModule(data.id)} className={className}>
      {children}
    </Box>
  }

  return <NavLink to={`/course/content/${data.id}`} key={data.id} className={className}>
    {children}
  </NavLink>
}

export const ModuleLink = ({contentId, setSelectedModule, selected}) => {
  const data = useCourseContentData(contentId);

  return <ModuleLinkWrapper data={data} setSelectedModule={setSelectedModule} className={'dropdown-menu__item'}>
    <MenuItem opacity={data.locked ? '40%' : '100%'} alignItems={'center'} borderRadius={'8px'} _hover={{bg: 'lightGreyThree.100'}} isDisabled={data.locked}>
      <Text>{data?.content?.title}</Text>
      {data.locked && <Box ml={'auto'}><LockedIcon size={24}/></Box>}
    </MenuItem>
  </ModuleLinkWrapper>
}

const ModuleChild = ({contentId, selected}) => {
  const classes = cn('module-nav');
  const data = useCourseContentData(contentId);

  const ModuleChildLink = ({to, children}) => {
    return to ?
      <NavLink to={to}>{children}</NavLink> :
      children;
  };

  return (
    <div className={classes('item')}>
      <ModuleChildLink to={data.locked ? false : `/course/content/${contentId}`}>
        <Flex opacity={data?.locked ? '40%' : '100%'} alignItems={'center'}>
          <Box className={classes('icon')} mr={2}>
            <CourseContentIcon data={data} />
          </Box>
          <Text color={'primary.navy'}>{data?.content?.title || '...'}</Text>
        </Flex>
      </ModuleChildLink>
    </div>
  )
}

export default function ModuleNav({courseId, moduleId, showButton, maxHeight = '500px'}) {
  const classes = cn('module-nav');
  const navigate = useNavigate();
  const courseData = useCourseData(courseId);

  if (courseData?.content && !moduleId) {
    const lastModule = courseData.content.findLast(child => !child.locked);
    if (lastModule) {
      moduleId = lastModule.id;
    }
  }
  const [selectedModule, setSelectedModule] = useState(moduleId);
  const moduleData = useCourseContentData(selectedModule);
  const allModules = courseData?.content || [];

  useEffect(() => {
    if (moduleId) {
      setSelectedModule(moduleId);
    }
  }, [moduleId])

  const goToModule = () => {
    if (moduleData?.children?.length > 0 && moduleData?.progress?.complete < 1) {
      const result = moduleData.children.find(activity => activity?.locked === false && activity?.progress?.complete < 1)
      navigate(`/course/content/${result.id}`)
    } else {
      navigate(`/course/content/${moduleData?.children[0]?.id}`)
    }

  }

  return (
    <div className={classes()}>
      <Menu height={'100%'} maxHeight={'600px'} pl={0} ml={0} mr={0}>
        <div className={classes('title')}>
          <MenuButton height={'6'} mx={0} ml={0} pl={0} color={'primary.navy'} _active={{bg: 'none'}} _hover={{bg: 'none'}}
                      mr={0} as={Button} bg={'none'} rightIcon={<ChevronDown/>}>
            <Box>
              {moduleData?.isSuccess && <Heading color={'primary.navy'} size={'md'}>{`${moduleData?.content?.title?.slice(0, 17)}`}</Heading>}
            </Box>
          </MenuButton>
        </div>
        {moduleData?.content?.subtitle && <Text className={classes('subtitle')}>{moduleData.content.subtitle}</Text>}
        <Progress className={classes('progress')} my={4} boxShadow='inner' colorScheme={'orange'} borderRadius={'24px'}
                  value={Math.ceil(moduleData?.progress?.complete * 100)}>{Math.ceil(moduleData?.progress?.complete * 100)}</Progress>
        <Flex className={classes('items')} overflowY={'auto'} maxHeight={maxHeight} gap={2} direction={'column'} mt={'24px'}>
          {moduleData?.children?.map((seg) => <ModuleChild key={seg?.id} contentId={seg?.id} />)}
        </Flex>
        {showButton && <Box mt={8}>
          {moduleData?.progress?.complete >= 1 ?
            <OutlineButton onClick={goToModule} _hover={{color: 'white', bg: 'primary.navy'}} copy={'Restart'} variant={'outline'}
                           txColor={'primary.navy'} bgColor={'transparent'}/>
            :
            <OutlineButton onClick={goToModule} copy={'Resume'} txColor={'white'}
                           bgColor={'primary.flame'}/>
          }
        </Box>}
        <DropdownMenu>
          {/* @todo add course selector */}
          {allModules && allModules.map((mod) => <ModuleLink key={mod.id} contentId={mod.id} setSelectedModule={setSelectedModule} selected={selectedModule} />)}
        </DropdownMenu>
      </Menu>
    </div>
  )


}