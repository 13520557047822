import {cn} from "app/helpers";
import {
  Button, IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay, useDisclosure
} from "@chakra-ui/react";
import CloseIcon from "icons/CloseIcon";

const ModalDialog = ({title, icon, footer, children, isOpen, onOpen, onClose, size = 'small'}) => {
  const classes = cn('modal');
  const modal = useDisclosure({
    isOpen,
    onOpen: onOpen,
    onClose: onClose,
  })

  return <Modal isOpen={modal.isOpen}
                onClose={modal.onClose}
                scrollBehavior="inside"
                motionPreset={'slideInBottom'}
  >
    <ModalOverlay />
    <ModalContent className={classes({size})}>
      <ModalHeader className={classes('header', {
        'no-title': !title,
      })}>
        {icon && <div className={classes('icon')}>{icon}</div>}
        {title && <div className={classes('title')}>{title}</div>}
        <ModalCloseButton className={classes('close')} as={IconButton} aria-label={'Close'} icon={<CloseIcon size={32} variant={'plain'}/>}/>
      </ModalHeader>
      <ModalBody className={classes('body')}>
        {children}
      </ModalBody>
      {footer && <ModalFooter className={classes('footer')}>
        {footer}
      </ModalFooter>}
    </ModalContent>
  </Modal>
}

export default ModalDialog;
