export const LoadBatch = class {
  constructor() {
    this.ids = [];
    this.loading = [];
    this.loaded = [];
    this.onLoading = {};
    this.onLoad = {};
  }

  add(id, params = {}) {
    if (!this.isBatched(id) && !this.isLoading(id)) {
      this.ids.push(id);
    }

    ['onLoading', 'onLoad'].forEach(callback => {
      if (typeof params[callback] === 'function') {
        this[callback][id] = this[callback][id] || [];
        this[callback][id].push(params[callback]);
      }
    });
  }

  next(maxItems = 20) {
    const ids = this.ids.splice(0, maxItems).sort((a, b) => a - b);
    ids.forEach(id => {
      if (!this.loading.includes(id)) {
        this.loading.push(id);
      }

      if (this.onLoading[id]) {
        let onLoading;
        while (onLoading = this.onLoading[id].shift()) {
          onLoading(id);
        }
      }
    });

    return ids;
  }

  isBatched(id) {
    return this.ids.includes(id);
  }

  isLoading(id) {
    return this.loading.includes(id);
  }

  isLoaded(id) {
    return this.loaded.includes(id);
  }

  load(ids) {
    ids.forEach(id => {
      const index = this.loading.indexOf(id);
      if (index > -1) {
        this.loading.splice(index, 1);
      }

      if (!this.loaded.includes(id)) {
        this.loaded.push(id);
      }

      if (this.onLoad[id]) {
        let onLoad;
        while (onLoad = this.onLoad[id].shift()) {
          onLoad(id);
        }
      }
    });
  }
}
