function ChevronUp() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="10"
        fill="none"
        viewBox="0 0 16 10"
      >
        <path
          fill="#2A2A2A"
          d="M.468 7.667L8.002.133l7.533 7.534-1.4 1.4-6.133-6.134-6.134 6.134-1.4-1.4z"
        ></path>
      </svg>
    );
  }

  export default ChevronUp
