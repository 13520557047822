import {
  Text, Button, Input
} from '@chakra-ui/react'
import moment from 'moment/moment'
import {useEffect, useState} from 'react'
import EditIcon from '../../icons/EditIcon'
import LocationIcon from '../../icons/LocationIcon'
import CalendarIcon from '../../icons/CalendarIcon'
import DeleteIcon from '../../icons/DeleteIcon'
import {useDispatch, useSelector} from 'react-redux'
import {cn} from "app/helpers";
import {useNoteData} from "features/notes/notesHooks";
import {useCourseContentData} from "features/course/courseHooks";
import {NavLink} from "react-router-dom";
import ConfirmationModal from "components/modals/ConfirmationModal";
import {deleteNotes, updateNote} from "features/notes/notesSlice";
import Textarea from "components/Textarea";
import {toast} from "react-toastify";
import ModalDialog from "components/modals/ModalDialog";

const NoteExpanded = ({id, editing = false, isOpen, onClose}) => {
  const dispatch = useDispatch();
  const classes = cn('note');
  const [data] = useNoteData(id);
  const [title, setTitle] = useState(data?.title || '');
  const [body, setBody] = useState(data?.body || '');
  const courseContent = useCourseContentData(data?.course_content?.id);
  const segmentData = useCourseContentData(courseContent?.segment?.id);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [isEditing, setEditing] = useState(editing);
  const [isProcessing, setProcessing] = useState(false);
  const hasChanges = data && (title !== data.title || body !== data.body);

  useEffect(() => {
    if (data) {
      setTitle(data.title || '');
      setBody(data.body || '');
    }
  }, [data]);

  useEffect(() => {
    setEditing(editing);
  }, [editing, isOpen]);

  const handleSave = () => {
    setProcessing(true);
    dispatch(updateNote({
      id,
      title,
      body,
    })).then(action => {
      setProcessing(false);
      if (action.meta.requestStatus === 'fulfilled') {
        setEditing(false);
        toast.success('Note saved.');
      } else {
        const message = action.payload.message;
        if (message) {
          toast.error(message);
        }
      }
    });
  };

  const handleEditing = (editing) => {
    if (!editing && hasChanges) {
      setShowCancelConfirm(true);
    }
    else {
      setEditing(editing);
    }
  }

  const handleDelete = () => {
    dispatch(deleteNotes([id]));
    setShowDeleteConfirm(false);
  }

  const handleInputChange = (event) => {
    const {name, value} = event.target;
    if (name === 'title') {
      setTitle(value);
    }
    else if (name === 'body') {
      setBody(value);
    }
  }

  return <ModalDialog title={isEditing ? <Input name={'title'} value={title} onChange={handleInputChange}/> : data.title} size={'xl'} isOpen={isOpen} onClose={() => {
    if (isEditing && hasChanges) {
      handleEditing(false);
    }
    else {
      onClose && onClose();
    }
  }} footer={
    <div className={classes('actions')}>
      {!isEditing && <Button variant={'transparent'} leftIcon={<EditIcon size={20} color={'navy'} variant={'outline'}/>}
                             onClick={() => handleEditing(true)}>Edit</Button>}
      {isEditing && <>
        <Button variant={'solidNavy'} isLoading={isProcessing} onClick={handleSave}>Save changes</Button>
        <Button variant={'outlineNavy'} isLoading={isProcessing} onClick={() => handleEditing(false)}>Cancel</Button>
      </>}
      <Button variant={'transparent'} leftIcon={<DeleteIcon size={20} color={'navy'} variant={'outline'}/>}
              onClick={() => setShowDeleteConfirm(true)}>Delete</Button>
    </div>
  }>
    <div className={classes({
      expanded: true,
    })}>
      <div className={classes('inner')}>
        <div className={classes('content')}>
          <div className={classes('body')}>
            {isEditing ?
              <Textarea onChange={handleInputChange} name={"body"} value={body} isRequired={true}
                        placeholder="Enter note"/> :
              data?.body.split("\n").filter(text => text.trim()).map((text, line) => <p key={line}>{text}</p>)}
          </div>
        </div>
        <div className={classes('meta')}>
          {segmentData.isSuccess && <div className={classes('meta-row')}>
            <div className={'meta-icon'}><LocationIcon size={16} variant={'outline'}/></div>
            <NavLink
              to={`/course/content/${courseContent.id}`}>{segmentData?.content?.title}</NavLink>
          </div>}
          <div className={classes('meta-row')}>
            <div className={'meta-icon'}><CalendarIcon size={16} variant={'outline'}/></div>
            {moment(data?.created.value).format("DD MMM YY")}
          </div>
        </div>
      </div>

      <ConfirmationModal isOpen={showDeleteConfirm} onConfirm={handleDelete} onCancel={() => {
        setShowDeleteConfirm(false)
      }} buttonText={'Delete'} buttonIcon={<DeleteIcon size={20} color={'white'} variant={'outline'}/>}>
        <Text>
          Are you sure you would like to delete this content? Once deleted it can not be recovered.
        </Text>
      </ConfirmationModal>

      <ConfirmationModal isOpen={showCancelConfirm} onConfirm={() => {
        setTitle(data?.title);
        setBody(data?.body);
        setShowCancelConfirm(false);
        setEditing(false);
      }} onCancel={() => {
        setShowCancelConfirm(false)
      }} buttonText={'Discard changed'}>
        <Text>
          Are you sure you would like to cancel editing? Your changes will not be saved.
        </Text>
      </ConfirmationModal>
    </div>
  </ModalDialog>
}

export default NoteExpanded;
