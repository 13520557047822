import {useEffect, useRef, useState, Fragment, useContext} from 'react';
import { useDrag, useDrop, useDragLayer } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import shuffle from 'just-shuffle';
import { throttle } from 'lodash/function';
import { isEqual } from 'lodash/lang';
import { cn } from 'app/helpers';
import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import { DndProvider } from './Drag';
import {ReactComponent as DraggableIcon} from 'icons/DraggableIcon.svg';
import BackArrow from 'icons/BackArrow';
import CompleteIcon from "icons/CompleteIcon";
import {playSound} from "app/sounds";
import {ActivityContext} from "components/course/Activity/ActivityContext";

const classes = cn('drag-to-reorder');

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

function DragLayer() {
  const isScrolling = useRef(false);

  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  function renderItem() {
    switch (itemType) {
      case 'item':
        return <Item label={item.label} />
      default:
        return null
    }
  }

  const goDown = () => {
    window.scrollBy({
      top: 6,
      behavior: 'instant',
    });

    const { scrollTop, scrollHeight } = document.documentElement;
    const isScrollEnd = window.innerHeight + scrollTop >= scrollHeight;

    if (isScrolling.current && !isScrollEnd) {
      window.requestAnimationFrame(goDown);
    }
  };

  const goUp = () => {
    window.scrollBy({
      top: -6,
      behavior: 'instant',
    });

    if (isScrolling.current && window.scrollY > 0) {
      window.requestAnimationFrame(goUp);
    }
  };

  const scrollOnDrag = (top, bottom) => {
    const isMouseOnTop = top <= 0;
    const isMouseOnBottom = window.innerHeight - bottom <= 0;

    if (!isScrolling.current && (isMouseOnTop || isMouseOnBottom)) {
      isScrolling.current = true;

      if (isMouseOnTop) {
        window.requestAnimationFrame(goUp);
      }

      if (isMouseOnBottom) {
        window.requestAnimationFrame(goDown);
      }
    }
    else if (!isMouseOnTop && !isMouseOnBottom) {
      isScrolling.current = false;
    }
  };

  const throttled = throttle(scrollOnDrag);

  useEffect(() => {
    if (isDragging && currentOffset) {
      throttled(currentOffset.y, currentOffset.y + item.height);
    }
  });

  if (!isDragging) {
    if (isScrolling.current) {
      isScrolling.current = false;
    }
    return null;
  }

  const style = {
    width: `${item.width}px`,
    transform: `translate(${currentOffset?.x || 0 }px,${currentOffset?.y || 0 }px)`,
  };
  return (
    <div style={layerStyles}>
      <div style={style}>
        {renderItem()}
      </div>
    </div>
  )
}

function Item({label, isCorrect = false}) {
  return (
    <div className={classes('option', { correct: isCorrect })}>
      {label}
      <div className={classes('option-icon')}>
        {isCorrect && <CompleteIcon size={22} color={'navy'} />}
        {!isCorrect && <DraggableIcon />}
      </div>
    </div>
  );
}

export const DraggableItem = ({ label, index, onDrop }) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'item',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item) {
      const dragIndex = item.index;
      const dropIndex = index;
      onDrop(dragIndex, dropIndex);
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    type: 'item',
    item: () => {
      return {
        index,
        label,
        width: ref.current?.getBoundingClientRect().width,
        height: ref.current?.getBoundingClientRect().height,
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, []);

  // Make the element draggable and drop target at the same time.
  drag(drop(ref));

  return (
    <div
      style={{opacity: isDragging ? 0 : 1}}
      ref={ref}
      data-handler-id={handlerId}
    >
      <Item label={label} />
    </div>
  );
}

export default function DragToReorder({data}) {
  const {content} = data;
  const optionsOriginal = content?.content?.options || [];

  // Shuffle options in the way no option is in its original position.
  const [options, setOptions] = useState(shuffle(optionsOriginal, {shuffleAll: true}));
  const [correct, setCorrect] = useState([]);

  const isValid = correct.length === options.length;

  const handleDrop = (sourceIndex, targetIndex) => {
    const newOptions = Array.from(options);
    // Swap 2 options drop target and source are not the same element.
    if (targetIndex !== sourceIndex) {
      [newOptions[sourceIndex], newOptions[targetIndex]] = [newOptions[targetIndex], newOptions[sourceIndex]];
      setOptions(newOptions);
    }

    // If option is moved to correct position and all other stay in correct
    // position too (no matter if they are touched or not), mark all as correct.
    // Works well with initial shuffle.
    if (options[sourceIndex] === optionsOriginal[targetIndex] && !correct.includes(options[sourceIndex])) {
      const newCorrect = isEqual(newOptions, optionsOriginal)
        ? [...options]
        : [...correct, options[sourceIndex]]
      if (newCorrect.length === options.length) {
        playSound('complete');
      }
      else {
        playSound('correct');
      }
      setCorrect(newCorrect);
    }
    else {
      playSound('incorrect');
    }
  };

  const Aside = (
    <div className={classes()}>
      {options.map((label, index) => {
        const isCorrect = correct.includes(label);
        return (
          <Fragment key={index}>
            {!isCorrect && <DraggableItem label={label} index={index} onDrop={handleDrop} />}
            {isCorrect && <Item label={label} isCorrect={isCorrect} /> }
            {index !== (options.length - 1) && <div className={classes('option-arrow')}>
              <BackArrow color="#fff" />
            </div>}
          </Fragment>
        );
      })}
    </div>
  );

  const {onNext} = useContext(ActivityContext);
  const handleSubmit = () => {
    // Submit reordered options to the backend.
    onNext(options);
  };


  // ActivityWrappers has z-index set via CSS and draggable items rendered under
  // buttons (if dragged over them). So, DragLayer should be placed outside of
  // ActivityWrapper to solve this.
  // @todo Is z-index on ActivityWrapper really needed? If no, move DragLayer closer to draggable items i.e. to Aside.
  return (
    <ActivityContext.Provider value={{
      ...useContext(ActivityContext),
      canSubmit: isValid,
      onNext: handleSubmit,
    }}>
      <DndProvider>
        <DragLayer/>
        <ActivityWrapper title={content?.title} description={content?.description} aside={Aside}>
          <div className={classes('message', {hidden: !isValid})}>
            <CompleteIcon size={22} color={'tangerine'} />
            <div>All Answers Correct</div>
          </div>
        </ActivityWrapper>
      </DndProvider>
    </ActivityContext.Provider>
  );
}
