function UploadIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          fill="#0C1851"
          d="M2.3 15.5c-.5 0-.925-.176-1.275-.526A1.736 1.736 0 01.5 13.7V11H2v2.7c0 .067.033.134.1.2.067.067.133.1.2.1h11.4c.067 0 .133-.033.2-.1.067-.066.1-.133.1-.2V11h1.5v2.7c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H2.3zm4.95-3.876v-8.4l-2.475 2.45L3.725 4.6 8 .324 12.275 4.6l-1.05 1.075-2.475-2.45v8.4h-1.5z"
        ></path>
      </svg>
    );
  }
  
  export default UploadIcon;