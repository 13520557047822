import { IconButton } from "@chakra-ui/react";

// @todo Combine with the NextButton - they're almost identical.
export default function PreviousButton({ isLoading, onClick }) {
  return (
    <IconButton isLoading={isLoading} onClick={onClick} aria-label="Back" width={6} borderRadius={'3xl'}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.9996 17.65L8.34961 12L13.9996 6.34998L15.0496 7.39998L10.4496 12L15.0496 16.6L13.9996 17.65Z"
              fill="#0C1851"/>
      </svg>
    </IconButton>
  );
}
