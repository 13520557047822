import {createSlice, createAsyncThunk, createEntityAdapter} from "@reduxjs/toolkit"
import notesService from "./notesService"
import {handleApiError} from "../auth/authSlice";

const notesAdapter = createEntityAdapter({
  sortComparer: (a, b) => {
    const dateA = new Date(a.created?.value);
    const dateB = new Date(b.created?.value);
    return dateA === dateB ? 0 : (dateA > dateB ? -1 : 1);
  },
});
export const notesSelector = notesAdapter.getSelectors(state => state.notes);

export const fetchNotes = createAsyncThunk('resources/fetchNotes', async (params, thunkAPI) => {
  try {
    return await notesService.getNotes(params);
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
});

export const addNote = createAsyncThunk('notes/addNote', async ( params, thunkAPI) => {
  try {
    return await notesService.addNote(params);
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

export const updateNote = createAsyncThunk('notes/updateNote', async ( params, thunkAPI) => {
  try {
    return await notesService.updateNote(params);
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

export const deleteNotes = createAsyncThunk('notes/deleteNotes', async (ids, thunkAPI) => {
  try {
    return await notesService.deleteNotes(ids)
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

export const downloadNotes = createAsyncThunk('notes/downloadNotes', async ( ids, thunkAPI) => {
  try {
    return await notesService.downloadNotes(ids)
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

const notesSlice = createSlice({
  name: 'notes',
  initialState: notesAdapter.getInitialState({
    isLoading: false,
    isSuccess: false,
    isError: false,
  }),
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotes.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchNotes.fulfilled, (state, action) => {
        notesAdapter.setMany(state, action.payload.items);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(fetchNotes.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
      .addCase(addNote.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addNote.fulfilled, (state, action) => {
        notesAdapter.addOne(state, action.payload);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(addNote.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
      .addCase(updateNote.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateNote.fulfilled, (state, action) => {
        notesAdapter.upsertOne(state, action.payload);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(updateNote.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
      .addCase(deleteNotes.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteNotes.fulfilled, (state, action) => {
        const result = action.payload;
        const ids = [];
        for (let noteId in result) {
          if (result[noteId] === 'true') {
            ids.push(noteId);
          }
        }
        notesAdapter.removeMany(state, ids);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(deleteNotes.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
  },
});

export default notesSlice.reducer;

/*const initialState = {
    data: {},
    notesIsError: false,
    notesIsSuccess: false,
    notesIsLoading: false,
    notesMessage: '',
    noteIsError: false,
    noteIsSuccess: false,
    noteIsLoading: false,
    deleteNotesIsError: false,
    deleteNotesIsSuccess: false,
    deleteNotesIsLoading: false
}

export const getNotes = createAsyncThunk('notes/getNotes', async (arg, thunkAPI) => {
    try {
        return await notesService.getNotes()
    } catch (error) {
        return handleApiError(thunkAPI, error);
    }
})

export const newNote = createAsyncThunk('notes/newNote', async ( value, thunkAPI) => {
    try {
        return await notesService.newNote(value)
    } catch (error) {
        return handleApiError(thunkAPI, error);
    }
})


export const editNote = createAsyncThunk('notes/editNote', async ( value, thunkAPI) => {
    try {
        return await notesService.editNote(value)
    } catch (error) {
        return handleApiError(thunkAPI, error);
    }
})

export const deleteNotes = createAsyncThunk('notes/deleteNotes', async ( value, thunkAPI) => {
    try {
        return await notesService.deleteNotes(value)
    } catch (error) {
        return handleApiError(thunkAPI, error);
    }
})

export const downloadNotes = createAsyncThunk('notes/downloadNotes', async ( value, thunkAPI) => {
    try {
        return await notesService.downloadNotes(value)
    } catch (error) {
        return handleApiError(thunkAPI, error);
    }
})



export const notesSlice = createSlice ({
    name: 'notes',
    initialState,
    reducers: {
        resetNotes: (state) => {
            state.data = {}
            state.notesIsLoading = false
            state.notesIsSuccess = false
            state.notesIsError = false
            state.notesMessage = ''
            state.noteIsError = false
            state.noteIsSuccess = false
            state.noteIsLoading = false
            state.deleteNotesIsError =  false
            state.deleteNotesIsSuccess = false
            state.deleteNotesIsLoading = false
        },
        resetNote: (state) => {
            state.noteIsError = false
            state.noteIsSuccess = false
            state.noteIsLoading = false
            state.notesIsLoading = false
        },
        resetDeleteNotes: (state) => {
            state.deleteNotesIsError =  false
            state.deleteNotesIsSuccess = false
            state.deleteNotesIsLoading = false
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getNotes.pending, (state) => {
            state.notesIsLoading = true
        })
        .addCase(getNotes.fulfilled, (state, action) => {
        state.notesIsLoading = false
        // state.notesIsSuccess = true
        state.data = action.payload
        state.notesIsError = false
        state.notesMessage = ''
        state.deleteNotesIsError = false
        state.deleteNotesIsLoading = false
        state.deleteNotesIsSuccess = false
        })
        .addCase(getNotes.rejected, (state, action) => {
        state.notesIsLoading = false
        state.notesIsError = true
        state.notesMessage = action.payload.message
        })
        .addCase(editNote.pending, (state) => {
            state.noteIsLoading = true
        })
        .addCase(editNote.fulfilled, (state, action) => {
        state.noteIsLoading = false
        state.noteIsSuccess = true
        state.noteIsError = false
        state.notesMessage = 'Note updated'
        const index = state.data.items.findIndex(item => item.id === action.payload.id)
        state.data.items[index] = action.payload
        
        })
        .addCase(editNote.rejected, (state, action) => {
        state.noteIsLoading = false
        state.noteIsError = true
        state.notesMessage = action.payload.message
        })
        .addCase(deleteNotes.pending, (state) => {
            state.deleteNotesIsLoading = true
        })
        .addCase(deleteNotes.fulfilled, (state, action) => {
        state.deleteNotesIsLoading = false
        state.deleteNotesIsSuccess = true
        state.deleteNotesIsError = false
        state.notesMessage = 'Successfully deleted'        
        })
        .addCase(deleteNotes.rejected, (state, action) => {
        state.deleteNotesIsLoading = false
        state.deleteNotesIsError = true
        state.notesMessage = action.payload.message
        })
        .addCase(newNote.pending, (state) => {
            state.notesIsLoading = true
        })
        .addCase(newNote.fulfilled, (state) => {
            state.noteIsLoading = false
            state.notesIsError = false
            state.notesIsSuccess = true
            state.notesMessage = "Note saved"
        })
        .addCase(newNote.rejected, (state, action) => {
            state.noteIsLoading = false
            state.notesIsError = true
            state.notesMessage = action.payload.message
        })

    }
})

export const { resetNotes, resetNote, resetDeleteNotes } = notesSlice.actions
export default notesSlice.reducer
*/