import { NavLink } from "react-router-dom";

 
import { Grid, GridItem, VStack, Text, Box, Show } from '@chakra-ui/react'

import Home from "../../icons/Home"
import Notification from "../../icons/Notification";
import Courses from "../../icons/Courses";
import Profile from "../../icons/Profile";

export default function BottomNavMobile({children}) {
    return (
      <Box className={'footer--mobile'} zIndex={'docked'} bg={'white'} w={'100%'} mx={'auto'} py={2} pb={2} position={'fixed'} bottom="0">
          <Grid fontSize={'xs'} templateColumns='repeat(4, 1fr)' gap={3}>

              <GridItem>
                  <NavLink to={"/"}>
                      {({ isActive }) => (
                        <VStack >
                            <Box mb={'-5px'}>
                                <Home color={({isActive})} />
                            </Box>
                            <Text color={isActive? "#EE5622" : "#0C1851"}>Home</Text>
                        </VStack>
                      )}
                  </NavLink>
              </GridItem>
              <GridItem>
                  <NavLink to={"/course"}>
                      {({ isActive }) => (
                        <VStack>
                            <Box mb={'-5px'}>
                                <Courses color={({isActive})} />
                            </Box>
                            <Text color={isActive? "#EE5622" : "#0C1851"}>Courses</Text>
                        </VStack>
                      )}
                  </NavLink>
              </GridItem>
              {/* <GridItem>
                        <NavLink to={"/resources"}>
                            {({ isActive }) => (
                                <VStack>
                                    <Box mb={'-5px'}>
                                        <Resources color={({isActive})} />
                                    </Box>
                                    <Text color={isActive? "#EE5622" : "#0C1851"}>Resources</Text>
                                </VStack>
                            )}
                        </NavLink>
                    </GridItem> */}
              <GridItem>
                  <NavLink to={"/notification"}>
                      {({ isActive }) => (
                        <VStack>
                            <Box mb={'-5px'}>
                                <Notification color={({isActive})} />
                            </Box>
                            <Text color={isActive? "#EE5622" : "#0C1851"}>Notification</Text>
                        </VStack>
                      )}
                  </NavLink>
              </GridItem>
              <GridItem>
                  <NavLink to={"/profile"}>
                      {({ isActive }) => (
                        <VStack>
                            <Box mb={'-5px'}>
                                <Profile color={({isActive})} />
                            </Box>
                            <Text color={isActive? "#EE5622" : "#0C1851"}>Profile</Text>
                        </VStack>
                      )}
                  </NavLink>
              </GridItem>

          </Grid>
      </Box>
    );
}
