import { Grid, GridItem, Box, Hide } from '@chakra-ui/react'
import BottomNavMobile from './BottomNavMobile';
import LeftNavDesktop from './LeftNavDesktop';
import ProfilePanel from './ProfilePanel';
import TopNavMobile from './TopNavMobile';
import {useSelector} from "react-redux";
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ModuleNavBlock from "./ModuleNavBlock";

export default function Layout({children, headerInfo}) {
    const {user} = useSelector((state) => state.auth)

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Box w={'100%'} minHeight={'100vh'} h={'100%'} pb={{base: '100px', lg: 0}}>
            <div className={'layout-container'}>
                <div className={'region region--header-mobile'}>
                    <TopNavMobile headerInfo={headerInfo} />
                </div>
                <Hide below='lg'>
                    <div className={'region region--sidebar-left'}><LeftNavDesktop/></div>
                </Hide>
                <div className={'region region--main'}>
                    {children}
                </div>
                <Hide below='lg'>
                    <div className={'region region--sidebar-right'}>
                        {user && <ProfilePanel />}
                        <ModuleNavBlock />
                    </div>
                </Hide>
                <BottomNavMobile colSpan={12} />
            </div>
        </Box>
       
    );
}