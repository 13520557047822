import {useCallback, useEffect, useId, useState} from "react";
import Controls from "./Controls/Controls";
import {cn} from "app/helpers";
import "styles/components/media/_audio-player.scss";
import authService from "features/auth/authService";
import {useSelector} from "react-redux";
import {getCanPlay, getMediaUserSettings} from "features/media/mediaSlice";

export default function AudioPlayer({src, captionsSrc, autoPlay, onComplete, toggleFullscreen, isMain = true}) {
  const playerId = useId();
  const classes = cn('audio-player');
  const userSettings = useSelector(state => getMediaUserSettings(state));
  const [media, setMedia] = useState(null);
  autoPlay = autoPlay && userSettings.autoPlay;
  const canPlay = useSelector(state => getCanPlay(state, playerId, {
    isMain,
    autoPlay,
  }));
  const [fileUrl, setFileUrl] = useState();
  const controlsSettings = {
    allowAutoPlay: autoPlay,
    allowFullscreen: typeof toggleFullscreen === 'function',
    toggleFullscreen: toggleFullscreen,
  };

  const handleComplete = useCallback((event) => {
    onComplete && onComplete();
  }, [onComplete]);

  useEffect(() => {
    if (media) {
      media.volume = userSettings.volume;
      media.addEventListener('ended', handleComplete);
    }

    return () => {
      if (media) {
        media.removeEventListener('ended', handleComplete);
      }
    }
  }, [media, handleComplete, userSettings.volume]);

  useEffect(() => {
    setFileUrl(null);
    authService.getClient().request({
      url: src,
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        'Accept': '*/*',
      },
    }).then(response => {
      const file = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const url = URL.createObjectURL(file);
      setFileUrl(url);
    }).catch(error => {
      console.log(error);
    });
  }, [src]);

  useEffect(() => {
    if (media) {
      if (canPlay) {
        if (media.paused && autoPlay) {
          media.play();
        }
        else if (!media.paused && !autoPlay) {
          media.pause();
        }
      }
      else if (!media.paused && autoPlay) {
        media.pause();
      }
    }
  }, [autoPlay, canPlay, media]);

  return <div className={classes()}>
    <video src={fileUrl} autoPlay={canPlay && autoPlay} controls={false} loop={false} ref={setMedia} width={0} playsInline muted={userSettings.muted}/>
    <Controls media={media} {...controlsSettings}/>
  </div>
}
