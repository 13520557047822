import {cn} from "app/helpers";
import {MenuList} from "@chakra-ui/react";

export default function DropdownMenu({children}) {
  const classes = cn('dropdown-menu');

  return <MenuList className={classes()} zIndex={9}>
    {children}
  </MenuList>
}
