import {colorToHex} from "app/helpers";

function ExtensionIcon({color = 'navy', variant = 'solid', size = 24}) {
  if (variant === 'outline') {
    return (
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.875 20.5H5C4.58333 20.5 4.22933 20.354 3.938 20.062C3.646 19.7707 3.5 19.4167 3.5 19V15.125C4.18333 15.0417 4.771 14.7584 5.263 14.275C5.75433 13.7917 6 13.2 6 12.5C6 11.8 5.75433 11.2084 5.263 10.725C4.771 10.2417 4.18333 9.95838 3.5 9.87505V6.00005C3.5 5.58338 3.646 5.22938 3.938 4.93805C4.22933 4.64605 4.58333 4.50005 5 4.50005H9C9.08333 3.86672 9.35833 3.34172 9.825 2.92505C10.2917 2.50838 10.85 2.30005 11.5 2.30005C12.15 2.30005 12.7083 2.50838 13.175 2.92505C13.6417 3.34172 13.9167 3.86672 14 4.50005H18C18.4167 4.50005 18.7707 4.64605 19.062 4.93805C19.354 5.22938 19.5 5.58338 19.5 6.00005V10C20.1333 10.0834 20.6583 10.3584 21.075 10.825C21.4917 11.2917 21.7 11.85 21.7 12.5C21.7 13.15 21.4917 13.7084 21.075 14.175C20.6583 14.6417 20.1333 14.9167 19.5 15V19C19.5 19.4167 19.354 19.7707 19.062 20.062C18.7707 20.354 18.4167 20.5 18 20.5H14.125C14.0417 19.7834 13.75 19.1874 13.25 18.712C12.75 18.2374 12.1667 18 11.5 18C10.8333 18 10.25 18.2374 9.75 18.712C9.25 19.1874 8.95833 19.7834 8.875 20.5ZM5 19H7.7C8.05 18.1167 8.596 17.479 9.338 17.087C10.0793 16.6957 10.8 16.5 11.5 16.5C12.2 16.5 12.921 16.6957 13.663 17.087C14.4043 17.479 14.95 18.1167 15.3 19H18V13.55H19.225C19.5417 13.5167 19.7833 13.396 19.95 13.188C20.1167 12.9794 20.2 12.75 20.2 12.5C20.2 12.25 20.1167 12.0207 19.95 11.812C19.7833 11.604 19.5417 11.4834 19.225 11.45H18V6.00005H12.55V4.77505C12.5167 4.45838 12.396 4.21672 12.188 4.05005C11.9793 3.88338 11.75 3.80005 11.5 3.80005C11.25 3.80005 11.0207 3.88338 10.812 4.05005C10.604 4.21672 10.4833 4.45838 10.45 4.77505V6.00005H5V8.75005C5.76667 9.05005 6.375 9.54172 6.825 10.225C7.275 10.9084 7.5 11.6667 7.5 12.5C7.5 13.3167 7.275 14.0667 6.825 14.75C6.375 15.4334 5.76667 15.9334 5 16.25V19Z"
          fill={colorToHex(color)}/>
      </svg>
    );
  }

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.875 20.5H5C4.58333 20.5 4.22933 20.354 3.938 20.062C3.646 19.7707 3.5 19.4167 3.5 19V15.125C4.18333 15.0417 4.771 14.7584 5.263 14.275C5.75433 13.7917 6 13.2 6 12.5C6 11.8 5.75433 11.2084 5.263 10.725C4.771 10.2417 4.18333 9.95838 3.5 9.87505V6.00005C3.5 5.58338 3.646 5.22938 3.938 4.93805C4.22933 4.64605 4.58333 4.50005 5 4.50005H9C9.08333 3.86672 9.35833 3.34172 9.825 2.92505C10.2917 2.50838 10.85 2.30005 11.5 2.30005C12.15 2.30005 12.7083 2.50838 13.175 2.92505C13.6417 3.34172 13.9167 3.86672 14 4.50005H18C18.4167 4.50005 18.7707 4.64605 19.062 4.93805C19.354 5.22938 19.5 5.58338 19.5 6.00005V10C20.1333 10.0834 20.6583 10.3584 21.075 10.825C21.4917 11.2917 21.7 11.85 21.7 12.5C21.7 13.15 21.4917 13.7084 21.075 14.175C20.6583 14.6417 20.1333 14.9167 19.5 15V19C19.5 19.4167 19.354 19.7707 19.062 20.062C18.7707 20.354 18.4167 20.5 18 20.5H14.125C14.0417 19.7834 13.75 19.1874 13.25 18.712C12.75 18.2374 12.1667 18 11.5 18C10.8333 18 10.25 18.2374 9.75 18.712C9.25 19.1874 8.95833 19.7834 8.875 20.5Z"
        fill={colorToHex(color)}/>
    </svg>
  );
}

export default ExtensionIcon;
