import Block from "../layout/Block";
import CourseProgress from "./CourseProgress";
import {Box} from "@chakra-ui/react";
import {cn} from "app/helpers";
import ModuleNav from "../course/ModuleNav";
import {useCourseData} from "features/course/courseHooks";

export default function CourseBlock({id}) {
  const classes = cn('homepage-course-block');
  const course = useCourseData(id);

  return course && !course.locked && <Block className={classes()} title={course.title}>
    <Box className={classes('widget', {'navigation': true})}>
      <ModuleNav courseId={course.id} maxHeight={'205px'}/>
    </Box>
    <Box className={classes('widget', {'progress': true})}>
      <CourseProgress courseId={course.id} />
    </Box>
  </Block>
}
