import { createSlice, createAsyncThunk } from "@reduxjs/toolkit" 
import generalService from "./generalService"
import {handleApiError} from "../auth/authSlice";

const initialState = {
    faqs: {},
    promotedFaqs: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

export const getFaqs = createAsyncThunk('general/getFaqs', async (arg, thunkAPI) => {
    try {
        return await generalService.getFaqs()
    } catch (error) {
        return handleApiError(thunkAPI, error);
    }
})

export const getPromotedFaqs = createAsyncThunk('general/getPromotedFaqs', async (arg, thunkAPI) => {
    try {
        return await generalService.getPromotedFaqs()
    } catch (error) {
        return handleApiError(thunkAPI, error);
    }
})

// @todo Rename to faqSlice.
export const generalSlice = createSlice ({
    name: 'general',
    initialState,
    reducers: {
        reset: (state) => {
           
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getFaqs.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getFaqs.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.faqs = action.payload
        state.isError = false
        state.message = ''
        })
        .addCase(getFaqs.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload.message
        })
        .addCase(getPromotedFaqs.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.promotedFaqs = action.payload
            state.isError = false
            state.message = ''
        })
        .addCase(getPromotedFaqs.rejected, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.promotedFaqs = {}
            state.isError = true
            state.message = action.payload.message
        })

    }
})

export const { reset } = generalSlice.actions
export default generalSlice.reducer