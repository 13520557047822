import { defineStyleConfig } from '@chakra-ui/react'

const baseStyle = {
  background: 'washedOut.orange',
  borderRadius: '2xl',
  color: 'primary.navy',
  fontWeight: 'semibold',
  py: 2,
  px: 4,
}

// export the component theme
export const tooltipTheme = defineStyleConfig({ baseStyle })
