import {useDispatch} from "react-redux";
import {addLike, removeLike} from "features/like/likesSlice";
import {
  IconButton, Text
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {cn} from "app/helpers";
import {useLikeData} from "features/like/likesHooks";
import BookmarkIcon from "icons/BookmarkIcon";
import HeartIcon from "icons/HeartIcon";
import ConfirmationModal from "components/modals/ConfirmationModal";

export default function Like({entityType, entityId, variant = 'heart', confirmationMessage, confirmationButton}) {
  const classes = cn('like');
  const dispatch = useDispatch();
  const [data, isLoading] = useLikeData(entityType, entityId);
  const [processing, setProcessing] = useState(false);
  const [liked, setLiked] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    setLiked(!!data);
  }, [data]);

  const handleLike = () => {
    if (!processing) {
      setLiked(true);
      setClicked(true);
      setProcessing(true);

      dispatch(addLike({
        'entity_type': entityType,
        'entity_id': entityId,
      })).then(result => {
        setProcessing(false);
        if (result?.meta?.requestStatus === 'rejected') {
          setLiked(false);
        }
      });
    }
  }

  const handleUnLike = () => {
    if (!processing) {
      setLiked(false);
      setClicked(true);
      setProcessing(true);
      setShowConfirm(false);

      setTimeout(() => {
        dispatch(removeLike(data)).then(result => {
          setProcessing(false);
          if (result?.meta?.requestStatus === 'rejected') {
            setLiked(true);
          }
        });
      }, 500);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (liked) {
      confirmationMessage ? setShowConfirm(true) : handleUnLike();
    }
    else {
      handleLike();
    }
  };

  let icon;
  switch (variant) {
    case 'bookmark':
      icon = <BookmarkIcon size={24} color={liked ? 'flame' : 'navy'} variant={liked ? 'solid' : 'outline'}/>
      break;
    default:
      icon = <HeartIcon size={24} color={liked ? 'red' : 'navy'} variant={liked ? 'solid' : 'outline'}/>
      break;
  }

  return <div className={classes({
    liked,
    clicked,
    processing,
    variant,
  })}>
    <IconButton onAnimationEnd={() => setClicked(false)}
                className={classes('button')} aria-label={'Save'}
                icon={icon}
                variant={'transparent'} size={'24px'} onClick={handleClick}
                isDisabled={isLoading}/>
    {confirmationMessage && <ConfirmationModal isOpen={showConfirm} onConfirm={handleUnLike} onCancel={() => {setShowConfirm(false)}} buttonText={confirmationButton}>
      <Text>
        Unsaving an encouragement means you will no longer be able to revisit it. All encouragements can be resaved by revisiting their relevent course content.
      </Text>
    </ConfirmationModal>}
  </div>
}
