import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getProfile} from "./profileSlice";

export function useProfileData() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const { isLoading, isError, isSuccess } = profile;

  useEffect(() => {
    if (!isLoading && !isSuccess && !isError) {
      dispatch(getProfile())
    }
  }, [dispatch, isLoading, isSuccess, isError]);

  return profile;
}
