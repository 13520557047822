import authService from '../auth/authService'

const getNotes = async (params) => {
  const response = await authService.getClient().request({
    url: `/api/v1/notes`,
    method: 'GET',
    params,
  })
  return response.data
}

const addNote = async (value) => {
  const {id, title, body} = value
  const formData = {
    "title": title,
    "body": body,
    "course_content": id
  }

  const response = await authService.getClient().request({
    url: `/api/v1/note`,
    method: 'POST',
    data: formData,
  })
  return response.data
}


const updateNote = async (params) => {
  const {id} = params;

  const response = await authService.getClient().request({
    url: `/api/v1/note/${id}`,
    method: 'PATCH',
    data: params,
  })
  return response.data
}

const deleteNotes = async (ids) => {
  const response = await authService.getClient().request({
    url: `/api/v1/notes/delete`,
    method: 'POST',
    data: {
      ids,
    },
  })
  return response.data
}

const downloadNotes = async (ids) => {
  const response = await authService.getClient().request({
    url: `/api/v1/notes/download`,
    responseType: 'arraybuffer',
    method: 'POST',
    data: {
      ids,
    },
  })
  return response
}

const notesService = {
  getNotes,
  updateNote,
  deleteNotes,
  downloadNotes,
  addNote,
}

export default notesService
