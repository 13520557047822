import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys)

const baseStyle = definePartsStyle({
  header: {
    paddingBottom: '10px',
  },
  body: {
    paddingTop: '10px',
  },
  footer: {
    paddingTop: '10px',
  },
  container: {
    boxShadow: 'none',
    padding: '14px 11px'
  }
})

const sizes = definePartsStyle({
  base: {
    container: {
      borderRadius: '16px',
    },
  },
})

export const cardTheme = defineMultiStyleConfig({ baseStyle, sizes })