import { Button, Flex, Text } from '@chakra-ui/react';

export default function BackButton({ isLoading, onClick }) {
  return (
    <Button isLoading={isLoading} onClick={onClick} color="white" fontFamily="button" bg="primary.deepblue" borderRadius="3xl" _hover={{ bg: 'other.backHover' }}>
      <Flex alignItems="center" gap={3}>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.99999 13.3542L0.645828 7L6.99999 0.645836L7.89583 1.54167L3.04166 6.375H13.3542V7.625H3.04166L7.89583 12.4583L6.99999 13.3542Z" fill="white"/>
        </svg>
        <Text>Back</Text>
      </Flex>
    </Button>
  )
}
