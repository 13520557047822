import { Flex, Heading, Text } from "@chakra-ui/react";
import OutlineButton from "../buttons/OutlineButton";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
 
  const navigate = useNavigate()

  const goHome = () => {
    navigate('/')
  }

  return (
    <Flex justifyContent={'center'} height={'100vh'} alignItems={'center'} direction={'column'} textAlign={'center'} bg={'lightGrey.100'}>
      <Heading lineHeight={'1'} fontSize={{base: '100px', lg: '150px'}} color={'primary.navy'}>404</Heading>
      <Heading mb={'24px'} fontSize={'32px'} color={'primary.navy'}>Page not found</Heading>
      <Text maxWidth={'335px'} mb={'24px'} fontSize={'16px'}>Sorry, the page you are looking for could not be found.</Text>
      <OutlineButton onClick={goHome} bgColor={'primary.flame'} txColor={'white'} copy={'Home'} />
    </Flex>
  );
}