import authService from '../auth/authService'

const initialState = {
  isLoading: false,
  isSuccess: false,
  locked: true,
}

const processCourse = (data) => {
  if (data.content) {
    data.content.forEach((child) => {
      processCourseContent(child);
    });
  }

  return data;
}

const processCourseContent = (data) => {
  if (data.children) {
    data.children.forEach((child) => {
      processCourseContent(child);
    });
  }

  ['module', 'segment', 'group'].forEach(prop => {
    if (data[prop]) {
      processCourseContent(data[prop]);
    }
  });

  return data;
}

const getCourseInfo = async () => {
  const response = await authService.getClient().request({
    url: `/api/v1/courses`,
    method: 'GET',
  })
  return response.data
}

const getCourses = async (params) => {
  const response = await authService.getClient().request({
    url: `/api/v1/courses`,
    method: 'GET',
  })

  if (response.data.items) {
    response.data.items.forEach(course => {
      processCourse(course);
    })
  }

  return response.data
}

const getCourse = async (id) => {
  const response = await authService.getClient().request({
    url: `/api/v1/course/${id}`,
    method: 'GET',
  })
  return processCourse(response.data)
}

const unlockCourse = async (id) => {
  const response = await authService.getClient().request({
    url: `/api/v1/course/${id}/progress/unlock`,
    method: 'POST',
    data: {},
  })
  return response.data
}

const startCourse = async (id) => {
  const response = await authService.getClient().request({
    url: `/api/v1/course/${id}/progress/start`,
    method: 'POST',
    data: {},
  })
  return response.data
}

const getCourseContent = async (id) => {
  const response = await authService.getClient().request({
    url: `/api/v1/course-content/${id}`,
    method: 'GET',
  })
  return processCourseContent(response.data)
}

const getCourseContents = async (ids = []) => {
  const response = await authService.getClient().request({
    url: `/api/v1/course-contents`,
    method: 'GET',
    params: {
      id: ids,
    },
  })

  if (response.data.items) {
    response.data.items.forEach(courseContent => {
      processCourseContent(courseContent);
    })
  }

  return response.data
}

//@todo remove
const getModuleInfo = async (id) => {
  const response = await authService.getClient().request({
    url: `/api/v1/course-content/${id}`,
    method: 'GET',
  })
  return response.data
}

//@todo remove
const getSegmentInfo = async (id) => {
  const response = await authService.getClient().request({
    url: `/api/v1/course-content/${id}`,
    method: 'GET',
  })
  return response.data
}

const submitAnswer = async (values) => {
  const {id, answer} = values
  const response = await authService.getClient().request({
    url: `/api/v1/course-content/${id}/answer`,
    method: 'POST',
    data: answer,
  })
  return response.data
}

const courseService = {
  initialState,
  getCourseInfo,
  getCourses,
  getCourse,
  unlockCourse,
  startCourse,
  getCourseContent,
  getCourseContents,
  getModuleInfo,
  getSegmentInfo,
  submitAnswer
}

export default courseService