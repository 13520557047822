import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Link,
    useDisclosure,
    Spinner,
    Heading
  } from '@chakra-ui/react'
import parse from 'html-react-parser';
import generalService from '../../features/general/generalService'
import { toast } from 'react-toastify'

import { useEffect, useState } from 'react'

  export function TAndCModal({link, title}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [errorMessage, setErrorMessage] = useState('')
    const [data, setData] = useState(false)

    useEffect(() => {
      if (errorMessage) {
        toast.error(errorMessage, {
          autoClose: false,
          onClose: () => setErrorMessage(false)
        })
      }
      if (title === 'IP Statement') {
        getData('ip-statement')
        
      }
      if (title === 'Terms & Conditions') {
        getData('terms-and-conditions')
      }
    },[title])

    const getData = async (url) => {
       try {
          const response = await generalService.getStatements(url)
          setData(response)
       } 
       catch (error){
        const message = (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
        setErrorMessage(message)
       }
    }

    return (
      <>
        <Link color={'other.hyperlink'} onClick={onOpen}>{link}</Link>
  
        <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader><Heading>{title}</Heading></ModalHeader>
            <ModalCloseButton />
            <ModalBody>
             {data ?
              parse(data?.body?.value)
              :
              <Spinner/>
            }
            </ModalBody>
  
           
          </ModalContent>
        </Modal>
      </>
    )
  }