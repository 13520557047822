import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {cn, fadeVolume} from "app/helpers";
import Controls from "components/media/Controls/Controls";

const AnimationVideoPlayer = forwardRef(function AnimationVideoPlayer({
                                                                        src,
                                                                        width,
                                                                        height,
                                                                        controls = false,
                                                                        autoPlay = true,
                                                                        muted = false,
                                                                        volume = 1,
                                                                        loop = false,
                                                                        onComplete,
                                                                        onCompleteOffset = 0,
                                                                        onError,
                                                                        restart = true
                                                                      }, ref) {
  const classes = cn('animation-video-player');
  const [videoRef, setVideoRef] = useState(null);
  const [timestamp, setTimestamp] = useState(0);
  const [complete, setComplete] = useState(false);
  const [isMuted, setMuted] = useState(muted);

  useImperativeHandle(ref, () => {
    return videoRef;
  }, [videoRef]);

  const handleError = (event) => {
    console.log(event);
    onError && onError();
  };

  const handleTimeUpdate = (event) => {
    const timestamp = videoRef.currentTime;

    if (!videoRef.paused) {
      setTimestamp(timestamp);
    }

    if (videoRef.duration - timestamp <= onCompleteOffset / 1000) {
      if (!complete) {
        setComplete(true);
      }
    } else {
      complete && setComplete(false);
    }
  };

  const handleCanPlay = (event) => {
    const video = event.target;

    if (video.autoplay && !muted) {
      video.play().catch((e) => {
        if (e.name === "NotAllowedError") {
          video.muted = true;
          video.play();
        }
      });
    }
  };

  useEffect(() => {
    if (videoRef && src && !restart) {
      videoRef.currentTime = timestamp;
    }
  }, [videoRef, src]);

  useEffect(() => {
    if (videoRef) {
      // Fade volume.
      const setVolume = (newVolume) => {
        videoRef.volume = newVolume;
      };
      if (!muted) {
        const from = videoRef.muted ? 0 : videoRef.volume;
        videoRef.muted = false;
        fadeVolume(setVolume, from, volume).then(() => {
          setMuted(false);
        });
      } else if (!isMuted) {
        fadeVolume(setVolume, videoRef.volume, 0).then(() => {
          setMuted(true);
        });
      }
    }
  }, [videoRef, volume, muted]);

  useEffect(() => {
    if (videoRef) {
      videoRef.addEventListener('error', handleError);
      videoRef.addEventListener('timeupdate', handleTimeUpdate);
      videoRef.addEventListener('canplay', handleCanPlay);
      videoRef.volume = volume;
    }

    return () => {
      if (videoRef) {
        videoRef.removeEventListener('error', handleError);
        videoRef.removeEventListener('timeupdate', handleTimeUpdate);
        videoRef.removeEventListener('canplay', handleCanPlay);
      }
    }
  }, [videoRef]);

  useEffect(() => {
    complete && onComplete && onComplete();
  }, [complete, onComplete]);

  return <>
    <video className={classes()} ref={setVideoRef} src={src} width={width} height={height} autoPlay={autoPlay} muted={isMuted} loop={loop}/>
    {controls && <Controls media={videoRef} allowFullscreen={false} />}
  </>
});
export default AnimationVideoPlayer;
