function AvatarEditIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="none"
        viewBox="0 0 32 32"
      >
        <circle cx="16" cy="16" r="16" fill="#EE5622"></circle>
        <mask
          id="mask0_1743_70412"
          style={{ maskType: "alpha" }}
          width="22"
          height="22"
          x="5"
          y="5"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#D9D9D9"
            d="M5.333 5.334H26.665999999999997V26.666999999999998H5.333z"
          ></path>
        </mask>
        <g mask="url(#mask0_1743_70412)">
          <path
            fill="#F9F9F9"
            d="M9.333 22.6h1.111l8.223-8.222-1.09-1.111-8.244 8.244V22.6zm12.178-9.2l-3-2.978.978-.978A1.597 1.597 0 0120.634 9c.451 0 .825.148 1.122.444l.91.912c.253.266.386.58.4.944.015.363-.11.67-.377.922L21.51 13.4zm-.955.978L11 23.933H8v-3l9.556-9.555 3 3zm-2.445-.556l-.533-.555 1.089 1.11-.556-.555z"
          ></path>
        </g>
      </svg>
    );
  }
  
  export default AvatarEditIcon;
  