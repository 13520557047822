import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {handleApiError} from "../auth/authSlice";
import likesService from "./likesService";

const likesAdapter = createEntityAdapter({
  selectId: (like) => like.entity_type + '_' + like.entity_id,
  sortComparer: (a, b) => {
    const dateA = new Date(a.timestamp?.value);
    const dateB = new Date(b.timestamp?.value);

    const aSticky = !!a.sticky;
    const bSticky = !!b.sticky;
    if (aSticky !== bSticky) {
      return aSticky > bSticky ? -1 : 1;
    }

    return dateA.getTime() === dateB.getTime() ? 0 : (dateA > dateB ? -1 : 1);
  },
});
export const likesSelector = likesAdapter.getSelectors(state => state.likes);
export const getLikesByType = (state, type) => {
  let entityType, bundle;
  switch (type) {
    case 'saved-content':
      entityType = 'course_content';
      bundle = 'course_segment';
      break;
    case 'encouragement':
      entityType = 'encouragement';
      break;
    default:
      return [];
  }

  return getLikesByEntityType(state, entityType, bundle);
}
export const getLikesByEntityType = (state, entityType, bundle) => {
  if (!entityType) {
    return likesSelector.selectAll(state);
  }

  return likesSelector.selectAll(state).filter((item) => {
    return item.entity_type === entityType && (!bundle || item.bundle === bundle);
  }) || [];
}
export const getLikeByEntity = (state, entityType, entityId) => {
  const id = entityType + '_' + entityId;
  return likesSelector.selectById(state, id) || null;
}
export const getLikesByEntity = (state, entityType, entityIds) => {
  const result = [];
  entityIds.forEach(id => {
    const like = getLikeByEntity(state, entityType, id);
    like && result.push(like);
  })

  return result;
}

export const fetchLikes = createAsyncThunk('likes/fetchLikes', async (params, thunkAPI) => {
  try {
    return await likesService.getLikes(params);
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
});

export const addLike = createAsyncThunk('likes/addLike', async (params, thunkAPI) => {
  try {
    return await likesService.createLike(params);
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
});

export const removeLike = createAsyncThunk('likes/removeLike', async (like, thunkAPI) => {
  try {
    return await likesService.deleteLike(like.id);
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
});

export const pinLike = createAsyncThunk('likes/pinLike', async (like, thunkAPI) => {
  try {
    return await likesService.pinLike(like.id, like.sticky);
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
});

const likesSlice = createSlice({
  name: 'likes',
  initialState: likesAdapter.getInitialState({
    isLoading: false,
    isSuccess: false,
    isError: false,
  }),
  extraReducers: (builder) => {
    builder
      .addCase(fetchLikes.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchLikes.fulfilled, (state, action) => {
        action.payload.items.forEach(item => {
          if (item.deleted) {
            likesAdapter.removeOne(state, likesAdapter.selectId(item));
          }
          else {
            likesAdapter.addOne(state, item);
          }
        });
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(fetchLikes.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
      .addCase(addLike.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addLike.fulfilled, (state, action) => {
        likesAdapter.addOne(state, action.payload);
        state.isLoading = false;
      })
      .addCase(removeLike.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(removeLike.fulfilled, (state, action) => {
        const like = action.meta.arg;
        likesAdapter.removeOne(state, likesAdapter.selectId(like));
        state.isLoading = false;
      })
      .addCase(pinLike.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(pinLike.fulfilled, (state, action) => {
        likesAdapter.upsertOne(state, action.payload);
        state.isLoading = false;
      })
  },
});

export default likesSlice.reducer;
