import {Radio, RadioGroup, Checkbox} from "@chakra-ui/react";
import OutlineButton from "../../buttons/OutlineButton";
import {useContext, useEffect, useState} from "react";
import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import {cn} from "app/helpers";
import {ActivityContext} from "components/course/Activity/ActivityContext";
import ActivityPager from "components/course/Activity/ActivityPager";

export default function Options({data}) {
  const {onNext, isSubmitting} = useContext(ActivityContext);
  const classes = cn('options');
  const {content, answer} = data
  const {options, multiple} = content?.content;
  const style = content?.content?.style || 'default';

  const [value, setValue] = useState([])
  const [showAll, setShowAll] = useState(false)

  useEffect(() => {
    if (value.length === 0 && answer?.value) {
      setValue(answer.value)
    }
  }, [answer])

  const handleNext = () => {
    onNext(Array.isArray(value) ? value : [value]);
  };

  const handleShowAll = () => {
    setShowAll(!showAll)
  }

  const handleChecked = (item) => {
    let newValue;
    if (multiple) {
      if (value.includes(item)) {
        newValue = value.filter(w => w !== item);
      } else {
        newValue = [item, ...value];
      }
    } else {
      newValue = [item];
    }

    setValue(newValue);
  }

  const handleClick = (item) => {
    if (style === 'button') {
      handleChecked(item);

      if (!multiple && !isSubmitting) {
        onNext([item]);
      }
    }
  }

  const OptionsWrapper = ({multiple, children}) => {
    return multiple ?
      <div className={classes('items')}>{children}</div> :
      <RadioGroup className={classes('items')} onChange={(value) => {
        handleChecked(value)
      }} value={value[0]}>
        {children}
      </RadioGroup>
  }

  const OptionsItems = () => {
    return <OptionsWrapper multiple={multiple}>
      {options.slice(0, showAll ? options.length - 1 : 6).map((item, index) => {
        const isChecked = value?.includes(item);
        return <div key={index} className={classes('item', {}, [isChecked ? 'js-active' : null])}
                    onClick={() => handleClick(item)}>
          {multiple ?
            <Checkbox colorScheme={'white'} onChange={() => handleChecked(item)}
                      isChecked={isChecked}>{item}</Checkbox> :
            <Radio colorScheme={'white'} isChecked={isChecked} value={item}>{item}</Radio>
          }
        </div>
      })}
    </OptionsWrapper>
  }

  return (
    <ActivityContext.Provider value={{
      ...useContext(ActivityContext),
      canSubmit: value.length > 0,
      onNext: !(style === 'button' && !multiple) && handleNext,
    }}>
      <ActivityWrapper title={content?.title} description={content?.description}>
        <div className={classes({
          style,
          multiple: !!multiple,
        })}>
          <OptionsItems value={value} setValue={setValue} options={options} multiple={multiple} style={style}/>
          {multiple && options.length > 6 &&
          <div className={classes('more-button')}>
            <OutlineButton txColor={'white'} bgColor={'primary.navy'} copy={showAll ? 'Show less' : 'Show more'}
                           onClick={handleShowAll}/>
          </div>}
        </div>
        <ActivityPager/>
      </ActivityWrapper>
    </ActivityContext.Provider>
  )
}
