import {Fragment, useContext, useEffect, useMemo} from 'react';
import { useDispatch } from 'react-redux';
import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import { submitAnswer } from 'features/course/courseContentSlice';
import { cn } from 'app/helpers';
import RichText from 'components/Activities/RichText';
import Wave from './Wave.jsx';
import WaveLarge from 'components/Activities/RiverOfChange/WaveLarge';
import {ActivityContext} from "components/course/Activity/ActivityContext";

const classes = cn('river-of-change');

function getItems(answer) {
  switch (answer.type) {
    case 'open_question_multi':
      const values = answer.value || [];
      return values.map(value => value.values || []).flat();

    case 'drag_to_select':
      return answer.value || [];

    default:
      return [];
  }
}

export default function RiverOfChange({data}) {
  const { id, content } = data;
  const startTitle = content?.content?.start_title || '';
  const startDescription = content?.content?.start_desc || '';
  const middleDescription = content?.content?.middle_desc || '';
  const endTitle = content?.content?.end_title || '';
  const endDescription = content?.content?.end_desc || '';

  // Workaround for missing data properties when slider navigation or other
  // page elements load.
  // @todo Fix data loading so loading secondary elements doesn't affect main page's data.
  const {startItems, middleItems, endItems} = useMemo(() => {
    return {
      startItems: getItems(content?.content?.start?.answer || {}),
      middleItems: getItems(content?.content?.middle?.answer || {}),
      endItems: getItems(content?.content?.end?.answer || {}),
    };
  }, [id]);

  const waveStrokeMin = 2;
  const waveStrokeMax = 5;

  return (
    <ActivityWrapper title={content?.title} description={content?.description}>
      <div className={classes()}>
        <div className={classes('start')}>
          {startTitle && <div className={classes('title')}>{startTitle}</div>}
          {startDescription &&
          <div className={classes('intro')}>
            <RichText text={startDescription} />
          </div>
          }
          {!!startItems.length &&
          <div className={classes('items')}>
            {startItems.map((item, index) => <div className={classes('item')} key={index}>{item}</div>)}
          </div>
          }
        </div>
        <div className={classes('middle')}>
          {!!middleItems.length && middleItems.map((item, index) => {
            const waveStrokeWidth = waveStrokeMin + Math.round(((waveStrokeMax - waveStrokeMin) * index) / middleItems.length);
            const isLastItem = index === (middleItems.length - 1);
            return (
              <Fragment key={index}>
                <div className={classes('wave')}><Wave strokeWidth={waveStrokeWidth} /></div>
                <div className={classes('item')}>{item}</div>
                {isLastItem && <div className={classes('wave')}><WaveLarge /></div>}
              </Fragment>
            );
          })
          }
        </div>
        <div className={classes('end')}>
          {endTitle && <div className={classes('title')}>{endTitle}</div>}
          {endDescription &&
          <div className={classes('intro')}>
            <RichText text={endDescription} />
          </div>
          }
          {!!endItems.length &&
          <div className={classes('items')}>
            {endItems.map((item, index) => <div className={classes('item')} key={index}>{item}</div>)}
          </div>
          }
        </div>
        <div className={classes('description')}>
          <RichText text={startDescription} />
        </div>
        <div className={classes('description', {middle: true})}>
          <div className={classes('arrow')} />
          <RichText text={middleDescription} />
        </div>
        <div className={classes('description')}>
          <RichText text={endDescription} />
        </div>
      </div>
    </ActivityWrapper>
  );
}
