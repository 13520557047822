import {getLikesByType} from "features/like/likesSlice";
import {useSelector} from "react-redux";
import Layout from "../../components/layout/Layout";
import SegmentCard from "../../components/course/SegmentCard";
import {Grid, Text} from "@chakra-ui/react";
import Block from "../../components/layout/Block";
import {getCourseContent} from "features/course/courseContentSlice";
import {useState} from "react";
import ModulesFilter from "../../components/listing/ModulesFilter";
import Sort from "../../components/listing/Sort";
import PageHeading from "../../components/layout/PageHeading";
import FilterWrapper from "../../components/listing/FilterWrapper";
import EmptyList from "components/listing/EmptyList";
import BookmarkIcon from "icons/BookmarkIcon";

export default function SavedContentPage() {
  const defaultSort = 'timestamp_desc';
  const includeModules = [];
  const [selectedModules, setSelectedModules] = useState([]);
  const [sort, setSort] = useState(defaultSort);
  const likes = useSelector(state => {
    const likes = getLikesByType(state, 'saved-content');
    const [sortBy, sortDir] = sort.split('_');

    return likes.map(like => {
      const content = getCourseContent(state, like.entity_id);
      if (content?.isError) return false;

      const module = content?.module;
      if (module?.id && !includeModules.includes(module.id)) {
        includeModules.push(module.id);
      }
      return {...like, content, module};
    })
      .filter(like => like && (selectedModules.length === 0 || selectedModules.includes(like?.module?.id)))
      .sort((a, b) => {
        let propA;
        let propB;
        switch (sortBy) {
          case 'timestamp':
            propA = new Date(a.timestamp?.value);
            propB = new Date(b.timestamp?.value);
            break;
          case 'title':
            propA = a.content?.content?.title;
            propB = b.content?.content?.title;
            break;
          case 'weight':
            propA = a.module?.weight;
            propB = b.module?.weight;
            break;
          default:
            return 0;
        }

        if (propA < propB) {
          return sortDir === 'asc' ? -1 : 1;
        }
        if (propA > propB) {
          return sortDir === 'asc' ? 1 : -1;
        }

        return 0;
      });
  });

  const header = {
    title: 'Saved content',
  };

  const sortOptions = [
    {
      id: 'timestamp_desc',
      title: 'Newest',
    },
    {
      id: 'timestamp_asc',
      title: 'Oldest',
    },
    {
      id: 'title_asc',
      title: 'A-Z',
    },
    {
      id: 'title_desc',
      title: 'Z-A',
    },
    {
      id: 'weight_asc',
      title: 'Module',
    }
  ];

  const handleFilter = (selected) => {
    setSelectedModules(selected);
  };

  const handleSort = (selected) => {
    setSort(selected);
  };

  return <Layout headerInfo={header}>
    <Block>
      <PageHeading title={header.title} isMain={true} size={'md'}>
        <FilterWrapper>
          <ModulesFilter onChange={handleFilter} include={includeModules}/>
          <Sort options={sortOptions} defaultValue={defaultSort} onChange={handleSort} />
        </FilterWrapper>
      </PageHeading>
      {likes.length > 0 ? <Grid gap={4} >
        {likes.map((like) => <SegmentCard id={like.entity_id} key={like.entity_id}/>)}
      </Grid> : <EmptyList icon={<BookmarkIcon size={24} color={'flame'}/>} title={'You haven’t saved any content yet.'}>
        <Text>Use the bookmark button on course content that you would like to easily revisit from this page.</Text>
      </EmptyList>}
    </Block>
  </Layout>;
}
