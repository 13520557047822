import { progressAnatomy } from '@chakra-ui/anatomy'
import {createMultiStyleConfigHelpers} from "@chakra-ui/styled-system";

const { defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(progressAnatomy.keys)

export const progressTheme = defineMultiStyleConfig({
  baseStyle: {
    track: {
      bg: 'rgba(0, 0, 0, 0.08)',
    },
    filledTrack: {
      bg: 'primary.flame',
      borderRadius: '30px',
    }
  },
})
