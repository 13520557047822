import {createSlice, createAsyncThunk, createEntityAdapter} from "@reduxjs/toolkit"
import courseService from "./courseService";
import {handleApiError} from "../auth/authSlice";

const coursesAdapter = createEntityAdapter({
  sortComparer: (a, b) => {
    if (a.weight !== b.weight) {
      return a.weight < b.weight ? -1 : 1;
    }

    const dateA = new Date(a.created?.value);
    const dateB = new Date(b.created?.value);
    return dateA === dateB ? 0 : (dateA > dateB ? -1 : 1);
  },
});
export const coursesSelector = coursesAdapter.getSelectors(state => state.courseContent.courses);

const courseContentAdapter = createEntityAdapter();
export const courseContentSelector = coursesAdapter.getSelectors(state => state.courseContent.items);

export const getCourses = (state) => coursesSelector.selectAll(state);
export const getCourse = (state, id) => coursesSelector.selectById(state, id) || {...courseService.initialState, id};
export const getCourseContent = (state, id) => (id && courseContentSelector.selectById(state, id)) || {...courseService.initialState, id};

const initialState = {
  courses: coursesAdapter.getInitialState(),
  items: courseContentAdapter.getInitialState(),
  coursesLoaded: false,
}

const updateItems = (state, items) => {
  if (Array.isArray(items)) {
    items.forEach((item) => {
      courseContentAdapter.updateOne(state, {
        id: item.id,
        changes: item,
      });
    });
  }
}

export const fetchCourses = createAsyncThunk('courseContent/fetchCourses', async (params, thunkAPI) => {
  try {
    return await courseService.getCourses(params);
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

export const fetchCourse = createAsyncThunk('courseContent/fetchCourse', async (id, thunkAPI) => {
  try {
    const course = await courseService.getCourse(id);
    if (course && course.locked) {
      await thunkAPI.dispatch(unlockCourse(course.id));
    }

    return course;
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

export const unlockCourse = createAsyncThunk('course/unlockCourse', async (id, thunkAPI) => {
  try {
    const progress = await courseService.unlockCourse(id);
    if (progress && !progress.locked) {
      thunkAPI.dispatch(fetchCourse(id));
    }
    return progress;
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

export const startCourse = createAsyncThunk('course/startCourse', async (id, thunkAPI) => {
  try {
    const progress = await courseService.startCourse(id);
    thunkAPI.dispatch(fetchCourse(id));
    return progress;
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

export const fetchCourseContent = createAsyncThunk('courseContent/fetchCourseContent', async (id, thunkAPI) => {
  try {
    return await courseService.getCourseContent(id)
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

export const fetchCourseContents = createAsyncThunk('courseContent/fetchCourseContents', async (ids, thunkAPI) => {
  try {
    return await courseService.getCourseContents(ids)
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

export const submitAnswer = createAsyncThunk('course/submitAnswer', async (values, thunkAPI) => {
  try {
    return await courseService.submitAnswer(values)
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
})

export const courseContentSlice = createSlice({
  name: 'courseContent',
  initialState,
  reducers: {
    // Temporary solution for updating state from goNextPage action.
    // @todo Rework. Somehow.
    setCourseContent: (state, action) => {
      const data = action.payload;
      courseContentAdapter.setOne(state.items, {
        ...data,
        isLoading: false,
        isSuccess: true,
      });

      updateItems(state.items, data.children);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourses.fulfilled, (state, action) => {
        action.payload.items.forEach((course) => {
          coursesAdapter.setOne(state.courses, {
            ...course,
            isLoading: false,
            isSuccess: true,
          });

          updateItems(state.items, course.content);
          state.coursesLoaded = true;
        });
      })
      .addCase(fetchCourse.fulfilled, (state, action) => {
        coursesAdapter.setOne(state.courses, {
          ...action.payload,
          isLoading: false,
          isSuccess: true,
        });

        updateItems(state.items, action.payload.content);
      })
      .addCase(fetchCourse.rejected, (state, action) => {
        const id = action.meta.arg;
        coursesAdapter.upsertOne(state.courses, {
          id,
          isLoading: false,
          isSuccess: false,
          isError: true,
          locked: true,
          error: action.payload,
        });
      })
      .addCase(fetchCourseContent.fulfilled, (state, action) => {
        const courseContent = action.payload;
        courseContentAdapter.setOne(state.items, {
          ...courseContent,
          isLoading: false,
          isSuccess: true,
        });

        updateItems(state.items, courseContent.parents);
        updateItems(state.items, courseContent.children);
      })
      .addCase(fetchCourseContent.rejected, (state, action) => {
        const id = action.meta.arg;
        courseContentAdapter.upsertOne(state.items, {
          id,
          isLoading: false,
          isSuccess: false,
          isError: true,
          locked: true,
          error: action.payload,
        });
      })
      .addCase(fetchCourseContents.fulfilled, (state, action) => {
        const data = action.payload;

        if (data.items) {
          data.items.forEach((courseContent) => {
            courseContentAdapter.setOne(state.items, {
              ...courseContent,
              isLoading: false,
              isSuccess: true,
            });

            updateItems(state.items, courseContent.parents);
            updateItems(state.items, courseContent.children);
          });
        }
      })
      .addCase(submitAnswer.fulfilled, (state, action) => {
        courseContentAdapter.setOne(state.items, {
          ...action.payload,
          isLoading: false,
          isSuccess: true,
        });
      });
  },
});

export const {setCourseContent} = courseContentSlice.actions
export default courseContentSlice.reducer;
