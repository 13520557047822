import {useEffect, useState} from "react";
import {fetchEncouragements, encouragementsSelector} from "./encouragementsSlice";
import {useDispatch, useSelector} from "react-redux";
import {LoadBatch} from "app/LoadBatch";

let encouragementBatch;

export function useEncouragementData(id, reload = false) {
  id = parseInt(id);
  encouragementBatch = encouragementBatch || new LoadBatch();
  const dispatch = useDispatch();
  const encouragement = useSelector(state => encouragementsSelector.selectById(state, id));
  const batch = encouragementBatch;
  const [isLoaded, setLoaded] = useState(batch.isLoaded(id));
  const [isLoading, setLoading] = useState(batch.isBatched(id) || batch.isLoading(id));
  const isSuccess = isLoaded || !!encouragement;

  useEffect(() => {
    if (reload || !isSuccess) {
      batch.add(id, {
        onLoading: () => {
          setLoaded(false);
          setLoading(true);
        },
        onLoad: () => {
          setLoading(false);
          setLoaded(batch.isLoaded(id));
        }}
      );

      setTimeout(() => {
        const ids = batch.next();

        if (ids.length > 0) {
          dispatch(fetchEncouragements({
            'id': ids,
          })).then(() => {
            batch.load(ids);
          });
        }
      }, 30);
    }
  }, [dispatch, batch, id, reload]);

  return [encouragement, isLoading, isSuccess];
}
