import authService from "../auth/authService";

const getBlocks = async (params) => {
  if (typeof params.region === 'undefined') {
    return [];
  }

  const response = await authService.getClient().request({
    url: `/api/v1/region/${params.region}`,
    method: 'GET',
    params: {
      uri: params.uri,
    },
  });

  return response.data;
}

const blocksService = {
  getBlocks
}

export default blocksService;
