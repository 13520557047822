function NotificationIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <mask
          id="mask0_641_21457"
          style={{ maskType: "alpha" }}
          width="24"
          height="24"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
        </mask>
        <g mask="url(#mask0_641_21457)">
          <path
            fill="#0C1851"
            d="M4.25 18.875v-1.5h2v-7.25c0-1.367.421-2.571 1.263-3.613A5.664 5.664 0 0110.75 4.5v-.7c0-.35.121-.646.363-.888.241-.241.537-.362.887-.362s.646.12.887.362c.242.242.363.538.363.888v.7c1.317.3 2.396.97 3.238 2.012.841 1.042 1.262 2.246 1.262 3.613v7.25h2v1.5H4.25zM12 21.8c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 0110.2 20h3.6c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525zm-4.25-4.425h8.5v-7.25c0-1.183-.413-2.188-1.238-3.013-.825-.825-1.829-1.237-3.012-1.237s-2.187.412-3.012 1.237c-.825.825-1.238 1.83-1.238 3.013v7.25z"
          ></path>
        </g>
      </svg>
    );
  }
  
  export default NotificationIcon;