import authService from '../auth/authService';

async function getConsultants(params) {
  const response = await authService.getClient().request({
    url: '/api/v1/consultants',
    method: 'GET',
    params: params,
  });
  return response.data;
}

const consultantsService = {
  getConsultants,
};

export default consultantsService;
