import {Box, Flex} from "@chakra-ui/react";
import NextButton from "../../buttons/NextButton";
import BackButton from "../../buttons/BackButton";
import PagerButton from "../../buttons/PagerButton";
import PreviousButton from '../../buttons/PreviousButton';
import Button from "buttons/Button";

const PagerFull = ({pages = [], handleClick, isLoading}) => {
  return <Flex className={'pager'} mt={6} gap={2}>
    {pages.map((page, index) => {
      return <PagerButton key={index} title={page.title}
                          isCurrent={page.isCurrent}
                          isPrevious={page.isPrevious}
                          isLoading={isLoading}
                          disabled={page.disabled}
                          onClick={(e) => {handleClick(e, page.page)}}
      />
    })}</Flex>
}

const PagerMini = ({totalPages, currentPage, handleNext, handlePrev, isLoading, disabled}) => {
  return <Flex className={'pager'} columnGap={4} alignItems="center">
    {currentPage === 0
      ? <BackButton isLoading={isLoading} onClick={handlePrev} />
      : <PreviousButton isLoading={isLoading} onClick={handlePrev} />
    }
    <Box color={'white'}>{`${currentPage + 1} / ${totalPages}`}</Box>
    {currentPage === totalPages - 1 ?
      <Button type={"submit"} name={"submit"} color={'orange'}
                     isLoading={isLoading}
                     disabled={disabled}
                     onClick={handleNext}>Continue</Button> :
      handleNext && <NextButton isLoading={isLoading}
                  disabled={disabled}
                  onClick={handleNext}
      />}
  </Flex>
}

const PagerDefault = ({isLoading, disabled, handleNext, handlePrev, nextLabel}) => {
  return <Flex className={'pager'} gap={2} alignItems="center">
    <BackButton isLoading={isLoading} onClick={handlePrev} />
    {handleNext && <Button type={"submit"} name={"submit"} color={'orange'}
                   isLoading={isLoading}
                   disabled={disabled}
                   onClick={handleNext}
    >{nextLabel || 'Continue'}</Button>}
  </Flex>;
}

export default function Pager(props) {
  const {
    type,
    totalPages = 0,
    currentPage = 0,
    setCurrentPage,
    canNavigate,
    onNext,
    onPrev,
    nextLabel,
  } = props;
  let pages = [];

  for (let i = 0; i < totalPages; i++) {
    pages.push({
      page: i,
      isCurrent: (i === currentPage),
      isPrevious: (i < currentPage),
      title: (i + 1),
      disabled: (i === currentPage) || !canNavigate || !canNavigate(i),
    });
  }

  const handleClick = (e, page) => {
    e.preventDefault();
    setCurrentPage && setCurrentPage(page);
  }

  const handleNext = (e) => {
    e.preventDefault();
    onNext && onNext();
  }

  const handlePrev = (e) => {
    e.preventDefault();
    onPrev && onPrev();
  }

  const pagerProps = {
    ...props,
    pages,
    handleClick,
    handleNext: onNext && handleNext,
    handlePrev,
    nextLabel,
  };
  switch (type) {
    case 'full':
      return pages.length > 0 && <PagerFull {...pagerProps}/>
    case 'mini':
      return <PagerMini {...pagerProps}/>
    default:
      return <PagerDefault {...pagerProps}/>
  }
}
