import EncouragementCard from "components/course/EncouragementCard";
import {useSelector} from "react-redux";
import {getLikesByType} from "features/like/likesSlice";
import {cn} from "app/helpers";
import ViewAllLink from "components/listing/ViewAllLink";
import {useLikesData} from "features/like/likesHooks";
import {useState} from "react";

export default function EncouragementsPanel() {
  const classes = cn('encouragements-panel');
  const [params] = useState( {
    'entity_type': 'encouragement',
    'items_per_page': 8,
  });
  useLikesData(params); // Make sure last 8 encouragements are always loaded.
  const likes = useSelector(state => getLikesByType(state, 'encouragement'));

  return <div className={classes()}>
    <div className={classes('header')}>
      <div className={classes('title')}>{likes.length} Saved Encouragements</div>
      {likes.length > 0 && <ViewAllLink to={'/encouragements'}/>}
    </div>
    {likes.length > 0 && <div className={'encouragement-list'}>
      {likes.slice(0, 8).map(like => <EncouragementCard key={like.entity_id} id={like.entity_id} variant={'small'} />)}
    </div>}
  </div>
}
