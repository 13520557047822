import {
  Route,
  createBrowserRouter,
  createRoutesFromElements, RouterProvider,
} from 'react-router-dom';
import { RequireAuth } from 'components/RequireAuth';
import Login from './Login';
import Main from './Main';
import Faqs from './Faqs';
import ErrorPage from "./ErrorPage";
import Courses from "./Courses";
import Profile from "./Profile";
import NewPassword from "./NewPassword";
import ChangePassword from "./ChangePassword";
import NotesPage from "./course/NotesPage";
import FirstTimeLogin from "./FirstTimeLogin";
import CourseContent from "./CourseContent";
import courseContentLoader from 'features/course/courseContentLoader';
import CoursePage from "./CoursePage";
import courseLoader, {coursesLoader} from "features/course/courseLoader";
import SavedContentPage from "./course/SavedContentPage";
import {encouragementsLoader, savedContentLoader} from "features/like/likesLoader";
import ResourcesPage from "./course/ResourcesPage";
import resourcesLoader from "features/resources/resourcesLoader";
import loginLoader from "features/auth/loginLoader";
import {Wipe} from "components/media/Wipe";
import {Spinner} from "@chakra-ui/react";
import mainLoader from "features/general/mainLoader";
import notesLoader from "../features/notes/notesLoader";
import EncouragementsPage from "routes/course/EncouragementsPage";
import consultantsLoader from 'features/consultants/consultantsLoader';
import Consultants from './Consultants';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<RequireAuth/>}>
        <Route path="/" exact loader={mainLoader} element={ <Main /> } />
        <Route path="/faq" element={  <Faqs /> } />
        <Route path="/course" loader={coursesLoader} element={  <Courses /> } />
        <Route path="/course/saved" loader={savedContentLoader} element={  <SavedContentPage /> } />
        <Route path="/course/:id" loader={courseLoader} element={ <CoursePage /> } />
        <Route path="/course/content/:id" loader={courseContentLoader} element={<CourseContent />} />
        <Route path="/profile" element={  <Profile /> } />
        <Route path="/notes" loader={notesLoader} element={  <NotesPage /> } />
        <Route path="/resources" loader={resourcesLoader} element={<ResourcesPage/>} />
        <Route path="/encouragements" loader={encouragementsLoader} element={<EncouragementsPage/>} />
        <Route path="/consultants" loader={consultantsLoader} element={<Consultants />} />
      </Route>

      <Route path="/login" loader={loginLoader} element={<Login />} />
      <Route path="/forgot-password" element={<NewPassword />} />
      <Route path="/change-password/:id/:timestamp/:hash" element={<ChangePassword />} />
      <Route path="/login/:id/:timestamp/:hash" element={<FirstTimeLogin />} />
      <Route path="*" element={<ErrorPage />} />
    </Route>
  )
);

function LoadingScreen() {
  return <div className={'app__loading'}>
    <Spinner width="48px" height="48px" />
  </div>
}

export default function App() {
  return <>
    <Wipe/>
    <RouterProvider router={router} fallbackElement={<LoadingScreen/>} />
  </>
}
