import {Box, Heading} from "@chakra-ui/react";
import {cn} from "app/helpers";

export default function PageHeading({title, icon, suffix, children, size='lg', isMain = false}) {
  const classes = cn('page');

  return <Box className={classes('heading', {size, main: isMain})}>
    <Heading className={classes('title')} as={isMain ? 'h1' : 'h2'}>
      {icon && <div className={classes('icon')}>{icon}</div>}
      {title}
      {suffix && <div className={classes('title-suffix')}>{suffix}</div>}
    </Heading>
    {children && <div className={classes('actions')}>{children}</div>}
  </Box>
}
