import {store} from "app/store";
import {fetchCourse, fetchCourses} from "./courseContentSlice";
import {redirect} from "react-router-dom";
import {toast} from "react-toastify";

export default async function courseLoader({ params }) {
  const result = await store.dispatch(fetchCourse(params.id));

  if (result.meta.requestStatus === 'rejected' && result.payload.status === 403) {
    if (result.payload.message) {
      toast.error(result.payload.message);
    }
    return redirect('/');
  }

  return result.payload;
}

export async function coursesLoader() {
  const result = await store.dispatch(fetchCourses({}));
  return result.payload;
}
