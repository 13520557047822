import {Button, useDisclosure} from "@chakra-ui/react";
import ModalDialog from "components/modals/ModalDialog";

export default function ModalButton({children, text, modalTitle, modalIcon, modalFooter, className}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return <>
    <Button className={className} variant={'transparent'} onClick={onOpen}>{text}</Button>
    <ModalDialog title={modalTitle} icon={modalIcon} footer={modalFooter} isOpen={isOpen} onClose={onClose}>
      {children}
    </ModalDialog>
  </>
}
