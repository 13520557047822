import { DndProvider as MultiBackendDndProvider } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch'

export function DndProvider({ children }) {
  return (
    <MultiBackendDndProvider options={HTML5toTouch} portal={document.getElementById('root')}>
      {children}
    </MultiBackendDndProvider>
  )
}
