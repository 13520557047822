import {colorToHex} from "app/helpers";

function ExpandIcon({color = 'navy', size = 24}) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.25 20.75V13.25H4.75V18.2L18.2 4.75H13.25V3.25H20.75V10.75H19.25V5.8L5.8 19.25H10.75V20.75H3.25Z"
        fill={colorToHex(color)}/>
    </svg>
  );
}

export default ExpandIcon;
