import {cn} from "app/helpers";
import {Collapse, IconButton, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure} from "@chakra-ui/react";
import NotesIcon from "icons/NotesIcon";
import BulbIcon from "icons/BulbIcon";
import ChevronUp from "icons/ChevronUp";
import {useParams} from "react-router-dom";
import EncouragementsPanel from "components/course/EncouragementsPanel";
import AddNoteForm from "components/notes/AddNoteForm";

export default function ExpandablePanel() {
  const classes = cn('expandable-panel');
  const { isOpen, onOpen, onToggle } = useDisclosure();
  const routeParams = useParams();

  return <div className={classes({
    open: isOpen,
  })}>
    <Tabs variant='unstyled'>
      <div className={classes('header')}>
        <TabList className={classes('tabs')}>
          <Tab className={classes('tab')} onClick={onOpen}>
            <NotesIcon size={16} color={'navy'} />
            Notes
          </Tab>
          <Tab className={classes('tab')} onClick={onOpen}>
            <BulbIcon size={16} color={'navy'} />
            Encouragements
          </Tab>
        </TabList>
        <IconButton className={classes('toggle')} aria-label={'Expand'} icon={<ChevronUp/>} colorScheme={'transparent'} onClick={onToggle}/>
      </div>

      <Collapse in={isOpen} direction={'bottom'} className={classes('collapse')}>
        <TabPanels className={classes('panels')}>
          <TabPanel className={classes('panel')}>
            <AddNoteForm segmentId={routeParams.id}/>
          </TabPanel>
          <TabPanel className={classes('panel')}>
            <EncouragementsPanel/>
          </TabPanel>
        </TabPanels>
      </Collapse>
    </Tabs>
  </div>
}
