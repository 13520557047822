import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup, Grid } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useProfileData } from 'features/profile/profileHooks';
import { submitWebform } from 'features/webform/webformSlice';
import Layout from 'components/layout/Layout';
import PageHeading from 'components/layout/PageHeading';
import Block from 'components/layout/Block';
import ConsultantCard from 'components/consultant/ConsultantCard';
import ContactForm from 'components/form/ContactForm';
import Button from 'buttons/Button';
import ModalDialog from 'components/modals/ModalDialog';

export default function Consultants() {
  // Data is already loaded and placed to store by the route loader.
  const {data} = useSelector(state => state.consultants);

  const [showContactForm, setShowContactForm] = useState(false);
  const {data: profileData} = useProfileData();

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    return dispatch(submitWebform({webform_id: 'contact', data: values}))
      .then((action) => {
        if (action.meta.requestStatus === 'fulfilled') {
          toast.success('Sent.');
          setShowContactForm(false);
        }
        else if (action.payload.message) {
          toast.error(action.payload.message);
        }
      });
  };

  const headerInfo = {
    title: 'Our Consultants',
  };

  return (
    <Layout headerInfo={headerInfo}>
      <Block>
        <PageHeading title={headerInfo.title} isMain={true} size='md' />
        <ButtonGroup>
          <Button color="orange" onClick={() => setShowContactForm(true)}>Contact NCS</Button>
        </ButtonGroup>
        <Grid gap={4} templateColumns={{base: '1fr', lg: '1fr 1fr' }} mt={6}>
          {data.map(item => <ConsultantCard key={item.uid} data={item} />)}
        </Grid>
      </Block>
      <ModalDialog title="Contact NCS" size="xl" isOpen={showContactForm} onClose={() => setShowContactForm(false)}>
        <ContactForm onSubmit={handleSubmit} initialValues={{from: profileData?.mail || ''}} />
      </ModalDialog>
    </Layout>
  );
};
