import { createSlice, createAsyncThunk } from "@reduxjs/toolkit" 
import courseService from "./courseService"
import {handleApiError} from "../auth/authSlice";

const initialState = {
    // Whole API response which contains 'items', 'page', 'items_per_page' and 'total'.
    // @todo Is it really needed at the moment to store othen than 'items' data?
    data: {},
    courseIsError: false,
    courseIsSuccess: false,
    courseIsLoading: false,
    courseModuleIsError: false,
    courseModuleIsSuccess: false,
    courseModuleIsLoading: false,
    moduleData: {},
    courseMessage: '',
    courseActivityIsError: false,
    courseActivityIsSuccess: false,
    courseActivityIsLoading: false,
}

export const getCourseInfo = createAsyncThunk('course/getCourseInfo', async (arg, thunkAPI) => {
    
    try {
        return await courseService.getCourseInfo();
    } catch (error) {
        return handleApiError(thunkAPI, error);
    }
})

export const getModuleInfo = createAsyncThunk('course/getModuleInfo', async (values, thunkAPI) => {
    try {
        return await courseService.getModuleInfo(values)
    } catch (error) {
        return handleApiError(thunkAPI, error);
    }
})

export const courseSlice = createSlice ({
    name: 'course',
    initialState,
    reducers: {
        // @todo This reducer is used in just in one place. Is it really needed?
        resetCourse: (state) => {
            state.data = {}
            state.courseIsError = false
            state.courseIsLoading = false
            state.courseIsSuccess = false
            state.courseModuleIsError = false
            state.courseModuleIsLoading = false
            state.courseModuleIsSuccess = false
            state.moduleData = {}
            // @todo What is message? There is no such property in default state.
            state.message = ''
            state.courseActivityIsError =  false
            state.courseActivityIsSuccess = false
        },
        resetMessage: (state) => {
            state.message = ''
            state.courseActivityIsError = false
            state.courseModuleIsError = false
            state.courseIsError = false
            state.courseIsSuccess = false
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getCourseInfo.pending, (state) => {
            state.courseIsLoading = true
        })
        .addCase(getCourseInfo.fulfilled, (state, action) => {
            state.courseIsLoading = false
            state.courseIsSuccess = true
            state.data = action.payload
        })
        .addCase(getCourseInfo.rejected, (state, action) => {
            state.courseIsLoading = false
            state.courseIsError = true
            state.courseMessage = action.payload.message
        })
        .addCase(getModuleInfo.pending, (state) => {
            state.courseModuleIsLoading = true
        })
        .addCase(getModuleInfo.fulfilled, (state, action) => {
            state.courseModuleIsLoading = false
            state.courseModuleIsSuccess = true
            state.moduleData = action.payload
        })
        .addCase(getModuleInfo.rejected, (state, action) => {
            state.courseModuleIsLoading = false
            state.courseModuleIsError = true
            state.courseMessage = action.payload.message
        })
    }
})

export const { resetCourse, resetMessage } = courseSlice.actions
export default courseSlice.reducer