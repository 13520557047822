import {createContext, useState} from "react";

export const VoiceoverContext = createContext({});

export const VoiceoverContextProvider = ({children}) => {
  const [voiceover, setVoiceover] = useState({});

  return <VoiceoverContext.Provider value={{voiceover, setVoiceover}}>
    {children}
  </VoiceoverContext.Provider>
}
