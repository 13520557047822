import { Flex, Heading, Menu, Button, Grid, Text, Progress, Box, MenuButton, MenuList } from "@chakra-ui/react";
import ChevronDown from '../../icons/ChevronDown';
import { useSelector, useDispatch } from 'react-redux'
import parse from 'html-react-parser';
import SegmentCard from "./SegmentCard";
import {ModuleLink} from "./ModuleNav";
import {fetchCourse, getCourse} from "../../features/course/courseContentSlice";
import {useEffect} from "react";
import DropdownMenu from "components/menu/DropdownMenu";

export default function Module({data}) {
  const dispatch = useDispatch();
  const courseId = data.course_id;
  const courseData = useSelector(state => getCourse(state, courseId));

  useEffect(() => {
    if (courseId && courseData && !courseData.isSuccess && !courseData.isLoading) {
      dispatch(fetchCourse(courseId));
    }
  }, [courseId]);

    const printMenuLinks = () => {
        return courseData.content?.map((mod) => <ModuleLink key={mod.id} contentId={mod.id}/> )
    }

    const printSegments = () => {
        // @todo Don't assume that module's children are segments.
        return data.children?.map(segment => <SegmentCard key={segment.id} id={segment.id}/>)
    }

   
    
    return (
        <>
            <Flex alignItems={'flex-start'} direction={'column'}>
                
                    <Menu pl={0} ml={0} mr={0}>
                        <MenuButton mx={0} ml={0} pl={0} color={'primary.navy'} _active={{bg: 'none'}} _hover={{bg: 'none'}} mr={0} as={Button} bg={'none'} rightIcon={<ChevronDown />}>
                            <Heading color={'primary.navy'} fontSize={{base: '18px', lg: '24px'}}>{data.content?.title}</Heading>
                        </MenuButton>
                        <DropdownMenu>
                            <Flex direction={'column'} gap={2}>
                                {courseData && printMenuLinks()}

                            </Flex>
                        </DropdownMenu>
                       
                    </Menu>
                    <Text mb={{base: '16px', lg: '24px'}} as="b" color={'primary.navy'} >  
                        {data.content?.subtitle}
                    </Text>
               
                <Box color={'primary.navy'} css={{
                        p: { marginBottom: "16px" },
                    }}>
                    {data.content?.description?.value &&
                    parse(data.content?.description?.value)}
                </Box>
                <Progress width={'100%'} boxShadow='inner' value={Math.ceil(data.progress?.complete * 100)} colorScheme={'orange'} borderRadius={'24px'} />
                <Text fontSize={'sm'} mt={2} mb={4} color={'primary.navy'}>{Math.ceil(data.progress?.complete * 100)}% Complete</Text>
            </Flex>
            <Grid gap={4} >
                {printSegments()}
            </Grid>
           
        </>
    )
}