import ChevronRightSquare from "icons/ChevronRightSquare";
import {NavLink} from "react-router-dom";
import {cn} from "app/helpers";

export default function ViewAllLink({to, label = 'View all'}) {
  const classes = cn('link');

  return <NavLink className={classes({
    'view-all': true,
  })} to={to}>
    {label}
    <ChevronRightSquare/>
  </NavLink>
}
