import {useEffect, useState} from "react";
import {fetchLikes, getLikeByEntity, getLikesByEntity} from "./likesSlice";
import {useDispatch, useSelector} from "react-redux";
import {LoadBatch} from "app/LoadBatch";

const likeBatches = {};
export function useLikeData(entityType, entityId, reload = false) {
  likeBatches[entityType] = likeBatches[entityType] || new LoadBatch();
  const dispatch = useDispatch();
  const like = useSelector(state => getLikeByEntity(state, entityType, entityId));
  const batch = likeBatches[entityType];
  const [isLoaded, setLoaded] = useState(batch.isLoaded(entityId));
  const isLoading = batch.isBatched(entityId) || batch.isLoading(entityId);
  const isSuccess = isLoaded || like;

  useEffect(() => {
    if (reload || !isSuccess) {
      batch.add(entityId, {
        onLoad: () => {
          setLoaded(batch.isLoaded(entityId));
        },
      });

      setTimeout(() => {
        const ids = batch.next();

        if (ids.length > 0) {
          dispatch(fetchLikes({
            'entity_type': entityType,
            'id': ids,
          })).then(() => {
            batch.load(ids);
          });
        }
      }, 30);
    }
  }, [dispatch, batch, entityType, entityId, reload]);

  return [like, isLoading, isSuccess];
}

export function useLikesData(params) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [ids, setIds] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const likes = useSelector(state => getLikesByEntity(state, params.entity_type, ids));

  useEffect(() => {
    if (!loading && !loaded) {
      setLoading(true);
      dispatch(fetchLikes(params)).then((action) => {
        const data = action.payload;
        const loadedIds = [];
        if (data?.items) {
          data.items.forEach((item) => {
            loadedIds.push(item.entity_id);
          });
        }
        setIds(loadedIds);
        setPage(data.page);
        setTotal(data.total);
        setLoading(false);
        setLoaded(true);
      });
    }
  }, [dispatch, params]);

  return [likes, page, total];
}
