import React, {useEffect, useId, useRef, useState} from 'react';
import {cn} from "app/helpers";
import {VerticalVolume} from 'shaka-player-ui-controls';
import 'shaka-player/dist/controls.css';
import 'shaka-player-ui-controls/dist/main.css';
import Controls from "./Controls/Controls";
import "styles/components/media/_video-player.scss";
import {useDispatch, useSelector} from "react-redux";
import {getCanPlay, getMediaUserSettings, setActiveMedia} from "features/media/mediaSlice";
const shaka = require('shaka-player/dist/shaka-player.ui.js');

shaka.ui.Controls.registerElement(
  'vertical_volume',
  new VerticalVolume.Factory()
);

const VideoPlayer = ({
                       dash,
                       hls,
                       variant = 'default',
                       controls = true,
                       allowFullscreen = true,
                       poster,
                       captions,
                       autoPlay,
                       timestamp,
                       style,
                       onError,
                       onPause,
                       onTimeChange,
                       onComplete,
                       toggleFullscreen,
                       isMain = true,
                     }) => {
  const dispatch = useDispatch();
  const playerId = useId();
  const videoContainer = useRef(null);
  const [videoComponent, setVideoComponent] = useState(null);
  const classes = cn('video-player');
  const variants = {};
  const userSettings = useSelector(state => getMediaUserSettings(state));
  autoPlay = autoPlay && (!controls || userSettings.autoPlay);
  const canPlay = useSelector(state => getCanPlay(state, playerId, {
    isMain,
    autoPlay,
  }));
  const dashManifests = Array.isArray(dash) ? dash : [];
  const hlsManifests = Array.isArray(hls) ? hls : [];

  const [shakaPlayer, setShakaPlayer] = useState();
  const [shakaControls, setShakaControls] = useState();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();
  const controlsSettings = {
    allowFullscreen,
    toggleFullscreen: typeof toggleFullscreen === 'function' ? toggleFullscreen : (shakaControls && shakaControls.toggleFullScreen.bind(shakaControls)),
  };

  const handleError = (e) => {
    const error = e.detail;
    // Log the error.
    console.error('Error code', error.code, 'object', error);
    onError && onError(error);
  }

  const handlePlaying = (e) => {
    if (isMain) {
      dispatch(setActiveMedia({
        playerId,
        isPlaying: true,
      }));
    }
  }

  const handlePause = (e) => {
    if (videoComponent) {
      if (isMain) {
        dispatch(setActiveMedia({
          playerId,
          isPlaying: false,
        }));
      }

      onPause && onPause(videoComponent);
    }
  }

  const handleTimeChange = (e) => {
    if (videoComponent) {
      onTimeChange && onTimeChange(videoComponent);

      if (videoComponent.currentTime >= videoComponent.duration) {
        handleComplete();
      }
    }
  }

  const handleComplete = () => {
    if (videoComponent) {
      onComplete && onComplete(videoComponent);
    }
  }

  const initQueue = async() => {
    const support = await shaka.Player.probeSupport();
    let queue = [];
    if (support.manifest.mpd) {
      queue = queue.concat(dashManifests, hlsManifests);
    } else {
      queue = queue.concat(hlsManifests, dashManifests);
    }
    queue = Array(10).fill(queue).flat();

    return queue;
  }

  const loadVideo = (player, video, queue) => {
    const manifestUri = queue.shift();

    if (manifestUri) {
      setErrorMessage(null);
      setLoading(true);

      player.load(manifestUri).then(function () {
        setErrorMessage(null);
        setLoading(false);
        console.log('The video has now been loaded.');
      }).catch((error) => {
        if (error.code !== 7000) { // loading was not cancelled
          console.error(`Error loading ${manifestUri}`);

          if (queue.length > 0 && error.severity === shaka.util.Error.Severity.CRITICAL) {
            setTimeout(() => {
              loadVideo(player, video, queue);
            }, 1000);
          }
          else {
            console.log(error);
            setErrorMessage(<p>Error #{error.code} while loading the video. Please try again later.</p>);
            setLoading(false);
            onError && onError(error);
          }
        }
      }).then(() => {
        video.volume = userSettings.volume;
        video.muted = userSettings.muted;
        if (timestamp) {
          video.currentTime = timestamp;
        }

        if (captions) {
          player.addTextTrackAsync(captions.uri, captions.lang, 'captions').then((track) => {
            player.selectTextTrack(track);
            player.setTextTrackVisibility(true);
            player.setTextTrackVisibility(userSettings.captions);
          });
        }
      });
    }
  }

  useEffect(() => {
    const container = videoContainer.current;

    if (videoComponent) {
      if (!allowFullscreen || toggleFullscreen) {
        container.requestFullscreen = () => {
          toggleFullscreen && toggleFullscreen();
        };
      }

      //Initialize shaka player
      const player = new shaka.Player(videoComponent);
      setShakaPlayer(player);

      //Setting UI configuration JSON object
      const uiConfig = {};

      //Configuring elements to be displayed on video player control panel
      uiConfig['enableFullscreenOnRotation'] = false;
      uiConfig['addSeekBar'] = false;
      uiConfig['controlPanelElements'] = [];
      uiConfig['addBigPlayButton'] = true;
      uiConfig['seekBarColors'] = {
        base: '#E2E2E2',
        buffered: '#E2E2E2',
        played: '#FF8B65',
      };

      //Setting up shaka player UI
      const ui = new shaka.ui.Overlay(player, container, videoComponent);

      ui.configure(uiConfig); //configure UI
      setShakaControls(ui.getControls());

      player.addEventListener('error', handleError);
      videoComponent.addEventListener('timeupdate', handleTimeChange);
      videoComponent.addEventListener('play', handlePlaying);
      videoComponent.addEventListener('pause', handlePause);

      initQueue().then((queue) => {
        loadVideo(player, videoComponent, queue);
      });

      return () => {
        if (player) {
          if (isMain) {
            dispatch(setActiveMedia({
              playerId,
              isPlaying: false,
            }));
          }

          player.unload();
          player.destroy();
        }
      }
    }
  }, [videoComponent]);

  useEffect(() => {
    if (shakaPlayer) {
      shakaPlayer.setTextTrackVisibility(userSettings.captions);
    }
  }, [shakaPlayer, userSettings]);

  useEffect(() => {
    if (videoComponent) {
      if (canPlay) {
        if (videoComponent.paused && autoPlay) {
          videoComponent.play();
        }
        else if (!videoComponent.paused && !autoPlay) {
          videoComponent.pause();
        }
      }
      else if (!videoComponent.paused && autoPlay) {
        videoComponent.pause();
      }
    }
  }, [autoPlay, canPlay, videoComponent]);

  variants[variant] = true;
  variants['loading'] = loading;

  return <div className={classes(variants)} style={style}>
    <div className={classes('container')} ref={videoContainer}>
      {errorMessage && <div className={classes('error')}>
        {errorMessage}
      </div>}
      <div className={classes('wrapper')}>
        <video
          className="shaka-video"
          ref={setVideoComponent}
          poster={poster}
          autoPlay={canPlay && autoPlay}
          playsInline
        />
      </div>
      {controls &&
      <Controls media={videoComponent}
                allowFullscreen={controlsSettings.allowFullscreen}
                toggleFullscreen={controlsSettings.toggleFullscreen}
                captionsButton={variant === 'small' ? false : 'default'}
      />
      }
    </div>
  </div>
}

export default VideoPlayer;
