import VideoPlayer from "../media/VideoPlayer";
import {useContext, useEffect, useState} from "react";
import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import posterImage from '../../img/NCS-Pattern.svg'
import {ActivityContext} from "components/course/Activity/ActivityContext";
import ActivityFooter from 'components/course/Activity/ActivityFooter';

export default function Video({data}) {
  const {submit, onNext} = useContext(ActivityContext);
  const {id, content, progress, answer} = data;
  const isComplete = progress?.complete === 1;
  const media = content?.content?.video;
  const [timestamp, setTimestamp] = useState( isComplete ? null : answer?.value);
  const [canSubmit, setCanSubmit] = useState(progress?.complete === 1);
  const {setFocused} = useContext(ActivityContext);
  const thumbnail = posterImage;

  const saveTimestamp = (timestamp, goNext = false) => {
    if (isComplete) {
      timestamp = content?.duration;
    }

    if (timestamp > 0) {
      goNext ? onNext(timestamp) : submit(timestamp);
    }
  }

  const onPause = (video) => {
    const timestamp = Math.round(video.currentTime);
    setTimestamp(timestamp);
  };

  const onTimeChange = (video) => {
    if (answer?.value >= (video.duration - 1)) {
      if (content?.duration) {
        setTimestamp(Math.round(content.duration));
      }
      setCanSubmit(true);
    }
  };

  const onComplete = () => {
    if (content?.duration) {
      setTimestamp(Math.round(content.duration));
    }
    setCanSubmit(true);
    setFocused(true);
  };

  useEffect(() => {
    if (!isComplete && timestamp) {
      saveTimestamp(timestamp);
    }
  }, [timestamp])

  // Video player has to be re-mounted when media changes.
  return <ActivityContext.Provider value={{
    ...useContext(ActivityContext),
    canSubmit,
    onNext: () => {
      saveTimestamp(timestamp, true);
    },
  }}>
    {content?.description && content?.description.length > 0 ?
    <ActivityWrapper description={content.description} align={'center'}>
      <VideoPlayer key={`activity-${id}`} id={id} variant={'small'} hls={media.hls} dash={media.dash} poster={thumbnail} onPause={onPause}
                   onTimeChange={onTimeChange} onComplete={onComplete} timestamp={timestamp} autoPlay={true}/>
    </ActivityWrapper> :
    <>
      <VideoPlayer key={`activity-${id}`} id={id} hls={media.hls} dash={media.dash} poster={thumbnail} onPause={onPause}
                 onTimeChange={onTimeChange} onComplete={onComplete} timestamp={timestamp} autoPlay={true}/>
      <ActivityFooter/>
    </>}
  </ActivityContext.Provider>
}
