import {Box, Text} from '@chakra-ui/react';
import {cn} from "app/helpers";
import ActivityFooter from "components/course/Activity/ActivityFooter";
import RichText from "components/Activities/RichText";

export default function ActivityWrapper({
                                          title,
                                          description,
                                          aside,
                                          children,
                                          align = 'start',
                                          classNames = [],
                                        }) {
  const classes = cn('activity');

  return (
    <>
      <Box className={classes('wrapper', {'has-aside': !!aside}, classNames)}>
        <Box className={classes('content')}>
          <Box className={classes('inner', {align})}>
            {title && <Text className={classes('title')}>{title}</Text>}
            {/* @todo Fix backend - sometimes it returns object for empty description for some reason */}
            <RichText className={classes('description')} text={description}/>
            {children}
          </Box>
        </Box>
        {aside &&
        <Box className={classes('aside')}>
          <Box className={classes('aside-inner')}>
            {aside}
          </Box>
        </Box>}
      </Box>
      <ActivityFooter/>
    </>
  );
};
