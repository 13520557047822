import {combineReducers, configureStore} from '@reduxjs/toolkit'
import authReducer, {logout} from '../features/auth/authSlice'
import profileReducer from '../features/profile/profileSlice'
import generalReducer from '../features/general/generalSlice'
import notesReducer from '../features/notes/notesSlice'
import courseReducer from '../features/course/courseSlice'
import courseContentReducer from '../features/course/courseContentSlice'
import likesReducer from "../features/like/likesSlice";
import resourcesReducer from "../features/resources/resourcesSlice";
import encouragementsReducer from "../features/encouragements/encouragementsSlice";
import blocksReducer from "../features/block/blockSlice";
import mediaReducer from "../features/media/mediaSlice";
import consultantsReducer from "../features/consultants/consultantsSlice";
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

const reducers = {
  auth: authReducer,
  profile: profileReducer,
  general: generalReducer,
  notes: notesReducer,
  course: courseReducer,
  courseContent: courseContentReducer,
  likes: likesReducer,
  resources: resourcesReducer,
  blocks: blocksReducer,
  media: mediaReducer,
  encouragements: encouragementsReducer,
  consultants: consultantsReducer,
};

const rootReducer = combineReducers(reducers);
const resettableRootReducer = (state, action) => {
  if (action.type === 'store/reset') {
    // Clear caches.
    serviceWorkerRegistration.clearCaches();
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

const authMiddleware = ({dispatch, getState}) => {
  return next => action => {
    let result;

    if (typeof action === 'function') {
      return action(dispatch, getState)
    }

    result = next(action);
    if (result?.meta?.requestStatus === 'rejected') {
      const error = result.payload;

      // Logout.
      if (error?.status === 401) {
        dispatch(logout());
      }
    }

    if (result?.type === 'auth/logout/pending') {
      // Reset store.
      dispatch({type: 'store/reset'});
    }

    return result;
  }
}

export const store = configureStore({
  reducer: resettableRootReducer,
  middleware: [
    authMiddleware,
  ],
});
