import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  fetchCourse,
  fetchCourseContent, fetchCourseContents,
  fetchCourses,
  getCourse,
  getCourseContent,
  getCourses
} from "./courseContentSlice";

let coursesLoading = false;
export function useCoursesData() {
  const dispatch = useDispatch();
  const courses = useSelector(state => getCourses(state));
  const {coursesLoaded} = useSelector(state => state.courseContent);

  useEffect(() => {
    if (!coursesLoading && !coursesLoaded) {
      coursesLoading = true;
      dispatch(fetchCourses()).then((action) => {
        coursesLoading = false;
      });
    }
  }, [dispatch, coursesLoaded]);

  return [courses, coursesLoaded];
}

const courseLoading = [];
export function useCourseData(id) {
  const dispatch = useDispatch();
  const course = useSelector(state => getCourse(state, id));

  useEffect(() => {
    const isLoading = coursesLoading || courseLoading.includes(id) || course.isLoading;

    if (!isLoading && !course.isSuccess && !course.isError) {
      courseLoading.push(id);
      dispatch(fetchCourse(id));
    }
  }, [dispatch, id, course]);

  return course;
}

const courseContentLoad = [];
const courseContentLoading = [];
export function useCourseContentData(id, batch = true) {
  id = parseInt(id);
  const dispatch = useDispatch();
  const content = useSelector(state => getCourseContent(state, id));

  useEffect(() => {
    const isLoading = courseContentLoading.includes(id) || content.isLoading;
    const isLoaded = content.isSuccess || content.isError;
    const needsLoad = !isLoading && !isLoaded;

    if (id && needsLoad) {
      courseContentLoading.push(id);
      courseContentLoad.push(id);

      setTimeout(() => {
        if (courseContentLoad.length === 1 || !batch) {
          const id = courseContentLoad.pop();
          dispatch(fetchCourseContent(id)).then(() => {
            const index = courseContentLoading.indexOf(id);
            if (index > -1) {
              courseContentLoading.splice(index, 1);
            }
          });
        }
        else if (courseContentLoad.length > 1) {
          const ids = courseContentLoad.splice(0, 20).sort((a, b) => {
            return a - b;
          });
          dispatch(fetchCourseContents(ids)).then(() => {
            ids.forEach(id => {
              const index = courseContentLoading.indexOf(id);
              if (index > -1) {
                courseContentLoading.splice(index, 1);
              }
            });
          });
        }
      }, 30);
    }
  }, [dispatch, id, content]);

  return content;
}
