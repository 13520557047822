import {useState, useCallback, useContext} from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import OutlineButton from '../../buttons/OutlineButton';
import {ActivityContext} from "components/course/Activity/ActivityContext";

function GroupedOptionsGrid({ groups, values, onCellClick }) {
  const [showAll, setShowAll] = useState(false);
  const rowsLimit = 11;
  const maxRows = groups.reduce(
    (max, group) => group.options?.length > max ? group.options.length : max,
    0
  );

  return (
    <>
      <Box className='parse-html' mt={8}>
        <table>
          <thead>
          <tr>
            {groups.map((col, index) => {
              return <th key={index}>{col?.group}</th>;
            })}
          </tr>
          </thead>
          <tbody>
          {Array.from({length: showAll || (maxRows <= rowsLimit)  ? maxRows : rowsLimit}).map((_, rowIndex) => (
            <tr key={rowIndex}>
              {groups.map((col, colIndex) => {
                const isSelected = !!values[`${rowIndex}_${colIndex}`];

                return (
                  <td
                    key={colIndex}
                    onClick={col.options[rowIndex] ? () => onCellClick(rowIndex, colIndex) : null}
                    className={isSelected ? 'selected' : ''}
                  >
                    {col.options[rowIndex] || ''}
                  </td>
                );
              })}
            </tr>
          ))}
          </tbody>
        </table>
      </Box>

      {(maxRows > rowsLimit) &&
        <Box mt={4}>
          <OutlineButton txColor={'white'} bgColor={'primary.navy'} copy={showAll ? 'Show less' : 'Show more'} onClick={() => setShowAll(!showAll)} />
        </Box>
      }
    </>
  );
}

function GroupedOptionsResults({data, answers}) {
  return (
    <Flex mt={6} gap={4} direction={{base: 'column', md: 'row'}}>
      {answers.map((item, index) => (
        <Flex w={'100%'} direction={'column'} key={item.group}>
          <Text w={'100%'} mb={2} color={'secondary.tangerine'} as={'b'}>{data.content?.content?.groups[index].group_summary_name }</Text>
          {item.answers.map((answer) => (
            <Text mb={2} color={'white'} key={answer}>{answer}</Text>
          ))}
        </Flex>
      ))}
    </Flex>
  );
}

export default function GroupedOptions({data}) {
  const {onPrev, onNext} = useContext(ActivityContext);
  const { content } = data;
  const [page, setPage] = useState(0);

  const groups = content?.content?.groups || [];
  const minAnswers = content?.content?.min || 0;

  const savedValues = data.answer?.value || [];
  const getDefaultValues = useCallback(() => {
    const defaultValues = {};

    groups.forEach((group, groupIndex) => {
      const savedGroup = savedValues.find(item => item.group === group.group);
      if (!savedGroup) {
        return;
      }

      const savedAnswers = savedGroup.answers || [];
      if (!savedAnswers.length) {
        return;
      }

      const options = group.options || [];
      options.forEach((option, optionIndex) => {
        if (savedAnswers.find(answer => answer === option)) {
          defaultValues[`${optionIndex}_${groupIndex}`] = true;
        }
      });
    });

    return defaultValues;
  }, [data]);

  const [values, setValues] = useState(getDefaultValues());
  const answers = groups.map((group, groupIndex) => {
    return {
      group: group.group,
      answers: group.options?.filter((option, optionIndex) => !!values[`${optionIndex}_${groupIndex}`]) || [],
    };
  });

  const isValid = answers.filter(group => group.answers.length >= minAnswers).length === groups.length;

  const handleCellClick = (rowIndex, colIndex) => {
    const key = `${rowIndex}_${colIndex}`;
    setValues(prevValues => ({...prevValues, [key]: !prevValues[key]}));
  };

  const handlePrev = () => {
    if (page > 0) {
      setPage(page - 1);
    }
    else {
      onPrev();
    }
  }

  const handleNext = () => {
    if (page === 0) {
      setPage(1);
    }
    else {
      onNext(answers);
    }
  }

  return (
    <ActivityContext.Provider value={{
      ...useContext(ActivityContext),
      canSubmit: isValid,
      onPrev: handlePrev,
      onNext: handleNext,
      totalPages: 2,
      canNavigate: () => true,
      currentPage: page,
      setCurrentPage: setPage,
    }}>
      <ActivityWrapper title={content?.title} description={page === 1 ? content?.content?.summary_description : content?.description}>
        {page === 0 &&
          <GroupedOptionsGrid groups={groups} values={values} onCellClick={handleCellClick} />
        }
        {page === 1 &&
          <GroupedOptionsResults data={data} answers={answers} />
        }
      </ActivityWrapper>
    </ActivityContext.Provider>
  );
}
