function VisibilityShow() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <mask
          id="mask0_210_18966"
          style={{ maskType: "alpha" }}
          width="24"
          height="24"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
        </mask>
        <g mask="url(#mask0_210_18966)">
          <path
            fill="#0C1851"
            d="M15.775 12.975L14.65 11.85c.15-.833-.087-1.579-.712-2.237-.625-.659-1.388-.913-2.288-.763l-1.125-1.125c.233-.1.467-.175.7-.225.233-.05.492-.075.775-.075 1.133 0 2.096.396 2.887 1.188.792.791 1.188 1.754 1.188 2.887 0 .283-.025.546-.075.787-.05.242-.125.471-.225.688zm3.175 3.1l-1.1-1.025a10.957 10.957 0 001.688-1.588A8.9 8.9 0 0020.8 11.5c-.833-1.683-2.03-3.021-3.588-4.013C15.654 6.496 13.917 6 12 6c-.483 0-.958.033-1.425.1a9.622 9.622 0 00-1.375.3L8.025 5.225c.633-.25 1.28-.433 1.938-.55A11.648 11.648 0 0112 4.5c2.35 0 4.467.646 6.35 1.938 1.883 1.291 3.25 2.979 4.1 5.062-.367.9-.842 1.737-1.425 2.512a10.35 10.35 0 01-2.075 2.063zm.8 5.8l-4.025-4.025a9.65 9.65 0 01-1.712.475c-.625.117-1.296.175-2.013.175-2.35 0-4.463-.646-6.338-1.938-1.875-1.291-3.245-2.979-4.112-5.062a11.1 11.1 0 011.425-2.475A10.84 10.84 0 014.9 7.05l-2.775-2.8 1.05-1.075 17.65 17.65-1.075 1.05zM5.95 8.1a10.06 10.06 0 00-1.537 1.513A8.945 8.945 0 003.2 11.5c.833 1.683 2.029 3.02 3.587 4.012C8.346 16.504 10.083 17 12 17c.45 0 .9-.038 1.35-.113.45-.075.833-.154 1.15-.237l-1.25-1.3a3.877 3.877 0 01-1.25.225c-1.133 0-2.096-.396-2.887-1.188-.792-.791-1.188-1.754-1.188-2.887 0-.2.021-.413.063-.638a3.81 3.81 0 01.162-.612L5.95 8.1z"
          ></path>
        </g>
      </svg>
    );
  }
  
  export default VisibilityShow;