import { Grid, GridItem, Text } from '@chakra-ui/react';
import { ReactComponent as NoticeIcon } from '../../icons/NoticeIcon.svg';

export default function Notice({children}) {
  return (
    <Grid templateColumns='20px auto' gap={2} mt={4}>
      <GridItem>
        <NoticeIcon/>
      </GridItem>
      <GridItem>
        <Text color='other.notice'>
          {children}
        </Text>
      </GridItem>
    </Grid>
  );
};
