import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Image } from '@chakra-ui/react';
import {cn} from 'app/helpers';
import { useCoursesData } from 'features/course/courseHooks';
import Block from "../Block";
import VideoPlayer from 'components/media/VideoPlayer';
import ReplayIcon from 'icons/ReplayIcon';
import CloseIcon from 'icons/CloseIcon';

const classes = cn('block');

function IntroVideoCta({media}) {
  const navigate = useNavigate();
  const [courses] = useCoursesData();
  const [canSubmit, setCanSubmit] = useState(false);

  const onTimeChange = (video) => {
    if (!canSubmit && (video.currentTime >= video.duration - 1)) {
      setCanSubmit(true);
    }
  };

  const onComplete = () => {
    setCanSubmit(true);
  };

  const goToCourse = () => {
    const course = courses.find(course => !course.locked);
    if (!course) {
      return;
    }

    const module = course.content?.find(child => !child.locked);
    if (!module) {
      navigate(`/course/${course.id}`);
      return;
    }

    const segment = module.children?.find(child => !child.locked);
    navigate(`/course/content/${segment ? segment.id : module.id}`);
  };

  return <Block className={classes({ type: 'intro-video-cta'})}>
    <div className={classes('video')}>
      <VideoPlayer hls={media.hls} dash={media.dash} poster={media.thumbnail?.url} onTimeChange={onTimeChange} onComplete={onComplete} autoPlay={false} />
    </div>
    <div className={classes('actions')}>
      <Button variant="solidOrange" isDisabled={!canSubmit} onClick={goToCourse}>Begin Course</Button>
    </div>
  </Block>
}

function IntroVideoReplay({title, media}) {
  const thumbnail = media.thumbnail?.url ? media.thumbnail : null;
  const [play, setPlay] = useState(false);

  return <Block className={classes({ type: 'intro-video-replay', collapsed: !play })}>
    {title && <h2 className={classes('title')}>{title}</h2>}

    <div className={classes('video-thumbnail')}>
      {!play && thumbnail && <Image src={thumbnail.url} alt={thumbnail.alt} htmlWidth={thumbnail.width} htmlHeight={thumbnail.height} loading='lazy' />}
      {!play && <div
        className={classes('video-overlay', { 'no-thumbnail': !thumbnail })}
        role="button" aria-label="Play video" onClick={() => setPlay(true)}>
        <div className={classes('overlay-icon')}>
          <ReplayIcon />
        </div>
      </div>}
    </div>

    {play && <div className={classes('video')}>
      <Box as='button' className={classes('overlay-icon')} onClick={() => setPlay(false)} aria-label='Close video'>
        <CloseIcon color="white" variant="plain" />
      </Box>
      <VideoPlayer hls={media.hls} dash={media.dash} poster={media.thumbnail?.url} autoPlay={true} />
    </div>}
    <div className={classes('actions')}>
      <Button variant={'outlineNavy'} leftIcon={<ReplayIcon />} onClick={() => setPlay(true)}>Replay video</Button>
    </div>
  </Block>
}

export default function IntroVideoBlock({data}) {
  const media = data.content?.video;
  // @todo Add some boolean flag or even separate block type on the backend so the component appearance doesn't change depending on region.
  const region = data.region || 'cta';

  if (!media) {
    return null;
  }

  return region === 'cta' ? <IntroVideoCta media={media} /> : <IntroVideoReplay title={data.title} media={media} />;
}
