import { Fragment, useContext, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { cn } from 'app/helpers';
import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import {ActivityContext} from "components/course/Activity/ActivityContext";

const classes = cn('editable-table');

export default function SummaryTableMulti({ data }) {
  const { content } = data;
  const items = content?.content?.items || [];

  const columnNames = items.map(item => item.title);

  const rowNames = useMemo(() => {
    const references = items.map(item => item.content).flat();
    const labels = references.map(reference => {
      switch (reference.content?.type) {
        case 'open_question_multi':
          return reference.content?.content?.items?.map(item => item.label) || [];

        case 'editable_table':
          return reference.content?.content?.column_names || [];

        default:
          return [];
      }
    }).flat()
      // Leave unique labels.
      .filter((label, index, array) => array.indexOf(label) === index);

    return labels;
  }, [data]);

  const rows = useMemo(() => {
    const rows = rowNames.map(() => Array(columnNames.length).fill([]));

    rowNames.forEach((name, rowIndex) => {
      items.forEach((item, colIndex) => {
        const references = item.content || [];

        references.forEach(reference => {
          const answers = reference.answer?.value || [];
          // Open Question Multi stores name in `item`, Editable Table - in `column`.
          const values = answers
            .filter(answer => answer.item === name || answer.column === name)
            .map(answer => answer.values || [])
            .flat();

          rows[rowIndex][colIndex] = rows[rowIndex][colIndex].concat(values);
        });
      });
    });

    return rows;
  }, [data]);

  // Mimic Editable Table (with references) so CSS styles are re-used.
  // @todo Avoid the need to wrap tables into .parse-html to apply styles.
  return (
    <ActivityContext.Provider value={{
      ...useContext(ActivityContext),
      canSubmit: true,
    }}>
      <ActivityWrapper
        title={content?.title}
        description={content?.description}
        classNames={['editable-table', 'editable-table--with-reference']}
      >
        <Box className='parse-html'>
          <table>
            <thead>
            <tr>
              <td></td>
              {columnNames.map(((name, index) => <th key={index}>{name}</th>))}
            </tr>
            </thead>
            <tbody>
            {rowNames.map((name, rowIndex) => {
              const maxRows = rows[rowIndex].reduce(
                (max, values) => values.length > max ? values.length : max,
                1
              );
              const isLastRow = rowIndex === (rowNames.length - 1);

              return (Array.from({ length: maxRows }).map((_, valIndex) => (
                <tr key={`${rowIndex}_${valIndex}`}>
                  {valIndex === 0 && <th rowSpan={maxRows} className={classes('cell', {last: isLastRow})}>{name}</th>}
                  {columnNames.map((_, colIndex) => {
                    const values = rows[rowIndex][colIndex];
                    const isLastCol = colIndex === (columnNames.length - 1);

                    return (
                      <Fragment key={colIndex}>
                        {(valIndex < values.length) && <td className={classes('cell', {last: isLastRow && isLastCol && valIndex === (maxRows - 1)})}>{values[valIndex]}</td>}
                        {(valIndex === values.length) && <td rowSpan={maxRows - values.length} className={classes('cell', {last: isLastRow && isLastCol})}></td>}
                      </Fragment>
                    );
                  })}
                </tr>
              )));
            })}
            </tbody>
          </table>
        </Box>
      </ActivityWrapper>
    </ActivityContext.Provider>
  );
}
