function MenuIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="12"
        fill="none"
        viewBox="0 0 18 12"
      >
        <path
          fill="#0C1851"
          d="M.25 11.625v-1.5h17.5v1.5H.25zm0-4.875v-1.5h17.5v1.5H.25zm0-4.875v-1.5h17.5v1.5H.25z"
        ></path>
      </svg>
    );
  }
  
  export default MenuIcon;