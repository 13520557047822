import authService from "../auth/authService";

const getEncouragements = async (params) => {
  const response = await authService.getClient().request({
    url: `/api/v1/encouragements`,
    method: 'GET',
    params: params,
  });

  return response.data;
}

const encouragementsService = {
  getEncouragements,
}

export default encouragementsService
