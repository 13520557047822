import {Navigate, Outlet} from 'react-router-dom'
import {useSelector} from "react-redux";

export const RequireAuth = ({children}) => {
  const {user} = useSelector((state) => state.auth);

  if (!user) {
    window.location.href = '/login';
    return <Navigate to='/login' />;
  }

  return children ? children : <Outlet />;
}
