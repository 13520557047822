import {cn} from "../../app/helpers";
import LockedIcon from "../../icons/LockedIcon";
import {Text} from "@chakra-ui/react";

export default function LockedLabel() {
  const classes = cn('label');
  return <div className={classes({'locked': true})}>
    <LockedIcon/>
    <Text>Locked</Text>
  </div>
}