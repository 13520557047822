import {cn} from "../../../app/helpers";
import SettingsIcon from "../../../icons/SettingsIcon";

export default function SettingsButton({showSettings, setShowSettings}) {
  const classes = cn('player-controls');
  const handleClick = (e) => {
    e.preventDefault();
    setShowSettings && setShowSettings(!showSettings);
  }

  return <button className={classes('button', {'settings': true})} onClick={handleClick}><SettingsIcon/></button>
}
