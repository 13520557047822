import {useContext, useEffect, useState} from "react";
import {Box, useBreakpointValue} from "@chakra-ui/react";
import RichText from "./RichText";
import ActivityWrapper from 'components/course/Activity/ActivityWrapper';
import "styles/components/course/_steps.scss";
import {VoiceoverContext} from "components/media/VoiceoverContext";
import {ActivityContext} from "components/course/Activity/ActivityContext";

function StepsCard({data, index, current, isActive, isComplete}) {
  const marginTop = useBreakpointValue({
    base: index > current ? '100%' : 0,
    lg: (index < current ? (current - index) * (isComplete ? 14 : 24) : 0) + 'px',
  });
  const marginLeft = useBreakpointValue({
    base: 0,
    lg: index > current ? '100%' : 0,
  });
  return <Box className={`steps-card ${isActive ? 'steps-card--active' : ''}`} mt={marginTop}
              ml={marginLeft}>
    {data.steps_card_next_label && <Box className={'steps-card__label'}>{data.steps_card_next_label}</Box>}
    <RichText className={'steps-card__body'} text={data?.description}/>
    {data?.title && <Box className={'steps-card__title'}>{data.title}</Box>}
  </Box>
}

// @todo rework sliding and animation
function StepsTrack({children, current, isComplete}) {
  const translate = useBreakpointValue({
    base: isComplete ? 'none' : `translateY(calc(50% - ${Math.round(40 + current * 160 / 2 + current * 24 + (current + 1) * 80)}px))`,
    lg: isComplete ? 'none' : `translateX(calc(50% - ${Math.round(current * 160 / 2 + current * 24 + (current + 1) * 80)}px))`,
  });
  const marginTop = useBreakpointValue({
    base: 0,
    lg: isComplete ? 0 : (current * 24) + 'px',
  });
  return <Box className={'steps-cards__track'} mt={marginTop} transform={translate}>
    {children}
  </Box>
}

export default function Steps({data, isSubmitting}) {
  const {onNext, onPrev, voiceover, showFooter} = useContext(ActivityContext);
  const {content, progress} = data
  const cards = content?.content?.cards || [];
  const [currentCard, setCurrentCard] = useState(0);
  const [isComplete, setComplete] = useState(false);
  const {setVoiceover} = useContext(VoiceoverContext);

  useEffect(() => {
    if (isComplete) {
      setVoiceover(voiceover);
    }
    else {
      setVoiceover(cards[currentCard]?.voiceover);
    }
  }, [isComplete, currentCard, voiceover, showFooter]);

  const handleNext = () => {
    if (isComplete) {
      onNext();
    } else {
      if (currentCard === cards.length - 1) {
        setComplete(true);
      } else {
        const nextCard = currentCard + 1;
        setCurrentCard(nextCard);
      }
    }
  };

  const handlePrev = () => {
    if (isComplete) {
      setComplete(false);
    } else {
      if (currentCard > 0) {
        const nextCard = currentCard - 1;
        setCurrentCard(nextCard);
      }
      else {
        onPrev();
      }
    }
  }

  return <ActivityContext.Provider value={{
    ...useContext(ActivityContext),
    canSubmit: true,
    onNext: handleNext,
    onPrev: handlePrev,
    totalPages: cards.length,
    currentPage: currentCard,
    canNavigate: () => true,
    setCurrentPage: setCurrentCard,
    nextLabel: !isComplete && cards[currentCard]?.steps_card_next_label,
  }}>
    <ActivityWrapper classNames={`${isComplete ? ['activity__wrapper--complete'] : []}`}
                     description={content.description} align={'center'}>
      <Box className={`steps-cards ${isComplete ? 'steps-cards--complete' : ''}`}>
        <StepsTrack current={currentCard} isComplete={isComplete}>
          {cards.map((card, index) => <StepsCard key={index} data={card} index={index} current={currentCard}
                                                 isActive={index === currentCard} isComplete={isComplete}/>)}
        </StepsTrack>
      </Box>
    </ActivityWrapper>
  </ActivityContext.Provider>
}
